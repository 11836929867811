import React, { FC } from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled from '@emotion/styled';

import { Flex } from '@lightspeed/cirrus/Core';
import { Text } from '@lightspeed/cirrus/Text';

import { KDS_APP_VERSION } from '../../../types/localStorageAliases';
import { Clock } from '../clock';

export const Footer: FC<{}> = ({ children }) => (
  <KitchenFooterWrapper>
    <Flex>
      <LightspeedKitchen>Lightspeed Kitchen</LightspeedKitchen>
      <LightspeedVersion>{localStorage.getItem(KDS_APP_VERSION)}</LightspeedVersion>
    </Flex>
    {children}
    <Clock />
  </KitchenFooterWrapper>
);

const KitchenFooterWrapper = styled(Flex)`
  position: relative;
  height: ${themeGet('kitchen.sizes.footer.height')};
  background: ${themeGet('kitchen.colors.footer.background')};
  padding: 0 ${themeGet('space.2')};
  width: 100vw;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-area: 3/1;
`;

const LightspeedKitchen = styled(Text)`
  color: ${themeGet('kitchen.colors.footer.lsText')};
  font-size: ${themeGet('fontSizes.text-xs')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
`;

const LightspeedVersion = styled(Text)`
  height: 15px;
  color: ${themeGet('kitchen.colors.footer.lsText')};
  font-size: ${themeGet('fontSizes.text-xs')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  opacity: ${themeGet('kitchen.colors.showntell.beta_opacity')};
  margin-left: ${themeGet('space.1')};
`;

export type KitchenFooterProps = {
  hidePagination?: boolean;
};
