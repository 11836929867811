import { Field, FieldProps, Formik, FormikHelpers, FormikProps } from 'formik';
import React from 'react';

import styled from '@emotion/styled';
import { Alert } from '@lightspeed/cirrus/Alert';
import { Card } from '@lightspeed/cirrus/Card';
import { Box, Flex } from '@lightspeed/cirrus/Core';
import { Input } from '@lightspeed/cirrus/Input';
import { Heading3 } from '@lightspeed/cirrus/Text';

import { LoginDataType, LoginErrorType } from '../../../types/login';
import { LoginActionsElement } from './LoginActionsElement';

export type LoginComponentProps = {
  onSubmit: (values: LoginDataType) => void;
  initialValues?: LoginDataType;
  appName: string;
  showForgotPassword?: boolean;
  loginError?: string;
};

export const LoginComponent = ({
  initialValues,
  onSubmit,
  appName,
  showForgotPassword,
  loginError,
}: LoginComponentProps) => {
  const validateInternal = ({ username, password }: LoginDataType): LoginErrorType => {
    const errors: LoginErrorType = {};
    if (!username || !username.trim()) {
      errors.username = 'Username required';
    }
    if (!password || !password.trim()) {
      errors.password = 'Password required';
    }
    return errors;
  };

  const onFormSubmit = async (
    values: LoginDataType,
    actions: FormikHelpers<LoginDataType>,
  ) => {
    await onSubmit(values);
    actions.setSubmitting(false);
  };

  const getStatus = (error: string | undefined, touched: boolean | undefined) => {
    return error && touched ? { type: 'error', message: error } : { type: '', message: null };
  };

  return (
    <Centered data-testid="loginForm">
      <Card>
        <Flex
          p={2}
          width={1}
          height="auto"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          pt={5}
        >
          <Heading3>{appName}</Heading3>
        </Flex>
        <Formik
          initialValues={initialValues || { username: '', password: '' }}
          validate={validateInternal}
          onSubmit={onFormSubmit}
          render={(formikBag: FormikProps<LoginDataType>) => {
            return (
              <form onSubmit={formikBag.handleSubmit}>
                {loginError && (
                  <Box m={2} title="loginError">
                    <Alert type="danger" title={loginError} noCloseBtn />
                  </Box>
                )}

                <Flex flexDirection="column" justifyContent="center">
                  <Box m={2}>
                    <Field
                      name="username"
                      render={({ field }: FieldProps<any>) => (
                        <Input
                          {...field}
                          label="Username"
                          placeholder="Enter your username"
                          autoComplete="new-password"
                          // @ts-ignore
                          status={getStatus(formikBag.errors.username, formikBag.touched.username)}
                        />
                      )}
                    />
                  </Box>
                  <Box m={2}>
                    <Field
                      name="password"
                      render={({ field }: FieldProps<any>) => (
                        <Input
                          {...field}
                          label="Password"
                          placeholder="Enter your password"
                          autoComplete="new-password"
                          type="password"
                          // @ts-ignore
                          status={getStatus(formikBag.errors.password, formikBag.touched.password)}
                        />
                      )}
                    />
                  </Box>
                  <Flex alignItems="center" m={2}>
                    <LoginActionsElement
                      showForgotPassword={showForgotPassword}
                      loading={formikBag.isSubmitting}
                    />
                  </Flex>
                </Flex>
              </form>
            );
          }}
        />
      </Card>
    </Centered>
  );
};

const Centered = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 425px;
`;
