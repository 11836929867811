import React, { FC } from 'react';
import { themeGet } from '@styled-system/theme-get';

import styled from '@emotion/styled';
import { Box, Flex } from '@lightspeed/cirrus/Core';

interface dotProps {
  selected: boolean;
  value: number;
}

export const PaginationDot: FC<dotProps> = ({ selected, value }) => {
  return (
    <DotWrapper data-testid={`pageDot-${value}`}>
      <Dot selected={selected} />
    </DotWrapper>
  );
};

const DotWrapper = styled(Flex)`
  height: 100%;
  justify-content: center;
  align-items: center;
  background: 'transparent';
`;

const Dot = styled(Box)<{ selected: boolean }>`
  opacity: ${props => (props.selected ? 1 : props.theme.kitchen.colors.footer.inactivePageOpacity)};
  background: ${themeGet('kitchen.colors.footer.pageColor')};
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin: 0px 9px;
  cursor: pointer;
`;
