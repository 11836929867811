import { useContext } from 'react';

import { TicketsContext } from '../../state/tickets.provider';

export const useRefreshing = (id: string) => {
  const {
    state: { refreshingTickets },
  } = useContext(TicketsContext);

  return refreshingTickets.some(ticketId => ticketId === id);
};
