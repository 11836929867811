import { useContext, useCallback } from 'react';

import { TicketDetails } from 'kitchen-types';
import { MixpanelEvents } from '../../types/mixpanel-events';
import { MixpanelContext } from '../../state/mixpanel.provider';

export const useDeprioritizeTracking = () => {
  const { track, getInteractionType, getSourceType } = useContext(MixpanelContext);

  const trackDeprioritize = useCallback(
    (event: Event, ticket: TicketDetails | undefined) => {
      try {
        if (ticket) {
          track(MixpanelEvents.TICKET_DEPRIORITIZE, getInteractionType(event), getSourceType(), {
            receiptId: ticket.receiptId,
          });
        }
      } catch (error) {
        // silently ignore the error
      }
    },
    [getInteractionType, getSourceType, track],
  );

  return trackDeprioritize;
};
