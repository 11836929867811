import React, { useState, useRef, useEffect, useContext } from 'react';
import { themeGet } from '@styled-system/theme-get';

import styled from '@emotion/styled';
import { Flex } from '@lightspeed/cirrus/Core';

import { bumpBarMap, useMoreModalBumpbar } from '../../../hooks/useMoreModalBumpbar';
import { MORE_MODAL_SCREENS } from '../../../types/MoreModal';
import { ACTION_KEYS } from '../../../utils/keyMap';
import { Action, BackAction } from './components/modal-action';
import { ActionDivider } from './components/modal-action-divider';
import { ActionList } from './components/modal-action-list';
import { KitchenModal } from './components/modal';
import { MixpanelContext } from '../../../state/mixpanel.provider';
import { MixpanelEvents, MixpanelInteractionType } from '../../../types/mixpanel-events';

type ClearTicketModalProps = {
  onCloseModal: () => void;
  onAction: () => void;
  onBack: (previousModal: string | null) => void;
};

const MessageContainer = styled(Flex)`
  font-size: ${themeGet('kitchen.fonts.sizes.2')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  line-height: ${themeGet('space.4')};
  margin-left: ${themeGet('kitchen.margins.modal.text.left')};
  margin-right: ${themeGet('kitchen.margins.modal.text.right')};
  margin-top: ${themeGet('space.1')};
  margin-bottom: ${themeGet('space.4')};
`;

export const ClearTicketModal = ({ onCloseModal, onAction, onBack }: ClearTicketModalProps) => {
  const [selection, setSelection] = useState(0);
  const { track, getSourceType } = useContext(MixpanelContext);

  const refSelectedElement = useRef(selection);

  useEffect(() => {
    refSelectedElement.current = selection;
  });

  const bumpBarMap: bumpBarMap = {
    [ACTION_KEYS.UP]: () => {
      if (refSelectedElement.current > 0) {
        setSelection(refSelectedElement.current - 1);
      }
    },
    [ACTION_KEYS.DOWN]: () => {
      if (refSelectedElement.current < 1) {
        setSelection(refSelectedElement.current + 1);
      }
    },
    [ACTION_KEYS.CANCEL]: () => {
      track(MixpanelEvents.MORE_CLEAR_CLOSE, MixpanelInteractionType.BUMPBAR, getSourceType());
      onCloseModal();
    },
    [ACTION_KEYS.ENTER]: () => {
      if (refSelectedElement.current === 0) {
        track(MixpanelEvents.MORE_CLEAR_BACK, MixpanelInteractionType.BUMPBAR, getSourceType());
        onBack(MORE_MODAL_SCREENS.OPTIONS);
      } else {
        track(MixpanelEvents.MORE_CLEAR_CONFIRM, MixpanelInteractionType.BUMPBAR, getSourceType());
        onAction();
      }
    },
  };

  useMoreModalBumpbar(bumpBarMap);

  return (
    <KitchenModal
      title="Clear all tickets"
      onCloseModal={(event: MouseEvent | KeyboardEvent) => {
        if (event && event.type === 'keydown') {
          track(MixpanelEvents.MORE_CLEAR_CLOSE, MixpanelInteractionType.BUMPBAR, getSourceType());
        } else {
          track(MixpanelEvents.MORE_CLEAR_CLOSE, MixpanelInteractionType.TOUCH, getSourceType());
        }
        onCloseModal();
      }}
    >
      <ActionList>
        <BackAction
          selected={selection === 0}
          onClick={() => {
            track(MixpanelEvents.MORE_CLEAR_BACK, MixpanelInteractionType.TOUCH, getSourceType());
            onBack(MORE_MODAL_SCREENS.OPTIONS);
          }}
          onSelectState={() => setSelection(0)}
        />
        <MessageContainer>
          Are you sure you want to clear all tickets on all profiles and screens?
        </MessageContainer>
        <ActionDivider />
        <Action
          selected={selection === 1}
          text="Yes, clear all tickets"
          onClick={() => {
            track(
              MixpanelEvents.MORE_CLEAR_CONFIRM,
              MixpanelInteractionType.TOUCH,
              getSourceType(),
            );
            onAction();
          }}
          noChevron
          onSelectState={() => setSelection(1)}
        />
        <ActionDivider />
      </ActionList>
    </KitchenModal>
  );
};
