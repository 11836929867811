import axios from 'axios';
import {
  KitchenProfileData,
  ProductCategoryData,
  ProductLayoutData,
} from '../kitchen-types';

import { getKitchenDefaultSettings } from '../defaultSettings/kitchenSettings';
import {
  KDS_ACCOUNT,
  KDS_PROFILE_IDENTIFIER,
  KDS_TOKEN_IDENTIFIER,
  KDS_LOGIN_INFO,
} from '../types/localStorageAliases';
import { getMappedTableType, TABLE_TYPES } from '../types/TableTypes';
import { TICKET_ACTIONS } from '../types/TicketActions';
import { getMappedTicketStatus, TICKET_STATUS } from '../types/TicketStatuses';
import { getCustomFormattedDate, getEndOfToday, getStartOfPreviousDay } from '../utils';
import { KDSDisabledError, KDSRedirect } from './errors';
import { kitchenApi } from './kitchen';

export const getCurrentDeviceProfile = (
  profiles: KitchenProfileData[],
  profileId: string | null,
) => {
  if (profileId) {
    return (
      profiles.find(profile => profile.oid === +profileId) ||
      profiles.find(profile => profile.defaultProfile) ||
      profiles[0]
    );
  }
  return profiles.find(profile => profile.defaultProfile) || profiles[0];
};

export const kitchenConnector = {
  login: async (username: string, password: string): Promise<void> => {
    const response = await kitchenApi.login(username, password);

    localStorage.setItem(KDS_TOKEN_IDENTIFIER, response.token);
    localStorage.setItem(KDS_ACCOUNT, username);
    localStorage.setItem(KDS_LOGIN_INFO, JSON.stringify({ username, password }));
    if (response.sendSettingsDefinition) {
      await kitchenApi.saveSettingsDefinition();
    }
  },

  checkOldKitchenInstalled: async (): Promise<boolean> => {
    try {
      const response = await axios.get(
        `${window.location.origin}/kitchen-old/assets/version.json?t=${Date.now()}`,
        {
          maxRedirects: 0,
        },
      );
      if (response.data.version) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  },

  loadApplicationSettings: async () => {
    const companySettings = await kitchenApi.getCompanySettings();

    if (companySettings.featureFlagKdsV3 === 'DISABLED') {
      console.log('~~~ Kitchen v3 disabled! ~~~');
      const oldKitchenFound = await kitchenConnector.checkOldKitchenInstalled();
      if (oldKitchenFound) {
        return Promise.reject(new KDSRedirect());
      }
      console.log('Old KDS not found, staying on v3');
    }

    // let floors: FloorData[] = [];
    let productCategories: ProductCategoryData[] = [];
    let productLayout: ProductLayoutData[] = [];
    let deviceProfiles: KitchenProfileData[] = [];
    let deviceProfile: KitchenProfileData;
    let backendVersion: string;

    if (companySettings.kdsEnabled) {
      [
        deviceProfiles,
        // floors,
        productCategories,
        productLayout,
        backendVersion,
      ] = await kitchenApi.getAdditionalInfo();

      if (deviceProfiles && deviceProfiles.length === 0) {
        const createdProfile = await kitchenConnector.createDefaultDeviceProfile(
          companySettings.oid,
        );

        deviceProfiles.push(createdProfile);

        localStorage.setItem(KDS_PROFILE_IDENTIFIER, `${createdProfile.oid}`);
      }

      deviceProfile = getCurrentDeviceProfile(
        deviceProfiles,
        localStorage.getItem(KDS_PROFILE_IDENTIFIER),
      );
      deviceProfile.settings = { ...getKitchenDefaultSettings(), ...deviceProfile.settings };
      return {
        companySettings,
        productCategories,
        productLayout,
        deviceProfiles,
        deviceProfile,
        backendVersion,
      };
    }
    return Promise.reject(new KDSDisabledError(companySettings));
  },

  saveDeviceProfile: async (profile: KitchenProfileData, companyId: number) => {
    const savedDeviceProfile = await kitchenApi.saveDeviceProfile(
      { ...profile, moduleName: 'kitchen', companyId },
      [],
    );
    return savedDeviceProfile;
  },

  loadFloors: async () => kitchenApi.getFloors(),

  createDefaultDeviceProfile: async (companyId: number) => {
    const defaultProfile: KitchenProfileData = {
      name: 'Kitchen 1',
      defaultProfile: true,
      settings: {
        showKitchen: true,
      },
    };
    const profile = await kitchenConnector.saveDeviceProfile(defaultProfile, companyId);
    return profile;
  },

  loadTickets: async (
    page: number,
    numberOfTickets: number,
    statusFilter: TICKET_STATUS,
    tableTypes: TABLE_TYPES,
    showKitchen: boolean,
    showBar: boolean,
    categories: number[],
    floors: number[],
  ) =>
    kitchenApi.getTickets(
      getCustomFormattedDate(getStartOfPreviousDay(new Date())),
      getCustomFormattedDate(getEndOfToday()),
      getMappedTicketStatus(statusFilter),
      getMappedTableType(tableTypes),
      showKitchen,
      showBar,
      categories,
      floors,
      page,
      numberOfTickets,
    ),

  loadHistoryTickets: async (
    page: number,
    numberOfTickets: number,
    showKitchen: boolean,
    showBar: boolean,
  ) =>
    kitchenApi.getHistoryTickets(
      getCustomFormattedDate(getStartOfPreviousDay(new Date())),
      getCustomFormattedDate(getEndOfToday()),
      showKitchen,
      showBar,
      page,
      numberOfTickets,
    ),

  getActionMapping: (action: TICKET_ACTIONS) => {
    switch (action) {
      case TICKET_ACTIONS.UNBUMP:
        return { url: 'unbumpTicket', method: 'kitchen.unbump' };
      case TICKET_ACTIONS.PRIORITISE:
        return { url: 'prioritizeTicket', method: 'kitchen.prioritizeReceipt' };
      case TICKET_ACTIONS.DEPRIORITISE:
        return { url: 'deprioritizeTicket', method: 'kitchen.deprioritizeReceipt' };
      default:
        return { url: 'bumpTicket', method: 'kitchen.bump' };
    }
  },

  updateTicket: async (ticketId: string, action: TICKET_ACTIONS) => {
    const { url, method } = kitchenConnector.getActionMapping(action);
    await kitchenApi.updateTicketStatus(ticketId, url, method);
  },

  clearTickets: async () => {
    await kitchenApi.clearTickets(
      getCustomFormattedDate(getStartOfPreviousDay(new Date())),
      getCustomFormattedDate(getEndOfToday()),
    );
  },
};
