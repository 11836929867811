import React from 'react'
import { Helmet } from 'react-helmet'
import { css, Global } from '@emotion/react'
import { HashRouter, Route, Switch } from 'react-router-dom'

import { CirrusGlobalStyles, CirrusTheme } from '@lightspeed/cirrus/Core'

import { kitchenDarkTheme } from './theming/themes'
import { AuthenticatedApp } from './shared/components'
import { Login, Disabled } from './shared/pages'
import { AuthenticationProvider } from './state'
import { KitchenApp } from './kitchen-app'
import { FirestoreProvider } from './hooks'

const KitchenGlobalStyle = () => (
  <Global
    styles={css`
      body {
        overflow: hidden;
        position: fixed;
      }

      @keyframes blink-new {
        0% {
          background-color: ${kitchenDarkTheme.kitchen.colors.ticket.status.new};
        }
        50% {
          background-color: ${kitchenDarkTheme.kitchen.colors.ticket.status.newHighlight};
        }
        55% {
          background-color: ${kitchenDarkTheme.kitchen.colors.ticket.status.newHighlight};
        }
        100% {
          background-color: ${kitchenDarkTheme.kitchen.colors.ticket.status.new};
        }
      }
    `}
  />
)

export const App = () => (
  <CirrusTheme themeOverrides={kitchenDarkTheme}>
    <CirrusGlobalStyles />
    <KitchenGlobalStyle />
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Assistant:600subset=latin-ext"
        rel="stylesheet"
      />
    </Helmet>
    <HashRouter basename={process.env.REACT_APP_BASE_PATH}>
      <AuthenticationProvider>
        <FirestoreProvider>
          <Switch>
            <Route exact component={Login} path="/login" />
            <Route exact component={Disabled} path="/disabled" />
            <AuthenticatedApp component={KitchenApp} path="/" />
          </Switch>
        </FirestoreProvider>
      </AuthenticationProvider>
    </HashRouter>
  </CirrusTheme>
)
