import { createBuilder } from './typesBuilder';

export type ProductLayoutData = {
  categoryId: number;
  imageLocation: string;
  name: string;
  oid: number;
  productIds: number[];
  relativeImageLocation: string;
};

const defaultProductLayoutData: ProductLayoutData = {
  productIds: [138219, 138218],
  name: 'Aperitif',
  relativeImageLocation: 'some-url',
  oid: 234302,
  categoryId: 52255,
  imageLocation: 'some-url',
};

export const productLayoutDataBuilder = createBuilder<ProductLayoutData>(defaultProductLayoutData);
