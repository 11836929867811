import React, { useMemo, FC } from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled from '@emotion/styled';
import { Flex } from '@lightspeed/cirrus/Core';

export const TicketPlaceholder: FC<{ number: number }> = ({ number }) => {
  return useMemo(
    () => (
      <PlaceholderWrapper>
        <TicketNumber>{number}</TicketNumber>
      </PlaceholderWrapper>
    ),
    [number],
  );
};

const PlaceholderWrapper = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${themeGet('kitchen.colors.emptyTicket.background')};
  border: 6px solid transparent;
  margin: 6px;
  border-radius: 6px;
`;

const TicketNumber = styled('p')`
  font-weight: 700;
  font-size: 164px;
  color: ${themeGet('kitchen.colors.emptyTicket.number')};
  letter-spacing: 0;
  margin: 0;
`;
