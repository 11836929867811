import { FloorData, FloorTableData } from 'kitchen-types'

export const getTablesMap = (floors: FloorData[] | null): { [key: string]: FloorTableData } => {
  if (floors === null) return {}
  return floors!
    .map((floor) => floor.tables)
    .reduce((floorTables, tableArray) => [...floorTables, ...tableArray], [])
    .reduce((tables, table) => ({ ...tables, [table.oid]: table }), {})
}

export const getTableData = (
  tableId: number,
  floors: FloorData[] | null,
): FloorTableData | undefined => {
  if (floors === null) return undefined
  const tables = floors!
    .map((floor) => floor.tables)
    .reduce((allTables, tableArray) => [...allTables, ...tableArray], [])
  return tables.find((table) => `${table.oid}` === `${tableId}`)
}
