import styled from '@emotion/styled';
import { Box } from '@lightspeed/cirrus/Core';

export const PageWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
`;
