import { baseColors } from '../../base/baseColors'

export const colors = {
  activeItemColor: baseColors['blue-500'],
  background: baseColors['gray-900'],
  header: {
    itemSelected: baseColors['blue-500'],
    background: baseColors['gray-900'],
    countIndicator: {
      background: baseColors['gray-800'],
    },
    text: baseColors.white,
  },
  footer: {
    background: baseColors['gray-900'],
    lsText: baseColors.white,
    clock: baseColors.white,
    pageColor: baseColors.white,
    inactivePageOpacity: 0.37,
    scrollbar: {
      background: '#66676a',
      indicator: 'white',
    },
  },
  emptyTicket: {
    background: baseColors['gray-800'],
    number: baseColors['gray-900'],
    message: baseColors.white,
    messageBox: baseColors['gray-800'],
  },
  ticket: {
    selected: baseColors['blue-500'],
    selectedText: baseColors.white,
    selectedGradientStart: 'rgba(46, 97, 222, 0)',
    selectedGradientEnd: 'rgba(46, 97, 222, 1)',
    header: {
      prioritizedIconColor: '#232a3b',
      text: baseColors['gray-800'],
      sequenceNumberText: baseColors.white,
      sequenceNumberBackground: '#2e3036',
      sequenceNumberBorder: '#2e3036',
      sequenceNumberTextSelected: baseColors.white,
      sequenceNumberBackgroundSelected: '#0b2bb3',
      sequenceNumberBorderSelected: '#0b2bb3',
      tableName: baseColors['gray-800'],
      infoSeparator: 'rgba(0,0,0, .3)',
      receiptId: baseColors['gray-800'],
      clock: '#232a3b',
      customers: '#232a3b',
      onlinePillText: baseColors.white,
      onlinePillBackground: 'rgb(213, 104, 44)',
      borderBottom: baseColors['gray-100'],
    },
    items: {
      text: baseColors['gray-800'],
      modifier: '#D5730D',
    },
    status: {
      new: baseColors['gray-50'],
      newHighlight: baseColors['gray-400'],
      cooking: baseColors['orange-300'],
      cookingHighlight: '#f5a648',
      done: baseColors['green-300'],
      doneHighlight: baseColors['green-500'],
      voided: baseColors['maple-300'],
      archived: baseColors['gray-300'],
    },
    body: {
      background: baseColors.white,
    },
    continued: {
      text: baseColors['gray-800'],
    },
  },
  showntell: {
    description_background: baseColors.white,
    description_color: baseColors['gray-800'],
    beta_opacity: '.5',
  },
  modal: {
    background: baseColors.white,
    action: {
      height: '60px',
      background: {
        selected: baseColors['blue-500'],
        default: baseColors['gray-50'],
      },
      text: {
        selected: baseColors.white,
        default: baseColors['gray-1000'],
      },
      icon: {
        selected: baseColors.white,
        default: baseColors['gray-1000'],
      },
      divider: baseColors['gray-100'],
    },
    account: {
      label: baseColors['gray-400'],
      value: baseColors.black,
    },
  },
}
