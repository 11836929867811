import { baseColors } from './baseColors';

export const borders = {
  ticket: {
    notSelected: `6px solid transparent`,
    selected: `6px solid ${baseColors['blue-500']}`,
    header: {
      bottom: `1px solid ${baseColors['gray-100']}`,
      sequenceNumber: '2px',
    },
    item: `1px solid ${baseColors['gray-100']}`,
    continued: `1px dotted #c4cacc`,
  },
  showntell: {
    list_separator: `1px solid ${baseColors['gray-100']}`,
  },
  header: {
    selectedItem: `2px solid ${baseColors['gray-400']}`,
  },
  radius: ['0px', '4px', '6px', '9px'],
};
