import { createBuilder } from './typesBuilder';

export type TicketItem = {
  amount: number;
  productId: number;
  name: string;
  receiptItemParentPLU?: string;
  modifiers: string[];
  kitchenName: string;
};

const defaultTicketItem: TicketItem = {
  amount: 1,
  productId: 138223,
  name: 'Spaghetti',
  modifiers: [],
  kitchenName: 'Spaghetti',
};

export const ticketItemBuilder = createBuilder<TicketItem>(defaultTicketItem);

export type ExtendedTicketItem = {
  productName: string;
  productImage: string;
  productDescription: string;
  productPlu: string;
} & TicketItem;
