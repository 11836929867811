import { createBuilder } from './typesBuilder';

export type LoginData = {
  token: string;
  sendSettingsDefinition: boolean;
};

const defaultLoginData: LoginData = {
  token: 'some-token',
  sendSettingsDefinition: false,
};

export const loginDataBuilder = createBuilder<LoginData>(defaultLoginData);
