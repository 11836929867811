import { createContext, useContext, useEffect, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';

import { AppContext } from './application.provider';
import { TicketsContext } from './tickets.provider';
import { TablesContext } from './tables.provider';
import { resolveWebSocketPath } from '../utils';

export type WebsocketContextType = {};

export const WebsocketContext = createContext<WebsocketContextType>({});

export const WebsocketProvider = ({ keepAliveInterval }: { keepAliveInterval?: number }) => {
  const {
    appSettings: {
      companySettings: { oid },
    },
  } = useContext(AppContext);
  const { updateTables } = useContext(TablesContext);
  const { reloadTicketsWebSocket } = useContext(TicketsContext);
  const [webSocket, setWebsocket] = useState<ReconnectingWebSocket | null>(null);

  useEffect(() => {
    const ws = new ReconnectingWebSocket(
      // resolveWebSocketPath(window.location.protocol, window.location.host),
      process.env.REACT_APP_LS_WS || ""
    );
    setWebsocket(ws);

    return () => ws && ws.close();
  }, []);

  useEffect(() => {
    if (webSocket) {
      const keepAlive = (interval: number, ws: ReconnectingWebSocket) => {
        setTimeout(() => {
          if (ws.readyState < 2) {
            ws.send(
              JSON.stringify({
                method: 'ping',
              }),
            );
            keepAlive(interval, ws);
          }
        }, interval);
      };

      webSocket.onmessage = (event: MessageEvent) => {
        if (event.data !== 'pong') {
          const data = JSON.parse(event.data);
          if (data.method === 'kitchen.ticketUpdate') {
            reloadTicketsWebSocket();
          }
          if (data.method === 'kitchen.modification') {
            switch (data.modification) {
              case 'lastFloorModDate':
                updateTables();
                break;
              default:
                window.location.reload();
            }
          }
        }
      };

      webSocket.onclose = () => {
        // onSocketClose(event, webSocket);
      };

      webSocket.onopen = () => {
        keepAlive(keepAliveInterval || 30000, webSocket);
        webSocket.send(
          JSON.stringify({
            method: 'register',
            companyId: oid,
          }),
        );
        reloadTicketsWebSocket();
      };
    }
  }, [webSocket, reloadTicketsWebSocket, oid, keepAliveInterval, updateTables]);

  return null;
};
