import { useContext } from 'react';
import { MixpanelEvents, MixpanelInteractionType } from '../../types/mixpanel-events';
import { MixpanelContext } from '../../state/mixpanel.provider';

export const useNumberPressTracking = () => {
  const { track, getSourceType } = useContext(MixpanelContext);

  const trackNumberPress = (pressedNumber: number) => {
    try {
      track(MixpanelEvents.BUMPBAR_NUMBER_PRESS, MixpanelInteractionType.BUMPBAR, getSourceType(), {
        pressedNumber,
      });
    } catch (error) {
      // silently ignore the error
    }
  };

  return trackNumberPress;
};
