import { useContext } from 'react';
import { MixpanelEvents, MixpanelInteractionType } from '../../types/mixpanel-events';
import { MixpanelContext } from '../../state/mixpanel.provider';

export const useArrowPressTracking = () => {
  const { track, getSourceType } = useContext(MixpanelContext);

  const trackArrowPress = (key: string) => {
    try {
      track(MixpanelEvents.BUMPBAR_ARROW_PRESS, MixpanelInteractionType.BUMPBAR, getSourceType(), {
        key,
      });
    } catch (error) {
      // silently ignore the error
    }
  };

  return trackArrowPress;
};
