import { createBuilder } from './typesBuilder';
import { RowsColumns } from './kitchenSettings';

export type SavedDeviceProfile = {
  defaultProfile: boolean;
  oid: number;
  name: string;
  settings: { [key: string]: string | number | boolean | any[] | RowsColumns | null };
};

const defaultSaveDeviceProfile: SavedDeviceProfile = {
  defaultProfile: true,
  oid: 123,
  name: 'default-profile',
  settings: {},
};

export const savedDeviceProfileBuilder = createBuilder<SavedDeviceProfile>(
  defaultSaveDeviceProfile,
);
