import { ReactNode } from 'react';

export enum MORE_MODAL_OPTIONS {
  STATE = 'STATE',
  STATE_NEW = 'STATE_NEW',
  STATE_PREPARING = 'STATE_PREPARING',
  STATE_DONE = 'STATE_DONE',
  STATE_ALL = 'STATE_ALL',
  TABLE_TYPE = 'TABLE_TYPE',
  TABLE_TYPE_DINE_IN = 'TABLE_TYPE_DINE_IN',
  TABLE_TYPE_TAKEOUT = 'TABLE_TYPE_TAKEOUT',
  TABLE_TYPE_DELIVERY = 'TABLE_TYPE_DELIVERY',
  TABLE_TYPE_TAKEOUT_DELIVERY = 'TABLE_TYPE_TAKEOUT_DELIVERY',
  TABLE_TYPE_ALL = 'TABLE_TYPE_ALL',
  LAYOUT = 'LAYOUT',
  LAYOUT_GRID = 'LAYOUT_GRID',
  LAYOUT_DYNAMIC = 'LAYOUT_DYNAMIC',
  LAYOUT_STATION = 'LAYOUT_STATION',
  CLEAR = 'CLEAR',
  PROFILE = 'PROFILE',
  LOGOUT = 'LOGOUT',
}

export enum MORE_MODAL_SCREENS {
  OPTIONS = 'OPTIONS',
  CLEAR_TICKETS = 'CLEAR_TICKETS',
  FILTER_TICKETS = 'FILTER_TICKETS',
  SETTINGS = 'SETTINGS',
  DEVICE_PROFILES = 'DEVICE_PROFILES',
  LAYOUT = 'LAYOUT',
  ACCOUNT = 'ACCOUNT',
}

export interface modalOption {
  icon?: ReactNode;
  id: string;
  text: string;
  clicked: Function;
}

export interface modalOptionGroup {
  title: string;
  options: modalOption[];
}

export const tableTypeDelivery = ['delivery'];
export const tableTypeDineIn = ['restaurant', 'tab', 'bar'];
export const tableTypeTakeOut = ['takeaway', 'takeaway_simple'];
export const tableTypeTakeOutAndDelivery = [...tableTypeDelivery, ...tableTypeTakeOut];

export const getMappedValue = (type: MORE_MODAL_OPTIONS, value: MORE_MODAL_OPTIONS) => {
  switch (type) {
    case MORE_MODAL_OPTIONS.STATE:
      switch (value) {
        case MORE_MODAL_OPTIONS.STATE_ALL:
          return null;
        case MORE_MODAL_OPTIONS.STATE_NEW:
          return 0;
        case MORE_MODAL_OPTIONS.STATE_PREPARING:
          return 1;
        case MORE_MODAL_OPTIONS.STATE_DONE:
          return 2;
        default:
          return value;
      }
    case MORE_MODAL_OPTIONS.TABLE_TYPE:
      switch (value) {
        case MORE_MODAL_OPTIONS.TABLE_TYPE_ALL:
          return [];
        case MORE_MODAL_OPTIONS.TABLE_TYPE_DELIVERY:
          return tableTypeDelivery;
        case MORE_MODAL_OPTIONS.TABLE_TYPE_DINE_IN:
          return tableTypeDineIn;
        case MORE_MODAL_OPTIONS.TABLE_TYPE_TAKEOUT:
          return tableTypeTakeOut;
        case MORE_MODAL_OPTIONS.TABLE_TYPE_TAKEOUT_DELIVERY:
          return tableTypeTakeOutAndDelivery;
        default:
          return value;
      }
    default:
      return value;
  }
};

export const getReadableTableType = (
  type:
    | MORE_MODAL_OPTIONS.TABLE_TYPE_DINE_IN
    | MORE_MODAL_OPTIONS.TABLE_TYPE_TAKEOUT
    | MORE_MODAL_OPTIONS.TABLE_TYPE_DELIVERY
    | MORE_MODAL_OPTIONS.TABLE_TYPE_TAKEOUT_DELIVERY
    | MORE_MODAL_OPTIONS.TABLE_TYPE_ALL,
): string => {
  switch (type) {
    case MORE_MODAL_OPTIONS.TABLE_TYPE_DELIVERY:
      return 'Delivery only';
    case MORE_MODAL_OPTIONS.TABLE_TYPE_DINE_IN:
      return 'Dine in only';
    case MORE_MODAL_OPTIONS.TABLE_TYPE_TAKEOUT:
      return 'Takeout only';
    case MORE_MODAL_OPTIONS.TABLE_TYPE_TAKEOUT_DELIVERY:
      return 'Takeout & Delivery only';
    default:
      return 'All';
  }
};
