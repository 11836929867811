import React, { useContext, useMemo, FC } from 'react'
import { themeGet } from '@styled-system/theme-get'
import styled from '@emotion/styled'

import { Flex, Box } from '@lightspeed/cirrus/Core'

import { TablesContext } from '../../../../state/tables.provider'
import { InfoSeparator } from './info-separator'

export const PrimaryInfo: FC<{
  tableId: string
  orderReference?: string | null
  large?: boolean
}> = ({ tableId, orderReference, large }) => (
  <TableNameWrapper large={large}>
    {orderReference && (
      <>
        <NoShrinkContainer>#{orderReference}</NoShrinkContainer>
        {large ? <InfoSeparator size="space.3" mx={2} /> : <InfoSeparator mx={1} />}
      </>
    )}
    <TableName tableId={tableId} />
  </TableNameWrapper>
)

const TableName: FC<{ tableId: string }> = ({ tableId }) => {
  const { getTableName, tablesLoaded } = useContext(TablesContext)
  return useMemo(
    () => <EllipsisContainer>{tablesLoaded && getTableName(tableId)}</EllipsisContainer>,
    [getTableName, tableId, tablesLoaded],
  )
}

const EllipsisContainer = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const NoShrinkContainer = styled(EllipsisContainer)`
  flex-shrink: 0;
`

const TableNameWrapper = styled(Flex)<{ large?: boolean }>`
  color: ${themeGet('kitchen.colors.ticket.header.tableName')};
  font-size: ${({ large }) =>
    large ? themeGet('kitchen.fonts.size.3') : themeGet('kitchen.fonts.size.2')};
  font-weight: ${themeGet('kitchen.fonts.weight.bolder')};
  height: ${({ large }) =>
    large ? themeGet('space.6') : themeGet('kitchen.sizes.ticket.header.tableName')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  line-height: ${({ large }) =>
    large ? themeGet('space.6') : themeGet('kitchen.sizes.ticket.header.tableName')};
  margin-bottom: ${({ large }) => (large ? 0 : themeGet('space.1'))};
  margin-top: ${themeGet('space.0')};
  margin-left: ${themeGet('space.2')};
  white-space: nowrap;
  align-items: center;
  overflow: hidden;
`
