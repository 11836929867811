import { useContext } from 'react';
import { TicketDetails } from 'kitchen-types';
import { MixpanelEvents } from '../../types/mixpanel-events';
import { MixpanelContext } from '../../state/mixpanel.provider';

export const usePrioritizeTracking = () => {
  const { track, getInteractionType, getSourceType } = useContext(MixpanelContext);

  const trackPrioritize = (event: Event, ticket: TicketDetails | undefined) => {
    try {
      if (ticket) {
        track(MixpanelEvents.TICKET_PRIORITIZE, getInteractionType(event), getSourceType(), {
          receiptId: ticket.receiptId,
        });
      }
    } catch (error) {
      // silently ignore the error
    }
  };

  return trackPrioritize;
};
