import React, { FC } from 'react';

import { TicketAdditionalInfo } from './ticket-additional-info';
import { useTicketDetails } from '../../../../hooks/useTicketDetails';
import { TicketInfo } from './ticket-info';
import { PrimaryInfo } from './primary-info';

export const SelectedHeader: FC<{
  ticketId: string;
  number: number;
  kdsStatusId: number;
  type: string;
  receiptId: number;
  orderReference?: string | null;
}> = ({ ticketId, number, kdsStatusId, type, receiptId, orderReference }) => {
  const { tableId } = useTicketDetails(ticketId);

  return (
    <>
      <TicketInfo
        ticketId={ticketId}
        number={number}
        kdsStatusId={kdsStatusId}
        type={type}
        receiptId={receiptId}
      />
      <PrimaryInfo tableId={`${tableId}`} orderReference={orderReference} />
      <TicketAdditionalInfo ticketId={ticketId} />
    </>
  );
};
