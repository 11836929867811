import { useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { useContext } from 'react';
import { TicketsContext } from '../state';

export const useGridSwipe = (threshold: number = 250, allowedVariation: number = 50) => {
  const {
    state: {
      pagination: { selectedPage, numberOfPages },
    },
    nextPage,
    previousPage,
  } = useContext(TicketsContext);

  const [{ x, y }, set] = useSpring(() => ({ x: 0, y: 0 }));

  const attachSwipeEvents = useDrag(
    ({ last, movement: [mx], memo = [x.getValue(), y.getValue()] }) => {
      if (last) {
        if (mx > threshold && selectedPage > 0) {
          previousPage();
        } else if (mx < -threshold && selectedPage < numberOfPages - 1) {
          nextPage();
        }
        set({ x: 0, immediate: true });
      } else if (mx > allowedVariation) {
        const max = Math.min(memo[0] + (mx - allowedVariation), threshold);
        set({ x: max, immediate: true });
      } else if (mx < -allowedVariation) {
        const max = Math.max(memo[0] + (mx + allowedVariation), -threshold);
        set({ x: max, immediate: true });
      }
      return memo;
    },
  );

  const getSwipeStyle = () => {
    return {
      transform: x.interpolate((x: any) => `translate3d(${x}px, 0, 0)`),
    };
  };

  return {
    attachSwipeEvents,
    getSwipeStyle,
  };
};
