export * from './dark/dark';
export * from './light/light';

export enum KITCHEN_THEMES {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export interface IKitchenTheme {
  kitchen: {
    colors: {
      activeItemColor: string;
      background: string;
      header: {
        itemSelected: string;
        background: string;
        text: string;
      };
      footer: {
        background: string;
        lsText: string;
        clock: string;
        pageColor: string;
        inactivePageOpacity: number;
      };
      emptyTicket: {
        background: string;
        number: string;
        message: string;
        messageBox: string;
      };
      ticket: {
        selected: string;
        selectedText: string;
        selectedGradientStart: string;
        selectedGradientEnd: string;
        header: {
          prioritizedIconColor: string;
          text: string;
          sequenceNumberText: string;
          sequenceNumberBackground: string;
          sequenceNumberBorder: string;
          tableName: string;
          infoSeparator: string;
          receiptId: string;
          clock: string;
          customers: string;
          onlinePillText: string;
          onlinePillBackground: string;
          borderBottom: string;
        };
        items: {
          text: string;
          modifier: string;
        };
        status: {
          new: string;
          cooking: string;
          done: string;
          voided: string;
          archived: string;
        };
        body: {
          background: string;
        };
      };
      modal: {
        background: string;
        action: {
          height: string;
          background: {
            selected: string;
            default: string;
          };
          text: {
            selected: string;
            default: string;
          };
          icon: {
            selected: string;
            default: string;
          };
          divider: string;
        };
      };
    };
    sizes: {
      header: {
        height: string;
        contentHeight: string;
        moreIcon: string;
        text: string;
        selectionLine: string;
      };
      footer: {
        height: string;
      };
      ticket: {
        emptyMessage: string;
        selected: {
          bumpIcon: string;
          statusText: string;
          gradientWidth: string;
        };
        header: {
          prioritizedIcon: string;
          sequenceNumber: string;
          tableName: string;
          separatorWidth: string;
          separatorHeight: string;
          clock: string;
          customers: string;
          showAndTellIconSize: string;
        };
        items: {
          quantityWidth: string;
        };
        continued: {
          padding: {
            top: string;
            right: string;
            bottom: string;
            left: string;
          };
          spacer: {
            height: string;
          };
        };
        showAndTell: {
          listWrapper: string;
        };
      };
      modal: {
        width: string;
        height: string;
        action: {
          height: string;
          text: {
            height: string;
          };
          icon: {
            size: string;
            containerWidth: string;
          };
          divider: {
            height: string;
          };
        };
      };
    };
    shadows: {
      normal: string;
    };
    fonts: {
      letterSpacing: string[];
      weight: {
        bold: number;
        bolder: number;
      };
    };
  };
}
