import React, { FC } from 'react';
import styled from '@emotion/styled';

import { Box } from '@lightspeed/cirrus/Core';
import { Spinner } from '@lightspeed/cirrus/Spinner';
import { themeGet } from '@styled-system/theme-get';

export const TicketLoading: FC<{ selected?: boolean; spacing?: 'normal' | 'large' }> = ({
  selected = false,
  spacing = 'normal',
}) => (
  <LoadingOverlay spacing={spacing}>
    <Spinner size="large" color={selected ? 'white' : 'black'} />
  </LoadingOverlay>
);

const LoadingOverlay = styled(Box)<{ spacing?: 'normal' | 'large' }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: ${({ spacing }) => (spacing === 'large' ? themeGet('space.4') : themeGet('space.2'))};
  margin-right: ${({ spacing }) =>
    spacing === 'large' ? themeGet('space.4') : themeGet('space.2')};
`;
