import React, { useContext } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { TicketsContext } from '../../state';
import { ShowAndTellHeader } from '../components/header/show-and-tell-header';
import { AppLoader, AppContainer, Footer } from '../../shared/components';
import { ShowAndTellBody } from '../components/ticket/ticket';
import { ShowAndTellProvider } from '../show-and-tell.provider';
import { useGridBumpbar } from '../../hooks';

export const ShowAndTell = ({
  match: {
    params: { ticketId },
  },
}: RouteComponentProps<{ ticketId: string }>) => {
  const {
    state: { tickets, loading },
  } = useContext(TicketsContext);
  const history = useHistory();

  const ticket = tickets.find(({ id }) => id === ticketId);
  useGridBumpbar(false, () => console.log('some funtion'));

  if (!loading && tickets.length >= 0 && ticket === undefined) {
    history.push('/');
  }

  return (
    <AppContainer>
      {ticket && (
        <ShowAndTellProvider ticket={ticket}>
          <ShowAndTellHeader ticket={ticket} data-testid="snt-header" />
          <ShowAndTellBody ticket={ticket} />
          <Footer data-testid="snt-footer" />
        </ShowAndTellProvider>
      )}
      {!ticket && <AppLoader />}
    </AppContainer>
  );
};
