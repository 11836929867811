import React, { useState, useRef, useEffect, useContext } from 'react';

import { bumpBarMap, useMoreModalBumpbar } from '../../../hooks/useMoreModalBumpbar';
import {
  getReadableTableType,
  MORE_MODAL_OPTIONS,
  MORE_MODAL_SCREENS,
} from '../../../types/MoreModal';
import { TABLE_TYPES } from '../../../types/TableTypes';
import { ACTION_KEYS } from '../../../utils/keyMap';
import { Action, BackAction } from './components/modal-action';
import { ActionDivider } from './components/modal-action-divider';
import { ActionList } from './components/modal-action-list';
import { StyledCheckmarkIcon } from './components/modal-icons';
import { KitchenModal } from './components/modal';
import { MixpanelContext } from '../../../state/mixpanel.provider';
import { MixpanelEvents, MixpanelInteractionType } from '../../../types/mixpanel-events';

type FilterTicketsModalProps = {
  activeFilter: TABLE_TYPES;
  onCloseModal: () => void;
  onFilter: (type: TABLE_TYPES) => void;
  onBack: (previousModal: string | null) => void;
};

const actionParameters: TABLE_TYPES[] = [
  TABLE_TYPES.TABLE_TYPE_ALL,
  TABLE_TYPES.TABLE_TYPE_DINE_IN,
  TABLE_TYPES.TABLE_TYPE_DELIVERY,
  TABLE_TYPES.TABLE_TYPE_TAKEOUT,
  TABLE_TYPES.TABLE_TYPE_TAKEOUT_DELIVERY,
];

export const FilterTicketsModal = ({
  activeFilter,
  onCloseModal,
  onFilter,
  onBack,
}: FilterTicketsModalProps) => {
  const [selection, setSelection] = useState(0);
  const { track, getSourceType } = useContext(MixpanelContext);

  const refSelectedElement = useRef(selection);

  useEffect(() => {
    refSelectedElement.current = selection;
  });

  const bumpBarMap: bumpBarMap = {
    [ACTION_KEYS.UP]: () => {
      if (refSelectedElement.current > 0) {
        setSelection(refSelectedElement.current - 1);
      }
    },
    [ACTION_KEYS.DOWN]: () => {
      if (refSelectedElement.current < 5) {
        setSelection(refSelectedElement.current + 1);
      }
    },
    [ACTION_KEYS.CANCEL]: () => {
      track(MixpanelEvents.MORE_FILTER_CLOSE, MixpanelInteractionType.BUMPBAR, getSourceType());
      onCloseModal();
    },
    [ACTION_KEYS.ENTER]: () => {
      if (refSelectedElement.current === 0) {
        track(MixpanelEvents.MORE_FILTER_BACK, MixpanelInteractionType.BUMPBAR, getSourceType());
        onBack(MORE_MODAL_SCREENS.OPTIONS);
      } else {
        switch (refSelectedElement.current) {
          case 1:
            track(MixpanelEvents.MORE_FILTER_ALL, MixpanelInteractionType.BUMPBAR, getSourceType());
            break;
          case 2:
            track(
              MixpanelEvents.MORE_FILTER_DINEIN,
              MixpanelInteractionType.BUMPBAR,
              getSourceType(),
            );
            break;
          case 3:
            track(
              MixpanelEvents.MORE_FILTER_DELIVERY,
              MixpanelInteractionType.BUMPBAR,
              getSourceType(),
            );
            break;
          case 4:
            track(
              MixpanelEvents.MORE_FILTER_TAKEOUT,
              MixpanelInteractionType.BUMPBAR,
              getSourceType(),
            );
            break;
          default:
            track(
              MixpanelEvents.MORE_FILTER_TAKEOUT_DELIVERY,
              MixpanelInteractionType.BUMPBAR,
              getSourceType(),
            );
        }
        onFilter(actionParameters[refSelectedElement.current - 1]);
      }
    },
  };

  useMoreModalBumpbar(bumpBarMap);

  return (
    <KitchenModal
      title="Filter tickets"
      onCloseModal={(event: MouseEvent | KeyboardEvent) => {
        if (event && event.type === 'keydown') {
          track(MixpanelEvents.MORE_FILTER_CLOSE, MixpanelInteractionType.BUMPBAR, getSourceType());
        } else {
          track(MixpanelEvents.MORE_FILTER_CLOSE, MixpanelInteractionType.TOUCH, getSourceType());
        }
        onCloseModal();
      }}
    >
      <ActionList>
        <BackAction
          selected={selection === 0}
          onClick={() => {
            track(MixpanelEvents.MORE_FILTER_BACK, MixpanelInteractionType.TOUCH, getSourceType());
            onBack(MORE_MODAL_SCREENS.OPTIONS);
          }}
          onSelectState={() => setSelection(0)}
        />
        <ActionDivider />
        <Action
          selected={selection === 1}
          text={getReadableTableType(MORE_MODAL_OPTIONS.TABLE_TYPE_ALL)}
          onClick={() => {
            track(MixpanelEvents.MORE_FILTER_ALL, MixpanelInteractionType.TOUCH, getSourceType());
            onFilter(TABLE_TYPES.TABLE_TYPE_ALL);
          }}
          noChevron
          onSelectState={() => setSelection(1)}
        >
          {activeFilter === TABLE_TYPES.TABLE_TYPE_ALL && (
            <StyledCheckmarkIcon selected={selection === 1} />
          )}
        </Action>
        <ActionDivider />
        <Action
          selected={selection === 2}
          text={getReadableTableType(MORE_MODAL_OPTIONS.TABLE_TYPE_DINE_IN)}
          onClick={() => {
            track(
              MixpanelEvents.MORE_FILTER_DINEIN,
              MixpanelInteractionType.TOUCH,
              getSourceType(),
            );
            onFilter(TABLE_TYPES.TABLE_TYPE_DINE_IN);
          }}
          noChevron
          onSelectState={() => setSelection(2)}
        >
          {activeFilter === TABLE_TYPES.TABLE_TYPE_DINE_IN && (
            <StyledCheckmarkIcon selected={selection === 2} />
          )}
        </Action>
        <ActionDivider />
        <Action
          selected={selection === 3}
          text={getReadableTableType(MORE_MODAL_OPTIONS.TABLE_TYPE_DELIVERY)}
          onClick={() => {
            track(
              MixpanelEvents.MORE_FILTER_DELIVERY,
              MixpanelInteractionType.TOUCH,
              getSourceType(),
            );
            onFilter(TABLE_TYPES.TABLE_TYPE_DELIVERY);
          }}
          noChevron
          onSelectState={() => setSelection(3)}
        >
          {activeFilter === TABLE_TYPES.TABLE_TYPE_DELIVERY && (
            <StyledCheckmarkIcon selected={selection === 3} />
          )}
        </Action>
        <ActionDivider />
        <Action
          selected={selection === 4}
          text={getReadableTableType(MORE_MODAL_OPTIONS.TABLE_TYPE_TAKEOUT)}
          onClick={() => {
            track(
              MixpanelEvents.MORE_FILTER_TAKEOUT,
              MixpanelInteractionType.TOUCH,
              getSourceType(),
            );
            onFilter(TABLE_TYPES.TABLE_TYPE_TAKEOUT);
          }}
          noChevron
          onSelectState={() => setSelection(4)}
        >
          {activeFilter === TABLE_TYPES.TABLE_TYPE_TAKEOUT && (
            <StyledCheckmarkIcon selected={selection === 4} />
          )}
        </Action>
        <ActionDivider />
        <Action
          selected={selection === 5}
          text={getReadableTableType(MORE_MODAL_OPTIONS.TABLE_TYPE_TAKEOUT_DELIVERY)}
          onClick={() => {
            track(
              MixpanelEvents.MORE_FILTER_TAKEOUT_DELIVERY,
              MixpanelInteractionType.TOUCH,
              getSourceType(),
            );
            onFilter(TABLE_TYPES.TABLE_TYPE_TAKEOUT_DELIVERY);
          }}
          noChevron
          onSelectState={() => setSelection(5)}
        >
          {activeFilter === TABLE_TYPES.TABLE_TYPE_TAKEOUT_DELIVERY && (
            <StyledCheckmarkIcon selected={selection === 5} />
          )}
        </Action>
        <ActionDivider />
      </ActionList>
    </KitchenModal>
  );
};
