import { useEffect, useRef } from 'react';

export const useSynchronizedAnimation = (animationName: string | string[]) => {
  const animations = typeof animationName !== 'string' ? animationName : [animationName];
  const animationStarted = useRef<number>(0);
  const baseElement = useRef<HTMLElement>();

  const animationStart = (event: AnimationEvent) => {
    if (animations.includes(event.animationName)) {
      const el = event.target as HTMLElement;
      if (animationStarted.current === 0) {
        baseElement.current = el;
        animationStarted.current = Date.now();
      } else {
        const delay = Date.now() - animationStarted.current;
        el.style.setProperty('animation-delay', `${-delay}ms`);
      }
    }
  };

  const animationIteration = (event: AnimationEvent) => {
    if (animations.includes(event.animationName)) {
      if (animationStarted.current === 0) {
        animationStart(event);
      } else {
        const el = event.target as HTMLElement;
        if (baseElement && baseElement.current && validate(baseElement.current)) {
          if (el && el.isSameNode(baseElement.current)) {
            animationStarted.current = Date.now();
          }
        } else {
          animationStarted.current = 0;
          baseElement.current = undefined;
        }
      }
    }
  };

  const animationEnd = (event: AnimationEvent) => {
    if (animations.includes(event.animationName)) {
      const el = event.target as HTMLElement;
      if (baseElement && baseElement.current && el && el.isSameNode(baseElement.current)) {
        animationStarted.current = 0;
        baseElement.current = undefined;
      }
    }
  };

  const validate = (el: HTMLElement) => {
    return document.body.contains(el);
  };

  useEffect(() => {
    window.addEventListener('animationstart', animationStart, true);
    window.addEventListener('animationiteration', animationIteration, true);
    window.addEventListener('animationend', animationEnd, true);
    return () => {
      window.removeEventListener('animationstart', animationStart, true);
      window.removeEventListener('animationiteration', animationIteration, true);
      window.removeEventListener('animationend', animationEnd, true);
    };
  }, []);
};
