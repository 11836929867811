import { useState, useEffect } from 'react';
import moment from 'moment';

export const useBlinking = (ticketDate: string, timeout: number) => {
  const [shouldBlink, setShouldBlink] = useState<boolean>(false);

  const blinkAt = timeout
    ? moment(ticketDate)
        .toDate()
        .getTime() +
      timeout * 60000
    : null;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (blinkAt) {
      if (Date.now() >= blinkAt) {
        setShouldBlink(true);
      } else {
        timeout = setTimeout(() => {
          setShouldBlink(true);
        }, blinkAt - Date.now());
      }
    }
    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    };
  }, [blinkAt]);

  return shouldBlink;
};
