import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { themeGet } from '@styled-system/theme-get'
import { withTheme } from '@emotion/react'

import { Flex, Box } from '@lightspeed/cirrus/Core'
import { IconExport } from '@lightspeed/cirrus/Icon/Export'
import { IconZoomIn } from '@lightspeed/cirrus/Icon/ZoomIn'
import { IconProps } from '@lightspeed/cirrus/Icon/utils/iconFactory'

import { ReactComponent as FlagIcon } from '../../icons/flag.svg'
import { ReactComponent as UnflagIcon } from '../../icons/unflag.svg'
import { useTicketDetails } from '../../../../hooks'
import { TicketsContext } from '../../../../state'
import { TICKET_STATUS } from '../../../../types/TicketStatuses'
import { TimeAndStatus } from './time-and-status'
import { useRefreshing } from '../../../hooks/refreshing-ticket.hook'

export const TicketInfo = ({
  ticketId,
  number,
  kdsStatusId,
  type,
  receiptId,
}: {
  ticketId: string
  number: number
  kdsStatusId: number
  type: string
  receiptId: number
}) => {
  const { modificationDate, prioritized } = useTicketDetails(ticketId)
  const isRefreshing = useRefreshing(ticketId)

  return (
    <TicketSelectedInfoWrapper data-testid="selectedTicketWrapper">
      <SequenceNumberWrapper data-testid="sequenceNumber">{number}</SequenceNumberWrapper>

      <TimeAndStatusWrapper data-testid="selectedTicketHeader">
        <TimeAndStatus
          date={kdsStatusId < TICKET_STATUS.ARCHIVED ? modificationDate : ''}
          status={kdsStatusId}
          type={type}
        />
        <GradientBox />
      </TimeAndStatusWrapper>

      {!isRefreshing && (
        <Flex alignItems="center">
          <TicketInfoActions
            ticketId={ticketId}
            voided={type === 'voided'}
            status={kdsStatusId}
            prioritized={prioritized}
            kdsStatusId={kdsStatusId}
            type={type}
            receiptId={receiptId}
          />
        </Flex>
      )}
    </TicketSelectedInfoWrapper>
  )
}

const GradientBox = styled(Box)`
  width: ${themeGet('kitchen.sizes.ticket.selected.gradientWidth')};
  height: 100%;
  background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.kitchen.colors.ticket.selectedGradientStart} 0%, ${theme.kitchen.colors.ticket.selectedGradientEnd})`};
  position: absolute;
  right: 0;
`

const TicketInfoActions = ({
  ticketId,
  voided,
  status,
  prioritized,
  kdsStatusId,
  type,
  receiptId,
}: {
  ticketId: string
  voided: boolean
  status: TICKET_STATUS
  prioritized: boolean
  kdsStatusId: number
  type: string
  receiptId: number
}) => {
  const { prioritise, bump, unbump } = useContext(TicketsContext)

  return (
    <>
      {status < TICKET_STATUS.ARCHIVED && (
        <Flex
          onClick={(event: Event) => bump(event, ticketId, kdsStatusId, type, receiptId)}
          data-testid="selectedTicketBumpButton"
        >
          <BumpIcon />
        </Flex>
      )}
      {status === TICKET_STATUS.ARCHIVED && !voided && (
        <Flex
          onClick={(event: Event) => unbump(event, ticketId, kdsStatusId, receiptId)}
          data-testid="selectedTicketUnbumpButton"
        >
          <StyledUnbumpIcon />
        </Flex>
      )}
      {status !== TICKET_STATUS.ARCHIVED && !voided && (
        <Flex
          data-testid="prioritiseButton"
          onClick={(event: Event) => prioritise(event, ticketId)}
        >
          {prioritized ? <StyledDeprioritizedIcon /> : <StyledPrioritizedIcon />}
        </Flex>
      )}
      {status === TICKET_STATUS.DONE && (
        <Flex
          onClick={(event: Event) => unbump(event, ticketId, kdsStatusId, receiptId)}
          data-testid="selectedTicketUnbumpButton"
        >
          <StyledUnbumpIcon />
        </Flex>
      )}
    </>
  )
}

const BumpIcon = withTheme(
  ({
    theme: {
      kitchen: {
        colors: {
          ticket: { selectedText },
        },
      },
    },
  }: any) => <ExportIcon color={selectedText} />,
)

const ExportIcon = styled(IconExport)<IconProps>`
  margin-right: ${themeGet('space.2')};
  margin-left: ${themeGet('space.2')};
  transition: opacity 0.2s ease-in-out;
`

const ExportReversedIconStyled = styled(IconExport)<IconProps>`
  margin-right: ${themeGet('space.4')};
  margin-left: ${themeGet('space.2')};
  transform: rotate(180deg);
  transition: opacity 0.2s ease-in-out;
`

const ExportReversedIcon = ({ theme }: any) => (
  <ExportReversedIconStyled color={theme.kitchen.colors.ticket.selectedText} />
)
const StyledUnbumpIcon = withTheme(ExportReversedIcon)

const PrioritizedIconStyled = styled(FlagIcon)<IconProps>`
  margin-right: ${themeGet('space.2')};
  transition: opacity 0.2s ease-in-out;
`

const PrioritizedIcon = ({ theme }: any) => (
  <PrioritizedIconStyled
    fill={theme.kitchen.colors.ticket.selectedText}
    color={theme.kitchen.colors.ticket.selectedText}
  />
)
const StyledPrioritizedIcon = withTheme(PrioritizedIcon)

const TicketSelectedInfoWrapper = styled(Flex)`
  align-items: center;
  padding-left: ${themeGet('kitchen.margins.ticket.selected.left')};
  background: ${themeGet('kitchen.colors.ticket.selected')};
  margin-bottom: ${themeGet('kitchen.margins.ticket.selected.tableName')};
  height: ${themeGet('space.7')};
  color: ${themeGet('kitchen.colors.ticket.selectedText')};
  justify-content: space-between;
`

const DeprioritizedIconStyled = styled(UnflagIcon)<IconProps>`
  margin-right: ${themeGet('space.2')};
  transition: opacity 0.2s ease-in-out;
`

const DeprioritizedIcon = ({ theme }: any) => (
  <DeprioritizedIconStyled
    fill={theme.kitchen.colors.ticket.selectedText}
    color={theme.kitchen.colors.ticket.selectedText}
  />
)
const StyledDeprioritizedIcon = withTheme(DeprioritizedIcon)

const TimeAndStatusWrapper = styled(Flex)`
  align-items: center;
  position: relative;
  flex: 1;
  min-width: 0;
  overflow: hidden;
`

const SequenceNumberWrapper = styled(Box)`
  font-size: ${themeGet('kitchen.fonts.size.1')};
  width: ${themeGet('space.5')};
  height: ${themeGet('kitchen.sizes.ticket.header.sequenceNumber')};
  color: ${themeGet('kitchen.colors.ticket.header.sequenceNumberTextSelected')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  line-height: ${themeGet('kitchen.sizes.ticket.header.sequenceNumber')};
  text-align: center;
  background: ${themeGet('kitchen.colors.ticket.header.sequenceNumberBackgroundSelected')};
  border: ${themeGet('kitchen.borders.ticket.header.sequenceNumberSelected')};
  border-color: ${themeGet('kitchen.colors.ticket.header.sequenceNumberBorderSelected')};
  border-radius: ${themeGet('kitchen.borders.radius.1')};
  margin-left: ${themeGet('space.1')};
`
