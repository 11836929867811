import React, { FC, useContext, useRef } from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { useHistory } from 'react-router-dom';

import { Box } from '@lightspeed/cirrus/Core';

import {
  TicketDetails,
  ExtendedTicketItem,
  ProductData,
} from 'kitchen-types';

import { Header } from './header/header';
import { Body } from './body/body';
import { AppContext, TicketsContext } from '../../../state';
import { isLowerTanIOS13 } from '../../../utils/is-lower-tan-ios-13';
import { useTicketClickHandler, useTicketPinchIn, useTracking } from '../../../hooks';

export const ShowAndTellBody: FC<{
  ticket: TicketDetails;
}> = ({ ticket: regularTicket }) => {
  const {
    appSettings: { products },
  } = useContext(AppContext);

  const getProductImage = (product: ProductData) =>
    product.imageLocationKitchen !== '' ? product.imageLocationKitchen : product.imageLocation;

  const extendedTicket: TicketDetails & { items: ExtendedTicketItem[] } = {
    ...regularTicket,
    items: regularTicket.items.map(item => ({
      ...item,
      productImage: products[item.productId] ? getProductImage(products[item.productId]) : '',
      productDescription: products[item.productId] ? products[item.productId].info : '',
      productName: products[item.productId] ? products[item.productId].kitchenName : '',
      productPlu: products[item.productId] ? products[item.productId].pId : '',
    })),
  };

  const { bump } = useContext(TicketsContext);
  const { trackZoomOut } = useTracking();

  const clickHandler = useTicketClickHandler();

  const ticketRef = useRef<HTMLElement>(null);
  const history = useHistory();

  useTicketPinchIn(ticketRef, () => {
    trackZoomOut(new Event('pinch'), regularTicket.receiptId);
    history.push('/');
  });

  return (
    <Container
      ref={ticketRef}
      data-testid="snt-body"
      onClick={(event: Event) => {
        event.preventDefault();
        event.stopPropagation();
        if (isLowerTanIOS13) {
          clickHandler(
            event,
            extendedTicket.id,
            extendedTicket.kdsStatusId,
            extendedTicket.type,
            extendedTicket.receiptId,
          );
        }
      }}
      onDoubleClick={(event: Event) => {
        bump(
          event,
          extendedTicket.id,
          extendedTicket.kdsStatusId,
          extendedTicket.type,
          extendedTicket.receiptId,
        );
      }}
    >
      <Header ticket={extendedTicket} />
      <Body ticket={extendedTicket} />
    </Container>
  );
};

const Container = styled(Box)`
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 0 ${themeGet('space.2')};
  overflow: auto;
`;
