import React, { useContext } from 'react';
import { themeGet } from '@styled-system/theme-get';
import { RouteComponentProps, Link } from 'react-router-dom';

import styled from '@emotion/styled';
import { Button } from '@lightspeed/cirrus/Button';
import { Card } from '@lightspeed/cirrus/Card';
import { Box, Flex } from '@lightspeed/cirrus/Core';
import { Heading1, Text } from '@lightspeed/cirrus/Text';

import { AuthenticationContext } from '../../state';
import { PageWrapper } from '../components';

export const Disabled = (_props: RouteComponentProps) => {
  const Centered = styled('div')`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    height: 90vh;
  `;

  const Image = styled('img')`
    border-bottom: '1px solid #c4cacc';
    border-radius: '0';
  `;

  const DisabledBox = styled(Box)`
    width: '100%';
    max-width: '650px';
    text-align: 'center';
    margin: ${themeGet('space.4')};
  `;

  const { logout } = useContext(AuthenticationContext);

  const StyledLink = styled(Link)`
    text-decoration: none;
  `;

  return (
    <PageWrapper>
      <Centered>
        <Card h="90vh">
          <Flex flexDirection="column" alignItems="center">
            <Box>
              <Image
                data-testid="disabled_image"
                src={`${process.env.PUBLIC_URL}/images/visual-disabled.png`}
                alt="Disabled account"
              />
            </Box>
            <DisabledBox data-testid="disabled_text">
              <Heading1>Introducing Lightspeed Kitchen!</Heading1>
              <Box mt={4}>
                <Text pb={4} color="gray-300">
                  Lightspeed Kitchen is the new, intuitive way to process and prepare orders in your
                  restaurant. With Kitchen, a waiter’s order is instantly sent to the Kitchen
                  display screen, eliminating the need for printing. The waiter can then easily
                  follow the progress of the order, thereby improving the overall quality of
                  service.{' '}
                </Text>
                <Text pb={4} color="gray-300">
                  Kitchen is compatible with both touchscreen displays and screens used alongside a
                  bump bar. Contact our sales team for more information about Lightspeed Kitchen!
                </Text>
              </Box>
            </DisabledBox>
            <Box mb={4}>
              <Flex justifyContent="space-between">
                <Button
                  data-testid="contact_sales"
                  href="mailto:sales@lightspeedhq.com"
                  title="sales@lightspeedhq.com"
                  variant="primary"
                  fill
                  mr={3}
                >
                  Contact Sales
                </Button>
                <StyledLink to="/login">
                  <Button data-testid="back_login" title="Back to login" fill onClick={logout}>
                    Back to login
                  </Button>
                </StyledLink>
              </Flex>
            </Box>
          </Flex>
        </Card>
      </Centered>
    </PageWrapper>
  );
};
