import { PaginationInfo, paginationInfoBuilder } from './paginationInfo';
import { TicketDetails, ticketDetailsBuilder } from './ticketDetails';
import { createBuilder } from './typesBuilder';

export type Tickets = PaginationInfo & {
  content: TicketDetails[];
};

const defaultTickets: Tickets = {
  ...paginationInfoBuilder(),
  content: [ticketDetailsBuilder()],
};

const defaultMultiTickets: Tickets = {
  ...paginationInfoBuilder(),
  content: [ticketDetailsBuilder({ id: '9876' }), ticketDetailsBuilder({ id: '12345' })],
};

export const ticketBuilder = createBuilder<Tickets>(defaultTickets);
export const multiTicketBuilder = createBuilder<Tickets>(defaultMultiTickets);
