import { createBuilder } from './typesBuilder';

export type KeyValuePair<T> = {
  key: string;
  value: T;
};

const defaultKeyValuePair: KeyValuePair<string> = {
  key: 'some-id',
  value: 'John Doe',
};

export const keyValuePairBuilder = createBuilder<KeyValuePair<string>>(defaultKeyValuePair);
