import React, { ReactNode } from 'react';
import { themeGet } from '@styled-system/theme-get';

import styled from '@emotion/styled';
import { Flex } from '@lightspeed/cirrus/Core';

import { ActionDivider } from './modal-action-divider';
import { StyledChevronLeftIcon, StyledChevronRightIcon } from './modal-icons';

type ActionProps = {
  children?: ReactNode;
  text: string;
  isBack?: boolean;
  selected?: boolean;
  noChevron?: boolean;
  onClick?: () => void;
  onSelectState: () => void;
};

type BackActionProps = {
  selected?: boolean;
  onClick?: () => void;
  onSelectState: () => void;
};

const ActionWrapper = styled(Flex)<{ selected?: boolean }>`
  flex-direction: row;
  height: ${themeGet('kitchen.sizes.modal.action.height')};
  align-items: center;
  background: ${({ selected }) =>
    selected
      ? themeGet('kitchen.colors.modal.action.background.selected')
      : themeGet('kitchen.colors.modal.action.background.default')};
  cursor: pointer;
`;

const ActionTextWrapper = styled(Flex)<{ selected?: boolean }>`
  flex-grow: 1;
  font-size: ${themeGet('space.3')};
  font-weight: bold;
  height: ${themeGet('kitchen.sizes.modal.action.text.height')};
  color: ${({ selected }) =>
    selected
      ? themeGet('kitchen.colors.modal.action.text.selected')
      : themeGet('kitchen.colors.modal.action.text.default')};
`;

const IconContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: ${themeGet('kitchen.sizes.modal.action.icon.containerWidth')};
`;

export const Action = ({
  text,
  onClick,
  isBack,
  selected,
  children,
  noChevron,
  onSelectState,
}: ActionProps) => {
  return (
    <ActionWrapper
      onClick={onClick}
      selected={selected}
      onMouseOver={onSelectState}
      onFocus={onSelectState}
      data-testid={`modal_action-${text}`}
    >
      {isBack && (
        <IconContainer>
          <StyledChevronLeftIcon selected={selected} />
        </IconContainer>
      )}
      {!isBack && <IconContainer>{children}</IconContainer>}
      <ActionTextWrapper selected={selected}>{text}</ActionTextWrapper>
      {!isBack && !noChevron && (
        <IconContainer>
          <StyledChevronRightIcon selected={selected} />
        </IconContainer>
      )}
      {isBack && <IconContainer />}
    </ActionWrapper>
  );
};

export const BackAction = ({ onClick, selected, onSelectState }: BackActionProps) => {
  return (
    <Flex my={3} flexDirection="column" onClick={onClick}>
      <ActionDivider />
      <Action
        text="Back"
        onClick={onClick}
        isBack
        selected={selected}
        onSelectState={onSelectState}
      />
      <ActionDivider />
    </Flex>
  );
};
