import { withTheme } from '@emotion/react';
import moment from 'moment';
import React, { useContext, FC } from 'react';
import { themeGet } from '@styled-system/theme-get';

import styled from '@emotion/styled';
import { Box, Flex } from '@lightspeed/cirrus/Core';
import { IconClock } from '@lightspeed/cirrus/Icon/Clock';
import { TABLE_TYPE } from '../../../../kitchen-types';

import { TablesContext } from '../../../../state';
import { useTicketDetails } from '../../../../hooks';
import { ReactComponent as IconCustomers } from '../../../../shared/components/icons/customer.svg';
import { FormattedTime, InfoSeparator } from '../../../../shared/components';

export const TicketAdditionalInfo: FC<{
  ticketId: string;
  selected: boolean;
}> = ({ ticketId, selected }) => {
  const { getTableType } = useContext(TablesContext);
  const {
    customerName,
    waiterName,
    tableId,
    receiptId,
    deliveryDate,
    numberOfCustomers,
    receiptSource,
  } = useTicketDetails(ticketId);
  const username = customerName || waiterName;
  const tableType = getTableType(`${tableId}`);

  return (
    <TicketAdditionalInfoWrapper data-testid="ticketAdditionalInfo" selected={selected}>
      <AdditionalInfoWrapper data-testid="receiptId">#{receiptId}</AdditionalInfoWrapper>
      <InfoSeparator size="space.3" mx={2} />
      <AdditionalInfoWrapper data-testid="username">
        {username.length > 20 ? `${username.slice(0, 17)}...` : username}
      </AdditionalInfoWrapper>
      <InfoSeparator size="space.3" mx={2} />
      {tableType === TABLE_TYPE.DELIVERY || tableType === TABLE_TYPE.TAKEAWAY ? (
        <TimeDisplay time={deliveryDate} />
      ) : (
        <CustomerDisplay customers={numberOfCustomers} />
      )}
      {receiptSource === 'onlineOrder' && (
        <>
          <InfoSeparator size="space.3" mx={2} />
          <OnlinePill data-testid="onlinePill">Online</OnlinePill>
        </>
      )}
    </TicketAdditionalInfoWrapper>
  );
};

const TicketAdditionalInfoWrapper = styled(Flex)<{ selected: boolean }>`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: ${themeGet('space.0')};
  margin-right: ${themeGet('space.1')};
  margin-left: ${themeGet('space.0')};
  margin-bottom: ${themeGet('space.0')};
`;

const AdditionalInfoWrapper = styled(Box)`
  color: ${themeGet('kitchen.colors.ticket.header.receiptId')};
  font-size: ${themeGet('kitchen.fonts.size.1')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  line-height: ${themeGet('kitchen.fonts.size.5')};
  height: ${themeGet('kitchen.fonts.size.5')};
`;

const BoldWrapper = styled(AdditionalInfoWrapper)<{ inShowAndTell?: boolean }>`
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  padding-left: 4px;
`;

const ClockIcon = withTheme(({ theme }: any) => (
  <IconClock color={theme.kitchen.colors.ticket.header.clock} />
));

const CustomersIcon = withTheme(({ theme }: any) => (
  <IconCustomers color={theme.kitchen.colors.ticket.header.customers} />
));

export const TimeDisplay = ({ time }: { time?: string }) => (
  <Flex alignItems="center">
    <ClockIcon data-testid="timeDisplayIcon" />
    <BoldWrapper data-testid="timeDisplay">
      {time && <FormattedTime time={moment(time).toISOString()} />}
    </BoldWrapper>
  </Flex>
);

export const CustomerDisplay = ({
  customers,
  inShowAndTell,
}: {
  customers?: number;
  inShowAndTell?: boolean;
}) => (
  <Flex alignItems="center">
    <CustomersIcon inShowAndTell={inShowAndTell} />
    <BoldWrapper data-testid="nrChairs" inShowAndTell={inShowAndTell}>
      {customers}
    </BoldWrapper>
  </Flex>
);

const OnlinePill = styled(AdditionalInfoWrapper)`
  border-radius: ${themeGet('kitchen.borders.radius.3')};
  padding: 0 ${themeGet('kitchen.margins.ticket.header.onlinePill')};
  color: ${themeGet('kitchen.colors.ticket.header.onlinePillText')};
  background: ${themeGet('kitchen.colors.ticket.header.onlinePillBackground')};
`;
