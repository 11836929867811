import { TicketItem } from '../kitchen-types'

export enum ITEM_TYPES {
  HIGHLIGHTED = 1,
  CLEARED = 2,
}

export interface ExpeditedTicket {
  items: Map<string, ITEM_TYPES>
}

export interface ExpeditedItem {
  ticket: string
  product: string
  quantity: number
  state: ITEM_TYPES
  timestamp: any
}

// document id: {ticketId}.{productId}_{modifier hash}...
export const buildProductKey = (item: TicketItem): string => {
  let key = item.productId.toString()
  if (item.modifiers.length > 0) {
    item.modifiers.forEach((mod: string) => {
      key += `_${mod}`
    })
  }
  return key
}
