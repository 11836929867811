import { FloorTableData } from './floorTable';
import { TABLE_TYPE } from './tableTypes';
import { createBuilder } from './typesBuilder';

export type FloorData = {
  name: string;
  oid: string | number;
  tables: FloorTableData[];
};

const defaultFloorData: FloorData = {
  oid: 7577,
  tables: [
    {
      oid: '184188',
      type: TABLE_TYPE.BAR,
      floorId: 7577,
      numberOfClients: 0,
      name: 'TAB',
    },
    {
      oid: '184807',
      type: TABLE_TYPE.TAKEAWAY,
      floorId: 7577,
      numberOfClients: 0,
      name: 'Bar',
    },
  ],
  name: 'Restaurant',
};

export const floorDataBuilder = createBuilder<FloorData>(defaultFloorData);
