import React, { createContext, ReactNode, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { TicketDetails } from 'kitchen-types';

import { getActionFromKey, ACTION_KEYS } from '../utils/keyMap';
import { BUTTONS } from '../shared/components/header/header-buttons';
import { TicketsContext } from '../state';
import { useTracking } from '../hooks';

type ShowAndTellState = {
  selectedElement: BUTTONS | number;
  setSelectedElement: (element: BUTTONS | number) => void;
  buttons: BUTTONS[];
  setButtons: (buttons: BUTTONS[]) => void;
};

const ShowAndTellContext = createContext<ShowAndTellState | undefined>(undefined);

export const ShowAndTellProvider = ({
  children,
  ticket,
}: {
  children: ReactNode;
  ticket: TicketDetails;
}) => {
  const { bump, unbump, prioritise } = useContext(TicketsContext);
  const [selectedElement, setSelectedElement] = useState<number | BUTTONS>(0);
  const [buttons, setButtons] = useState([BUTTONS.CLOSE]);
  const { trackZoomOut } = useTracking();

  const history = useHistory();

  useEffect(() => {
    if (
      typeof selectedElement === 'string' &&
      selectedElement === BUTTONS.UN_BUMP &&
      buttons.indexOf(selectedElement) === -1
    ) {
      setSelectedElement(BUTTONS.BUMP);
    }

    const handleKeypress = (event: KeyboardEvent) => {
      event.preventDefault();

      switch (getActionFromKey(event.key)) {
        case ACTION_KEYS.UP: {
          if (typeof selectedElement === 'number' && selectedElement > 0) {
            setSelectedElement(selectedElement - 1);
          }
          if (typeof selectedElement === 'number' && selectedElement === 0) {
            setSelectedElement(BUTTONS.CLOSE);
          }
          break;
        }

        case ACTION_KEYS.DOWN: {
          if (
            typeof selectedElement === 'number' &&
            selectedElement >= 0 &&
            ticket &&
            selectedElement < ticket.items.length - 1
          ) {
            setSelectedElement(selectedElement + 1);
          }

          if (typeof selectedElement === 'string') {
            setSelectedElement(0);
          }
          break;
        }

        case ACTION_KEYS.RIGHT: {
          if (typeof selectedElement === 'string') {
            const buttonIndex = buttons.indexOf(selectedElement);
            if (buttonIndex < buttons.length - 1) {
              setSelectedElement(buttons[buttonIndex + 1]);
            }
          }

          break;
        }

        case ACTION_KEYS.LEFT: {
          if (typeof selectedElement === 'string') {
            const i = buttons.indexOf(selectedElement);
            if (i > 0 && i <= buttons.length - 1) {
              setSelectedElement(buttons[i - 1]);
            }
          }

          break;
        }

        case ACTION_KEYS.ENTER: {
          if (typeof selectedElement === 'string') {
            switch (selectedElement) {
              case BUTTONS.CLOSE:
                trackZoomOut(event, ticket.receiptId);
                history.push('/');
                break;

              case BUTTONS.BUMP:
                bump(event, ticket.id, ticket.kdsStatusId, ticket.type, ticket.receiptId);
                break;

              case BUTTONS.UN_BUMP:
                unbump(event, ticket.id, ticket.kdsStatusId, ticket.receiptId);
                break;

              case BUTTONS.PRIORITIZE:
                prioritise(event, ticket.id);
                break;

              default:
                trackZoomOut(event, ticket.receiptId);
                history.push('/');
                break;
            }
          }
          break;
        }

        case ACTION_KEYS.BUMP: {
          if (buttons.indexOf(BUTTONS.BUMP) > 0) {
            bump(event, ticket.id, ticket.kdsStatusId, ticket.type, ticket.receiptId);
          }
          break;
        }

        case ACTION_KEYS.UNBUMP: {
          if (buttons.indexOf(BUTTONS.UN_BUMP) > 0) {
            unbump(event, ticket.id, ticket.kdsStatusId, ticket.receiptId);
          }
          break;
        }

        case ACTION_KEYS.PRIORITIZE: {
          if (buttons.indexOf(BUTTONS.PRIORITIZE) > 0) {
            prioritise(event, ticket.id);
          }
          break;
        }

        case ACTION_KEYS.CANCEL: {
          trackZoomOut(event, ticket.receiptId);
          history.push('/');
          break;
        }

        default:
          break;
      }
    };

    window.addEventListener('keyup', handleKeypress);

    return () => window.removeEventListener('keyup', handleKeypress);
  }, [bump, buttons, prioritise, selectedElement, ticket, unbump]);

  return (
    <ShowAndTellContext.Provider
      value={{ selectedElement, setSelectedElement, buttons, setButtons }}
    >
      {children}
    </ShowAndTellContext.Provider>
  );
};

export const useShowAndTellBumpBar = () => {
  const context = useContext(ShowAndTellContext);

  if (!context) {
    throw new Error('Not provided by ShowAndTellProvider');
  }

  const { selectedElement, setButtons, setSelectedElement } = context;

  return { selectedElement, setButtons, setSelectedElement };
};
