import { TICKET_STATUS } from '../../../types/TicketStatuses';
import { MixpanelEvents } from '../../../types/mixpanel-events';

export const HEADER_BUTTONS = [
  { id: 'more', mixPanelEvent: '' },
  { id: 'all', status: TICKET_STATUS.ALL, mixPanelEvent: MixpanelEvents.FILTER_ALL },
  { id: 'new', status: TICKET_STATUS.NEW, mixPanelEvent: MixpanelEvents.FILTER_NEW },
  {
    id: 'preparing',
    status: TICKET_STATUS.PREPARING,
    mixPanelEvent: MixpanelEvents.FILTER_PREPARING,
  },
  { id: 'done', status: TICKET_STATUS.DONE, mixPanelEvent: MixpanelEvents.FILTER_DONE },
  { id: 'archived', status: TICKET_STATUS.ARCHIVED, mixPanelEvent: MixpanelEvents.FILTER_ARCHIVED },
];

export enum BUTTONS {
  CLOSE = 'close',
  BUMP = 'bump',
  UN_BUMP = 'unBump',
  PRIORITIZE = 'prioritize',
}
