export enum TICKET_STATUS {
  ALL = -1,
  NEW = 0,
  PREPARING = 1,
  DONE = 2,
  ARCHIVED = 3,
  VOIDED = 4,
}

export const getStatusAsText = (status?: TICKET_STATUS, voided?: boolean) => {
  if (voided) {
    return 'Voided';
  }
  switch (status) {
    case TICKET_STATUS.NEW:
      return 'New';
    case TICKET_STATUS.PREPARING:
      return 'Preparing';
    case TICKET_STATUS.DONE:
      return 'Done';
    case TICKET_STATUS.VOIDED:
      return 'Voided';
    case TICKET_STATUS.ARCHIVED:
      return 'Archived';
    default:
      return '';
  }
};

export const getMappedTicketStatus = (value: TICKET_STATUS): number | null => {
  switch (value) {
    case TICKET_STATUS.NEW:
      return 0;
    case TICKET_STATUS.PREPARING:
      return 1;
    case TICKET_STATUS.DONE:
      return 2;
    default:
      return null;
  }
};
