import axios, { AxiosResponse } from 'axios'

import debug from 'debug'
const log = debug('@kds')

interface InProgress {
  group: string
  label: string
}

export const ikewClient = axios.create({
  baseURL: process.env.REACT_APP_IKEW,
})

interface IkewApi {
  init: () => boolean
  inProgress: (slot: InProgress) => Promise<any>
  ready: (slotID: string) => Promise<any>
  unbump: (slotID: string) => Promise<any>
}

const IKEW_STORE = process.env.REACT_APP_IKEW_STORE || null

export const ikewApi: IkewApi = {
  init: () => {
    if (IKEW_STORE) {
      return true
    }
    if (!IKEW_STORE) {
      log('Invalid iKew storeId')
      return false
    }
    return true
  },

  inProgress: async (slot: InProgress): Promise<any> => {
    if (!ikewApi.init()) {
      return
    }
    try {
      const response = await ikewClient.post(`/slots/${IKEW_STORE}/inProgress`, slot)
      return response.data.slotID
    } catch (err) {
      log('Unable to connect to ikew hub')
      return null
    }
  },

  ready: async (slotID: string): Promise<any> => {
    if (!ikewApi.init()) {
      return
    }
    try {
      const response = await ikewClient.post(`/slots/${IKEW_STORE}/ready`, { slotID })
      return response.data
    } catch (err) {
      log('Unable to connect to ikew hub')
      return null
    }
  },

  unbump: async (slotID: string): Promise<any> => {
    if (!ikewApi.init()) {
      return
    }
    try {
      const response = await ikewClient.post(`/slots/${IKEW_STORE}/unbump`, { slotID })
      return response.data
    } catch (err) {
      log('Unable to connect to ikew hub')
      return null
    }
  },
}

ikewClient.interceptors.response.use(
  (response): AxiosResponse<any> | Promise<AxiosResponse<any>> => {
    const {
      data: { error },
    } = response
    if (error) {
      return Promise.reject(response)
    }
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)
