import React, { FC } from 'react';

import { Flex, Box } from '@lightspeed/cirrus/Core';
import { IconProps } from '@lightspeed/cirrus/Icon/utils/iconFactory';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from '@emotion/react';

import { ReactComponent as FlagIcon } from '../../icons/flag.svg';
import { IKitchenTheme } from '../../../../theming/themes';
import { PrimaryInfo } from './primary-info';
import { TICKET_STATUS } from '../../../../types/TicketStatuses';
import { TimeAndStatus } from './time-and-status';
import { TicketAdditionalInfo } from './ticket-additional-info';
import { useTicketDetails } from '../../../../hooks';

export const NormalHeader: FC<{ ticketId: string; number: number }> = ({ ticketId, number }) => {
  const {
    modificationDate,
    kdsStatusId,
    prioritized,
    tableId,
    type,
    orderReference,
  } = useTicketDetails(ticketId);
  return (
    <>
      <TicketBasicInfoWrapper>
        {number && (
          <SequenceNumberWrapper data-testid="sequenceNumber">{number}</SequenceNumberWrapper>
        )}
        <TimeAndStatus date={modificationDate} status={kdsStatusId} type={type} />
        {prioritized && kdsStatusId < TICKET_STATUS.ARCHIVED && <StyledPrioritizedIcon />}
      </TicketBasicInfoWrapper>
      <PrimaryInfo tableId={`${tableId}`} orderReference={orderReference} />
      <TicketAdditionalInfo ticketId={ticketId} />
    </>
  );
};

const TicketBasicInfoWrapper = styled(Flex)`
  margin-right: ${themeGet('space.2')};
  margin-left: ${themeGet('space.2')};
  margin-bottom: ${themeGet('kitchen.margins.ticket.header.tableName')};
  height: ${themeGet('space.7')};
  margin-top: 0;
  align-items: center;
`;

const SequenceNumberWrapper = styled(Box)`
  font-size: ${themeGet('kitchen.fonts.size.1')};
  width: ${themeGet('space.5')};
  height: ${themeGet('kitchen.sizes.ticket.header.sequenceNumber')};
  color: ${themeGet('kitchen.colors.ticket.header.sequenceNumberText')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  line-height: ${themeGet('kitchen.sizes.ticket.header.sequenceNumber')};
  text-align: center;
  background: ${themeGet('kitchen.colors.ticket.header.sequenceNumberBackground')};
  border: ${themeGet('kitchen.borders.ticket.header.sequenceNumber')};
  border-color: ${themeGet('kitchen.colors.ticket.header.sequenceNumberBorder')};
  border-radius: ${themeGet('kitchen.borders.radius.1')};
`;

const PrioritizedIconStyled = styled(FlagIcon)<IconProps>``;

const PrioritizedIcon = ({ theme }: { theme: IKitchenTheme }) => (
  <PrioritizedIconStyled
    fill={theme.kitchen.colors.ticket.header.prioritizedIconColor}
    color={theme.kitchen.colors.ticket.header.prioritizedIconColor}
    size={theme.kitchen.sizes.ticket.header.prioritizedIcon}
  />
);
const StyledPrioritizedIcon = withTheme(PrioritizedIcon);
