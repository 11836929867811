import { md } from 'node-forge';
import Axios from 'axios';

import {
  // ApiResponse,
  CompanySettingsData,
  FloorData,
  FloorTableData,
  // GenericSettingObject,
  KeyValuePair,
  KitchenProfile,
  KitchenProfileData,
  KitchenSetting,
  LoginData,
  ProductCategoryData,
  ProductLayoutData,
  Tickets,
} from 'kitchen-types';

import { defaultKitchenSettings } from '../defaultSettings/kitchenSettings';
import { rpcClient, rpcLogin } from './rpc';
import { KDS_APP_VERSION } from '../types/localStorageAliases';

export const kitchenApi = {
  login: async (username: string, password: string): Promise<LoginData> => {
    const settingsDefinitionHash = md.md5.create();
    settingsDefinitionHash.update(JSON.stringify(defaultKitchenSettings));
    const appVersion = localStorage.getItem(KDS_APP_VERSION);
    const response = await rpcLogin.post(`?login`, {
      method: 'kitchen.login',
      params: [
        {
          applicationId: 'KDS',
          applicationVersion: appVersion,
          deviceId: 'KDS',
          deviceModel: '',
          deviceName: '',
          deviceVersion: '',
          locale: '',
          optout: false,
          password,
          settingsDefinitionHash: settingsDefinitionHash.digest().toHex(),
          settingsDefinitionVersion: defaultKitchenSettings.settingsDefinitionVersion,
          username,
        },
      ],
    });

    return {
      token: response.data.result.token,
      sendSettingsDefinition: response.data.result.sendSettingsDefinition,
    };
  },

  getCompanySettings: async (): Promise<CompanySettingsData> => {
    const response = await rpcClient.post(`?getCompany`, {
      method: 'kitchen.getCompany',
    });

    return {
      oid: response.data.result.oid,
      kdsEnabled: response.data.result.kdsEnabled,
      kitchenAutoPrinting: response.data.result.kitchenAutoPrinting,
      locale: response.data.result.locale,
      timeZoneID: response.data.result.timeZoneID,
      enforceLiteserver: response.data.result.enforceLiteserver,
      featureFlagKdsV3: response.data.result.featureFlagKdsV3,
    };
  },

  getKitchenProfiles: async (): Promise<KitchenProfileData[]> => {
    const response = await rpcClient.post('?getDeviceProfiles', {
      method: 'kitchen.getDeviceProfiles',
      params: [['kitchen']],
    });
    return response.data.result.map(({ deviceProfile, settings }: KitchenProfile) => {
      const parsedSettings: any = settings.reduce(
        (allSettings: any, setting: KitchenSetting) => {
          try {
            return {
              ...allSettings,
              [setting.settingKey]: JSON.parse(`${setting.settingValue}`),
            };
          } catch (e) {
            return { ...allSettings, [setting.settingKey]: setting.settingValue };
          }
        },
        {},
      );

      return {
        defaultProfile: deviceProfile.defaultProfile,
        name: deviceProfile.name,
        oid: deviceProfile.oid,
        settings: parsedSettings,
      };
    });
  },

  getFloors: async (): Promise<FloorData[]> => {
    const response = await rpcClient.post(`?getFloors`, {
      method: 'kitchen.getFloors',
    });
    return response.data.result.map((floor: FloorData) => {
      return {
        name: floor.name,
        oid: floor.oid,
        tables: floor.tables.map((table: FloorTableData) => {
          return {
            floorId: table.floorId,
            name: table.name,
            numberOfClients: table.numberOfClients,
            oid: table.oid,
            type: table.type,
          };
        }),
      };
    });
  },

  getProductCategories: async (): Promise<ProductCategoryData[]> => {
    const response = await rpcClient.post(
      `?getProductCategories`,
      {
        method: 'kitchen.getProductCategories',
      },
    );

    return response.data.result.map(({ name, oid, products }: any) => {
      return {
        name,
        oid,
        products: products.map(
          ({ name, oid, imageLocationKitchen, imageLocation, kitchenName, info, pId }: any) => {
            return {
              name,
              oid,
              imageLocationKitchen,
              imageLocation,
              kitchenName,
              info,
              pId,
            };
          },
        ),
      };
    });
  },

  getProductLayouts: async (): Promise<ProductLayoutData[]> => {
    const response = await rpcClient.post(`?getProductLayouts`, {
      method: 'kitchen.getProductLayouts',
    });

    return response.data.result.map(
      ({
        categoryId,
        imageLocation,
        name,
        oid,
        productIds,
        relativeImageLocation,
      }: ProductLayoutData) => {
        return {
          categoryId,
          imageLocation,
          name,
          oid,
          productIds,
          relativeImageLocation,
        };
      },
    );
  },

  getAdditionalInfo: async (): Promise<
    [KitchenProfileData[], ProductCategoryData[], ProductLayoutData[], string]
  > => {
    return Promise.all([
      kitchenApi.getKitchenProfiles(),
      kitchenApi.getProductCategories(),
      kitchenApi.getProductLayouts(),
      kitchenApi.getBackendVersion(),
    ]);
  },

  saveSettingsDefinition: (): Promise<any> => {
    return rpcClient.post('', {
      method: 'kitchen.saveSettingsDefinition',
      params: [JSON.stringify(defaultKitchenSettings), 'kitchen'],
    });
  },

  saveDeviceProfile: async (
    profile: KitchenProfileData & {
      moduleName: string;
      companyId: number;
    },
    deviceProfileSettings: KeyValuePair<string>[],
  ): Promise<KitchenProfileData> => {
    const response = await rpcClient.post('', {
      method: 'kitchen.saveDeviceProfile',
      params: [profile, deviceProfileSettings, []],
    });
    return {
      defaultProfile: response.data.result.deviceProfile.defaultProfile,
      name: response.data.result.deviceProfile.name,
      oid: response.data.result.deviceProfile.oid,
      settings: {},
    };
  },

  getTickets: async (
    from: string,
    to: string,
    includedStatuses: number | null,
    tableTypes: string[],
    showKitchenItems: boolean,
    showBarItems: boolean,
    productCategoryIds: number[],
    floorIds: number[],
    page: number,
    numberOfTickets: number,
  ): Promise<Tickets> => {
    const response = await rpcClient.post(`?getTickets`, {
      method: `kitchen.getTickets`,
      params: [
        {
          from,
          to,
          bar: showBarItems,
          floorIds,
          includedKdsStatus: includedStatuses,
          kitchen: showKitchenItems,
          pageable: { page, size: numberOfTickets },
          productCategoryIds,
          tableTypes,
        },
      ],
    });
    return response.data.result;
  },

  getHistoryTickets: async (
    from: string,
    to: string,
    showKitchenItems: boolean,
    showBarItems: boolean,
    page: number,
    numberOfTickets: number,
  ): Promise<Tickets> => {
    const response = await rpcClient.post(`?getHistoryTickets`, {
      method: `kitchen.getRecentDeletedTickets`,
      params: [
        {
          from,
          to,
          bar: showBarItems,
          floorIds: [],
          includedKdsStatus: 3,
          kitchen: showKitchenItems,
          pageable: { page, size: numberOfTickets },
          productCategoryIds: [],
          tableTypes: [],
        },
      ],
    });
    return response.data.result;
  },

  updateTicketStatus: async (ticketId: string, url: string, method: string): Promise<any> => {
    await rpcClient.post(`?${url}`, {
      method,
      params: [ticketId],
    });
  },

  clearTickets: async (from: string, to: string): Promise<void> => {
    await rpcClient.post('?clearTickets', {
      method: 'kitchen.clearTickets',
      params: [from, to],
    });
  },

  getBackendVersion: async (): Promise<string> => {
    // const info = await Axios.get(`${window.location.origin.replace(/-web/, '')}/PosServer/info`);
    // const {
    //   version: { major, minor },
    // } = await info.data;
    const major = 1
    const minor = 618
    return `${major}.${minor}`;
  },
};
