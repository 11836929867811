import { TicketDetails } from 'kitchen-types'
import { TICKET_STATUS } from '../types/TicketStatuses'
import { ExpeditedTicket } from '../types/ExpeditedTickets'
import {
  KitchenAction,
  loadTickets,
  bumpTicket,
  unbumpTicket,
  selectButton,
  selectTicket,
  selectPage,
  filterTickets,
  ticketRefreshed,
  prioritiseTicket,
  deprioritiseTicket,
  receiptRefreshed,
  updateModifierTotals,
  updateClearedItems,
} from './tickets.action'
import { TotalsIndexEntry } from '../types/TotalsIndex'

export interface KitchenState {
  tickets: TicketDetails[]
  ticketsCounts: {
    [key: string]: number
  }
  pagination: { numberOfPages: number; selectedPage: number }
  selectedTicket: string | null
  selectedButton: string | null
  refreshingTickets: string[]
  stateFilter: TICKET_STATUS
  loading: boolean
  lastTicketReceivedAt: string | null
  items: { [key: string]: ExpeditedTicket }
  totalsIndex: { [key: string]: TotalsIndexEntry }
  clearedIndex: { [key: string]: number }
}

export const ticketReducer = (state: KitchenState, action: KitchenAction): KitchenState => {
  const { type } = action

  switch (type) {
    case 'LOAD_TICKETS': {
      const {
        payload: {
          tickets,
          numberOfPages,
          originatingTicket,
          totals,
          totalsIndex,
          selectedTicketIndex,
          keepSelection,
        },
      } = action as loadTickets

      const newState = {
        ...state,
        tickets,
        ticketsCounts: totals,
        totalsIndex: {},
        pagination: { ...state.pagination, numberOfPages },
        loading: false,
      } as KitchenState

      if (!keepSelection) {
        if (selectedTicketIndex !== undefined) {
          const newIndex = Math.min(selectedTicketIndex, tickets.length - 1)
          if (tickets[newIndex] && tickets[newIndex].id) {
            newState.selectedButton = null
            newState.selectedTicket = tickets[newIndex].id
          } else {
            newState.selectedTicket = null
            newState.selectedButton = 'more'
          }
        } else if (tickets.length) {
          if (tickets[0] && tickets[0].id) {
            newState.selectedButton = null
            newState.selectedTicket = tickets[0].id
          } else {
            newState.selectedTicket = null
            newState.selectedButton = 'more'
          }
        }
        if (tickets.length === 0) {
          newState.selectedTicket = null
          newState.selectedButton = 'more'
        }
      }

      if (originatingTicket) {
        newState.refreshingTickets = []
      }

      if (
        state.ticketsCounts.all !== -1 &&
        state.ticketsCounts.new !== -1 &&
        state.ticketsCounts.all < newState.ticketsCounts.all &&
        state.ticketsCounts.new < newState.ticketsCounts.new
      ) {
        newState.lastTicketReceivedAt = new Date().toISOString()
      } else {
        newState.lastTicketReceivedAt = null
      }

      if (totalsIndex) {
        newState.totalsIndex = totalsIndex
      }

      return newState
    }
    case 'BUMP_TICKET': {
      const { payload } = action as bumpTicket
      return {
        ...state,
        refreshingTickets: [...state.refreshingTickets, payload.id],
      }
    }
    case 'UNBUMP_TICKET': {
      const { payload } = action as unbumpTicket
      return {
        ...state,
        refreshingTickets: [...state.refreshingTickets, payload.id],
      }
    }
    case 'SELECT_TICKET': {
      const { payload } = action as selectTicket
      if (state.tickets.length !== 0) {
        if (state.tickets.findIndex((ticket) => ticket.id === payload.id) > -1) {
          return { ...state, selectedTicket: payload.id, selectedButton: null }
        }
        return { ...state, selectedTicket: state.tickets[0].id, selectedButton: null }
      }
      return { ...state, selectedTicket: null, selectedButton: 'more' }
    }

    case 'SELECT_BUTTON': {
      const { payload } = action as selectButton
      return { ...state, selectedTicket: null, selectedButton: payload.id }
    }
    case 'FILTER_TICKETS': {
      const { payload } = action as filterTickets
      return {
        ...state,
        stateFilter: payload.status,
        selectedButton: null,
        selectedTicket: null,
        loading: true,
      }
    }
    case 'SELECT_PAGE': {
      const previousPage = state.pagination.selectedPage
      const { payload } = action as selectPage
      return {
        ...state,
        pagination: { ...state.pagination, selectedPage: payload.page },
        selectedTicket: null,
        loading: previousPage !== payload.page,
        refreshingTickets: [],
      }
    }
    case 'TICKET_REFRESHED': {
      const { payload } = action as ticketRefreshed
      return {
        ...state,
        refreshingTickets: state.refreshingTickets.filter((id) => id !== payload.id),
      }
    }

    case 'RECEIPT_REFRESHED': {
      const { payload } = action as receiptRefreshed

      const allTicketsForReceipt = state.tickets
        .filter((ticket: TicketDetails) => ticket.receiptId === payload.id)
        .map((ticket) => ticket.id)

      return {
        ...state,
        refreshingTickets: state.refreshingTickets.filter(
          (id) => allTicketsForReceipt.includes(id) === false,
        ),
      }
    }

    case 'PRIORITISE_TICKET': {
      const { payload } = action as prioritiseTicket

      const allTicketsForReceipt = state.tickets
        .filter((ticket: TicketDetails) => ticket.receiptId === payload.receiptId)
        .map((ticket) => ticket.id)

      return {
        ...state,
        refreshingTickets: [...state.refreshingTickets, ...allTicketsForReceipt],
      }
    }

    case 'DEPRIORITISE_TICKET': {
      const { payload } = action as deprioritiseTicket

      const allTicketsForReceipt = state.tickets
        .filter((ticket: TicketDetails) => ticket.receiptId === payload.receiptId)
        .map((ticket) => ticket.id)

      return {
        ...state,
        refreshingTickets: state.refreshingTickets.filter((id) =>
          allTicketsForReceipt.includes(id),
        ),
      }
    }

    case 'TOGGLE_ITEM': {
      return {
        ...state,
      }
    }

    case 'UPDATE_CLEARED_ITEMS': {
      const { payload } = action as updateClearedItems
      const { clearedIndex } = payload
      return {
        ...state,
        clearedIndex,
      }
    }

    case 'UPDATE_MODIFIER_TOTALS': {
      const { payload } = action as updateModifierTotals

      const { totalsIndex } = payload

      return {
        ...state,
        totalsIndex,
      }
    }

    default:
      return state
  }
}
