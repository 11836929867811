import { themeGet } from '@styled-system/theme-get'

import { TICKET_STATUS } from '../types/TicketStatuses'
import { IKitchenTheme } from '../theming/themes'

export const getBackground = ({
  status,
  type,
  ...theme
}: {
  status: TICKET_STATUS | null
  type: string
  theme: IKitchenTheme
}) => {
  if (type === 'voided') {
    return themeGet('kitchen.colors.ticket.status.voided')(theme)
  }
  switch (status) {
    case TICKET_STATUS.PREPARING:
      return themeGet('kitchen.colors.ticket.status.cooking')(theme)
    case TICKET_STATUS.DONE:
      return themeGet('kitchen.colors.ticket.status.done')(theme)
    case TICKET_STATUS.ARCHIVED:
      return themeGet('kitchen.colors.ticket.status.archived')(theme)
    default:
      return themeGet('kitchen.colors.ticket.status.new')(theme)
  }
}
