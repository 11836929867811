import React, { forwardRef } from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled from '@emotion/styled';

import { Box } from '@lightspeed/cirrus/Core';

import { Modifiers } from './modifiers';

export const TicketItem = forwardRef(
  (
    {
      quantity,
      name,
      modifiers,
      selected,
      index,
      plu,
      onClick,
    }: {
      quantity: number;
      name: string;
      modifiers?: string[];
      selected: boolean;
      index: number;
      plu: string;
      onClick: () => void;
    },
    ref,
  ) => (
    <ItemContainer
      selected={selected}
      onClick={onClick}
      ref={ref}
      data-testid={`item-index-${index}`}
    >
      <ItemPlu selected={selected}>{plu}</ItemPlu>
      <TicketItemWrapper data-testid={`ticketItem-${index}`} selected={selected}>
        <Box data-testid={`itemQuantity-${index}`}>{quantity}</Box>
        <ItemName data-testid={`itemName-${index}`}>{name}</ItemName>
        <Box />
        <ModifierWrapper>
          {modifiers && modifiers.length ? <Modifiers modifiers={modifiers} /> : null}
        </ModifierWrapper>
      </TicketItemWrapper>
    </ItemContainer>
  ),
);

const ItemContainer = styled(Box)<{ selected: boolean }>`
  display: grid;
  grid-auto-flow: row;
  border: ${({ theme, selected }) =>
    theme.kitchen.borders.ticket[selected ? 'selected' : 'notSelected']};
  border-top: none;
  padding-bottom: ${({ selected }) => (selected ? '0' : '5px')};
  border-bottom: ${({ theme, selected }) =>
    selected
      ? theme.kitchen.borders.ticket.selected
      : theme.kitchen.borders.showntell.list_separator};
`;

const TicketItemWrapper = styled(Box)<{ selected: boolean }>`
  display: grid;
  grid-template-columns: ${themeGet('kitchen.sizes.ticket.items.quantityWidth')} 1fr;
  grid-template-rows: auto minmax(0, auto);
  word-wrap: break-word;
  margin-left: ${themeGet('space.2')};
  padding: ${themeGet('space.2')} 0;
  line-height: ${themeGet('kitchen.fonts.size.3')};
  font-size: ${themeGet('space.3')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  color: ${themeGet('kitchen.colors.ticket.items.text')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
`;

const ItemName = styled(Box)`
  word-break: break-all;
`;

const ModifierWrapper = styled(Box)`
  color: ${themeGet('kitchen.colors.ticket.items.modifier')};
  font-size: ${themeGet('kitchen.fonts.size.1')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  word-break: break-all;
  margin-top: ${themeGet('space.2')};
`;

const ItemPlu = styled(Box)<{ selected: boolean }>`
  height: ${themeGet('space.7')};
  background: ${({
    selected,
    theme: {
      kitchen: {
        colors: { activeItemColor },
      },
    },
  }) => (selected ? activeItemColor : '')};
  color: ${({ selected }) =>
    selected
      ? themeGet('kitchen.colors.ticket.selectedText')
      : themeGet('kitchen.colors.ticket.items.text')};
  align-items: center;
  padding-left: ${themeGet('space.4')};
  font-size: ${themeGet('space.2')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  line-height: ${themeGet('space.7')};
`;
