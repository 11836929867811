import moment from 'moment'

export const getPreviousDay = (date: Date) => {
  return moment(date).subtract(1, 'days')
}

export const getStartOfPreviousDay = (date: Date) => {
  return getPreviousDay(date).startOf('day')
}

export const getEndOfToday = () => {
  return moment().endOf('day')
}
// This should be removed and replaced with standard ISO format
export const getCustomFormattedDate = (date: moment.Moment | Date) => {
  return moment(date).format('YYYYMMDDTHHmmss.sssZ')
}
