import { createBuilder } from './typesBuilder';

export type CompanySettingsData = {
  oid: number;
  kdsEnabled: boolean;
  kitchenAutoPrinting: boolean;
  locale: string;
  timeZoneID: string;
  enforceLiteserver: boolean;
  featureFlagKdsV3: string;
};

const defaultCompanySettingsData: CompanySettingsData = {
  oid: 1234,
  kdsEnabled: false,
  kitchenAutoPrinting: false,
  locale: 'en-US',
  timeZoneID: 'UTC',
  enforceLiteserver: false,
  featureFlagKdsV3: 'ENABLED',
};

export const companySettingsDataBuilder = createBuilder<CompanySettingsData>(
  defaultCompanySettingsData,
);
