import React, { useCallback, useContext, useState, useEffect, FC } from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { animated } from 'react-spring';

import { Flex, Box } from '@lightspeed/cirrus/Core';

import { RowsColumns } from 'kitchen-types';

import { KitchenHeader, AppLoader, MoreModal } from '../../shared/components';
import { TicketsContext, AppContext } from '../../state';
import { TicketPlaceholder } from '../components/ticket-placeholder';
import { useGridBumpbar, useGridSwipe } from '../../hooks';
import { GridFooter } from '../components/footer/grid-footer';
import { Ticket } from '../components/ticket/ticket';
import { useSynchronizedAnimation } from '../../shared/hooks/synchronized-animation.hook';
import { LAYOUTS } from '../../types/Layouts';
import { KDS_DISPLAY_LAYOUT } from '../../types/localStorageAliases';

export const GridLayout: FC<{}> = () => {
  const { getGridDimensions, updateSelectedLayout } = useContext(AppContext);
  const {
    state: { tickets, loading },
  } = useContext(TicketsContext);

  const [defaultTickets, setDefaultTickets] = useState<number[]>([]);
  const [showMoreModal, setShowMoreModal] = useState<boolean>(false);

  const onMoreClicked = useCallback(() => {
    setShowMoreModal(!showMoreModal);
  }, [showMoreModal]);

  const { attachSwipeEvents, getSwipeStyle } = useGridSwipe();
  useSynchronizedAnimation(['blink-new']);

  useGridBumpbar(showMoreModal, onMoreClicked);

  useEffect(() => {
    if (tickets !== undefined) {
      const { rows, columns } = getGridDimensions();
      const defaultTicketsTemp = Array.from(Array(rows * columns), (_, x) => x + 1);
      defaultTicketsTemp.splice(0, tickets && tickets.length ? tickets.length : 0);
      setDefaultTickets(defaultTicketsTemp);
    }
  }, [getGridDimensions, tickets]);

  if (localStorage.getItem(KDS_DISPLAY_LAYOUT) !== LAYOUTS.LAYOUT_GRID) {
    updateSelectedLayout(LAYOUTS.LAYOUT_GRID);
  }

  return (
    <AppContainer>
      {showMoreModal && <MoreModal onCloseModal={onMoreClicked} />}
      <KitchenHeader onMoreClicked={onMoreClicked} />
      <AnimatedGrid
        rows={getGridDimensions().rows}
        columns={getGridDimensions().columns}
        {...attachSwipeEvents()}
        style={getSwipeStyle()}
      >
        {loading ? (
          <AppLoader />
        ) : (
          <>
            {tickets.map((ticket, index) => (
              <Ticket
                ticketId={ticket.id}
                modificationDate={ticket.modificationDate}
                number={index + 1}
                navigationId={ticket.navigationId}
                kdsStatusId={ticket.kdsStatusId}
                type={ticket.type}
                receiptId={ticket.receiptId}
                key={ticket.id}
                orderReference={
                  ticket.receiptSource === 'onlineOrder' ? ticket.orderReference : null
                }
              />
            ))}
            {defaultTickets.map(elem => (
              <TicketPlaceholder number={elem} key={elem} />
            ))}
          </>
        )}
      </AnimatedGrid>
      <GridFooter />
    </AppContainer>
  );
};

const AppContainer = styled(Flex)`
  flex-direction: column;
  width: 100vw;
  user-select: none;
  background: ${themeGet('kitchen.colors.background')};
  @media print {
    display: none;
  }
`;

const GridContainer = styled(Box)<RowsColumns>`
  display: inline-grid;
  height: ${props =>
    `calc(100vh - ${props.theme.kitchen.sizes.header.height} - ${
      props.theme.kitchen.sizes.footer.height
    });`};
  max-height: ${props =>
    `calc(100vh - ${props.theme.kitchen.sizes.header.height} - ${
      props.theme.kitchen.sizes.footer.height
    });`};
  width: 100vw;
  grid-template-columns: ${props =>
    `repeat(${props.columns}, calc((100vw - (2*${props.theme.space[2]})) / ${props.columns}))`};
  grid-template-rows: ${props =>
    `repeat(${props.rows}, calc((100vh - ${props.theme.kitchen.sizes.header.height} - ${
      props.theme.kitchen.sizes.footer.height
    }) / ${props.rows}))`};
  gap: 0;
  padding: 0 ${themeGet('space.2')};
  background: ${themeGet('kitchen.colors.background')};
  transform: translateX(100);
  will-change: transform;
`;

const AnimatedGrid = animated(GridContainer);
