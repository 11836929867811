import { useContext } from 'react';

import { TicketsContext } from '../../state';

export const useSelection = (id: string) => {
  const {
    state: { selectedTicket },
    selectTicket,
  } = useContext(TicketsContext);
  const selected = selectedTicket === id;
  return {
    selected,
    selectTicket,
  };
};
