import { withTheme } from '@emotion/react'
import moment from 'moment'
import React, { useContext, FC } from 'react'
import { themeGet } from '@styled-system/theme-get'
import parseISO from 'date-fns/parseISO'
import differenceInMinutes from 'date-fns/differenceInMinutes'

import styled from '@emotion/styled'
import { Box, Flex } from '@lightspeed/cirrus/Core'
import { IconClock } from '@lightspeed/cirrus/Icon/Clock'
import { IconProps } from '@lightspeed/cirrus/Icon/utils/iconFactory'
import { TABLE_TYPE } from '../../../../kitchen-types'

import { TablesContext } from '../../../../state'
import { useTicketDetails } from '../../../../hooks'
import { IKitchenTheme } from '../../../../theming/themes'
import { ReactComponent as IconCustomers } from '../../icons/customer.svg'
import { FormattedTime } from '../../formatted-time'
import { InfoSeparator } from './info-separator'

interface ticketAdditionalInfoProps {
  ticketId: string
  inShowAndTell?: boolean
}

export const TicketAdditionalInfo: FC<ticketAdditionalInfoProps> = ({
  ticketId,
  inShowAndTell,
}) => {
  const { getTableType } = useContext(TablesContext)
  const { tableId, deliveryDate, numberOfCustomers, receiptSource } = useTicketDetails(ticketId)
  const tableType = getTableType(`${tableId}`)

  return (
    <TicketAdditionalInfoWrapper data-testid="ticketAdditionalInfo">
      {tableType === TABLE_TYPE.DELIVERY || tableType === TABLE_TYPE.TAKEAWAY ? (
        <TimeDisplay time={deliveryDate} />
      ) : (
        <CustomerDisplay customers={numberOfCustomers} inShowAndTell={inShowAndTell} />
      )}
      {(tableType === TABLE_TYPE.DELIVERY || tableType === TABLE_TYPE.TAKEAWAY) && (
        <>
          <InfoSeparator mx={1} />
          <OnlinePill data-testid="onlinePill">T A K E A W A Y</OnlinePill>
        </>
      )}
      {(receiptSource === 'onlineOrder' || tableType === TABLE_TYPE.DELIVERY) && (
        <TimeDistanceDisplay time={deliveryDate} />
      )}
    </TicketAdditionalInfoWrapper>
  )
}

const TicketAdditionalInfoWrapper = styled(Flex)`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: ${themeGet('space.0')};
  margin-right: ${themeGet('space.2')};
  margin-left: ${themeGet('space.2')};
  margin-bottom: ${themeGet('space.2')};
`

const AdditionalInfoWrapper = styled(Box)`
  color: ${themeGet('kitchen.colors.ticket.header.receiptId')};
  font-size: ${themeGet('kitchen.fonts.size.0')};
  font-weight: ${themeGet('kitchen.fonts.weight.slim')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  line-height: ${themeGet('kitchen.fonts.size.3')};
  height: auto;
`

const BoldWrapper = styled(AdditionalInfoWrapper)<{ inShowAndTell?: boolean; warn?: boolean }>`
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  color: ${({ warn }) => (warn ? 'rgb(202, 10, 10)' : 'inherit')};
`

const ClockIconStyled = styled(IconClock)<IconProps>`
  margin-right: ${themeGet('kitchen.margins.ticket.header.clockRight')};
`

const CustomersIconStyled = styled(IconCustomers)<IconProps>`
  margin-right: ${themeGet('kitchen.margins.ticket.header.customersRight')};
`

const ClockIcon = ({ theme }: { theme: IKitchenTheme }) => (
  <ClockIconStyled
    color={theme.kitchen.colors.ticket.header.clock}
    size={theme.kitchen.sizes.ticket.header.clock}
  />
)
const StyledClockIcon = withTheme(ClockIcon)

const CustomersIcon = ({
  theme,
  inShowAndTell,
}: {
  theme: IKitchenTheme
  inShowAndTell?: boolean
}) => (
  <CustomersIconStyled
    color={theme.kitchen.colors.ticket.header.customers}
    size={
      inShowAndTell
        ? theme.kitchen.sizes.ticket.header.showAndTellIconSize
        : theme.kitchen.sizes.ticket.header.customers
    }
  />
)
const StyledCustomersIcon = withTheme(CustomersIcon)

export const TimeDisplay = ({ time }: { time?: string }) => {
  if (!time) {
    return null
  }
  const minsDiff = differenceInMinutes(parseISO(time), new Date())
  return (
    <Flex alignItems="center">
      <StyledClockIcon data-testid="timeDisplayIcon" />
      <BoldWrapper data-testid="timeDisplay" warn={minsDiff >= 25}>
        {time && <FormattedTime time={moment(time).toISOString()} />}
      </BoldWrapper>
    </Flex>
  )
}

export const TimeDistanceDisplay = ({ time }: { time?: string }) => {
  if (!time) {
    return null
  }
  const minsDiff = differenceInMinutes(parseISO(time), new Date())
  return minsDiff >= 25 ? (
    <>
      <InfoSeparator mx={1} />
      <OnlinePill data-testid="onlinePill">HOLD</OnlinePill>
    </>
  ) : null
}

export const CustomerDisplay = ({
  customers,
  inShowAndTell,
}: {
  customers?: number
  inShowAndTell?: boolean
}) => (
  <Flex alignItems="center">
    <StyledCustomersIcon inShowAndTell={inShowAndTell} />
    <BoldWrapper data-testid="nrChairs" inShowAndTell={inShowAndTell}>
      {customers}
    </BoldWrapper>
  </Flex>
)

const OnlinePill = styled(AdditionalInfoWrapper)`
  border-radius: ${themeGet('kitchen.borders.radius.3')};
  padding: 0 ${themeGet('kitchen.margins.ticket.header.onlinePill')};
  color: ${themeGet('kitchen.colors.ticket.header.onlinePillText')};
  background: ${themeGet('kitchen.colors.ticket.header.onlinePillBackground')};
`
