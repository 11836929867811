import React from 'react';
import { withTheme } from '@emotion/react';

import { ButtonWrapper } from './button-wrapper';
import { ReactComponent as FlagIcon } from '../../../shared/components/icons/flag.svg';
import { ReactComponent as UnFlagIcon } from '../../../shared/components/icons/unflag.svg';

export const Prioritize = ({
  selected,
  isPrioritized,
  prioritize,
}: {
  selected: boolean;
  isPrioritized: boolean;
  prioritize: (event: Event, receiptId: string) => Promise<void>;
}) => {
  return (
    <ButtonWrapper selected={selected} onClick={prioritize}>
      {isPrioritized && (
        <>
          <UnPrioritizeIcon />
          <span>Unprioritise</span>
        </>
      )}
      {!isPrioritized && (
        <>
          <PrioritizeIcon />
          <span>Prioritise</span>
        </>
      )}
    </ButtonWrapper>
  );
};

const PrioritizeIcon = withTheme(
  ({
    theme: {
      kitchen: {
        colors: {
          ticket: { selectedText },
        },
      },
    },
  }: any) => <FlagIcon fill={selectedText} color={selectedText} />,
);

const UnPrioritizeIcon = withTheme(
  ({
    theme: {
      kitchen: {
        colors: {
          ticket: { selectedText },
        },
      },
    },
  }: any) => <UnFlagIcon fill={selectedText} color={selectedText} />,
);
