import React, { useContext, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import {
  AppProvider,
  TablesProvider,
  TicketsProvider,
  TablesContext,
  WebsocketProvider,
  MixpanelProvider,
  FormattersProvider,
  AppContext,
} from './state'
import { AppLoader, BumpPrinting } from './shared/components'
import { GridLayout } from './grid-layout/pages/grid-layout'
import { DynamicLayout } from './dynamic-layout/pages/dynamic-layout'
import { LAYOUTS } from './types/Layouts'
import { KDS_DISPLAY_LAYOUT } from './types/localStorageAliases'
import { ShowAndTell } from './show-and-tell/pages/show-and-tell'

export const KitchenApp = () => {
  return (
    <AppProvider>
      <TablesProvider>
        <InternalApp />
      </TablesProvider>
    </AppProvider>
  )
}

const InternalApp = () => {
  const { tablesLoaded } = useContext(TablesContext)
  const {
    appSettings: { formatOptions },
  } = useContext(AppContext)

  const layout = resolveLayout()
  const [ticketToPrint, setTicketToPrint] = useState<string | null>(null)

  return tablesLoaded ? (
    <FormattersProvider {...formatOptions}>
      <MixpanelProvider>
        <TicketsProvider setTicketToPrint={setTicketToPrint}>
          <WebsocketProvider />
          <Switch>
            <Route exact component={GridLayout} path="/grid" />
            <Route exact component={DynamicLayout} path="/dynamic" />
            <Route exact component={ShowAndTell} path="/ticket/:ticketId" />
            <Redirect to={`/${layout}`} />
          </Switch>
          {ticketToPrint && (
            <BumpPrinting ticketId={ticketToPrint} onPrint={() => setTicketToPrint(null)} />
          )}
        </TicketsProvider>
      </MixpanelProvider>
    </FormattersProvider>
  ) : (
    <AppLoader />
  )
}

const resolveLayout = () => {
  const layout = localStorage.getItem(KDS_DISPLAY_LAYOUT) || LAYOUTS.LAYOUT_GRID
  if (layout === 'grid' || layout === 'dynamic') {
    return layout
  }
  if (layout === LAYOUTS.LAYOUT_DYNAMIC) {
    return 'dynamic'
  }
  return 'grid'
}
