import React, { useState, useRef, useEffect, useContext } from 'react'

import { bumpBarMap, useMoreModalBumpbar } from '../../../hooks/useMoreModalBumpbar'
import { MORE_MODAL_SCREENS } from '../../../types/MoreModal'
import { ACTION_KEYS } from '../../../utils/keyMap'
import { Action, BackAction } from './components/modal-action'
import { ActionDivider } from './components/modal-action-divider'
import { ActionList } from './components/modal-action-list'
import {
  StyledDeviceProfileIcon,
  StyledLayoutIcon,
  StyledCheckmarkIcon,
} from './components/modal-icons'
import { KitchenModal } from './components/modal'
import { MixpanelContext } from '../../../state/mixpanel.provider'
import { MixpanelEvents, MixpanelInteractionType } from '../../../types/mixpanel-events'

type SettingsModalProps = {
  onCloseModal: () => void
  onAction: (type: string) => void
  onBack: (previousModal: string | null) => void
}

const actionParameters: MORE_MODAL_SCREENS[] = [
  MORE_MODAL_SCREENS.OPTIONS,
  MORE_MODAL_SCREENS.DEVICE_PROFILES,
  MORE_MODAL_SCREENS.LAYOUT,
]

export const SettingsModal = ({ onCloseModal, onAction, onBack }: SettingsModalProps) => {
  const [selection, setSelection] = useState(0)
  const { track, getSourceType } = useContext(MixpanelContext)

  const refSelectedElement = useRef(selection)

  useEffect(() => {
    refSelectedElement.current = selection
  })

  const bumpBarMap: bumpBarMap = {
    [ACTION_KEYS.UP]: () => {
      if (refSelectedElement.current > 0) {
        setSelection(refSelectedElement.current - 1)
      }
    },
    [ACTION_KEYS.DOWN]: () => {
      if (refSelectedElement.current < actionParameters.length - 1) {
        setSelection(refSelectedElement.current + 1)
      }
    },
    [ACTION_KEYS.CANCEL]: () => {
      track(MixpanelEvents.MORE_SETTINGS_CLOSE, MixpanelInteractionType.BUMPBAR, getSourceType())
      onCloseModal()
    },
    [ACTION_KEYS.ENTER]: () => {
      if (refSelectedElement.current === 0) {
        track(MixpanelEvents.MORE_SETTINGS_BACK, MixpanelInteractionType.BUMPBAR, getSourceType())
        onBack(actionParameters[refSelectedElement.current])
      } else {
        switch (refSelectedElement.current) {
          case 1:
            track(
              MixpanelEvents.MORE_SETTINGS_DEVICES,
              MixpanelInteractionType.BUMPBAR,
              getSourceType(),
            )
            break
          default:
            track(
              MixpanelEvents.MORE_SETTINGS_LAYOUTS,
              MixpanelInteractionType.BUMPBAR,
              getSourceType(),
            )
        }
        onAction(actionParameters[refSelectedElement.current])
      }
    },
  }

  useMoreModalBumpbar(bumpBarMap)

  return (
    <KitchenModal
      title="Settings"
      onCloseModal={(event: MouseEvent | KeyboardEvent) => {
        if (event && event.type === 'keydown') {
          track(
            MixpanelEvents.MORE_SETTINGS_CLOSE,
            MixpanelInteractionType.BUMPBAR,
            getSourceType(),
          )
        } else {
          track(MixpanelEvents.MORE_SETTINGS_CLOSE, MixpanelInteractionType.TOUCH, getSourceType())
        }
        onCloseModal()
      }}
    >
      <ActionList>
        <BackAction
          selected={selection === 0}
          onClick={() => {
            track(MixpanelEvents.MORE_SETTINGS_BACK, MixpanelInteractionType.TOUCH, getSourceType())
            onBack(MORE_MODAL_SCREENS.OPTIONS)
          }}
          onSelectState={() => setSelection(0)}
        />
        <ActionDivider />
        <Action
          selected={selection === 1}
          text="Device profile"
          onClick={() => {
            track(
              MixpanelEvents.MORE_SETTINGS_DEVICES,
              MixpanelInteractionType.TOUCH,
              getSourceType(),
            )
            onAction(MORE_MODAL_SCREENS.DEVICE_PROFILES)
          }}
          onSelectState={() => setSelection(1)}
        >
          <StyledDeviceProfileIcon selected={selection === 1} />
        </Action>
        <ActionDivider />
        <Action
          selected={selection === 2}
          text="Layout"
          onClick={() => {
            track(
              MixpanelEvents.MORE_SETTINGS_LAYOUTS,
              MixpanelInteractionType.TOUCH,
              getSourceType(),
            )
            onAction(MORE_MODAL_SCREENS.LAYOUT)
          }}
          onSelectState={() => setSelection(2)}
        >
          <StyledLayoutIcon selected={selection === 2} />
        </Action>
        <ActionDivider />
        <Action
          selected={false}
          text={`Ikew: ${process.env.REACT_APP_IKEW_STORE}`}
          noChevron
          onClick={() => {
            const ikew = window.localStorage.getItem('ikew')
            if (!ikew) {
              localStorage.setItem('ikew', 'true')
            } else {
              localStorage.removeItem('ikew')
            }
          }}
          onSelectState={() => {}}
        >
          <StyledCheckmarkIcon selected={!window.localStorage.getItem('ikew')} />
        </Action>
        <ActionDivider />
      </ActionList>
    </KitchenModal>
  )
}
