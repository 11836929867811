import styled from '@emotion/styled';
import { Box } from '@lightspeed/cirrus/Core';
import { themeGet } from '@styled-system/theme-get';

export const ButtonWrapper = styled(Box)<{ selected: boolean }>`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${themeGet('space.2')};
  border-radius: ${themeGet('kitchen.borders.radius.1')};
  padding-left: ${themeGet('space.2')};
  padding-right: ${themeGet('space.2')};
  align-items: center;
  font-size: ${themeGet('kitchen.sizes.header.text')};
  cursor: pointer;
  background: ${({ selected, ...theme }) =>
    selected ? themeGet('kitchen.colors.activeItemColor')(theme) : ''};
  height: ${themeGet('kitchen.sizes.header.contentHeight')};
  line-height: ${themeGet('kitchen.sizes.header.contentHeight')};

  &:active {
    background: ${({ ...theme }) => themeGet('kitchen.colors.activeItemColor')(theme)};
  }
`;
