import React, { FC, useContext } from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { withTheme } from '@emotion/react';

import { Flex, Box } from '@lightspeed/cirrus/Core';
import { IconProps } from '@lightspeed/cirrus/Icon/utils/iconFactory';

import { TicketDetails } from 'kitchen-types';

import { TICKET_STATUS } from '../../../../types/TicketStatuses';
import { IKitchenTheme } from '../../../../theming/themes';
import { ReactComponent as FlagIcon } from '../../../../shared/components/icons/flag.svg';
import { TicketAdditionalInfo } from './ticket-additional-info';

import { getBackground } from '../../../../utils/header-background';
import { useBlinking } from '../../../../shared/hooks';
import { AppContext } from '../../../../state';
import { TimeAndStatus } from './time-and-status';
import { InfoSeparator, TicketLoading, PrimaryInfo } from '../../../../shared/components';
import { useRefreshing } from '../../../../shared/hooks/refreshing-ticket.hook';

export const Header: FC<{
  ticket: TicketDetails;
}> = ({
  ticket: {
    id: ticketId,
    modificationDate,
    kdsStatusId,
    prioritized,
    tableId,
    type,
    orderReference,
    receiptSource,
  },
}) => {
  const {
    appSettings: { blinkingTimeout },
  } = useContext(AppContext);

  const isBlinking = useBlinking(modificationDate, blinkingTimeout);
  const isRefreshing = useRefreshing(ticketId);

  return (
    <TicketHeaderBox status={kdsStatusId} blinking={isBlinking} type={type}>
      <Flex alignItems="center">
        <TimeAndStatus date={modificationDate} status={kdsStatusId} voided={type === 'voided'} />
        {prioritized && kdsStatusId < TICKET_STATUS.ARCHIVED && <StyledPrioritizedIcon />}
      </Flex>
      <Flex alignItems="center">
        <PrimaryInfo
          tableId={`${tableId}`}
          orderReference={receiptSource === 'onlineOrder' ? orderReference : null}
          large
        />
        <InfoSeparator size="space.3" mx={2} />
        <TicketAdditionalInfo ticketId={ticketId} selected={false} />
      </Flex>
      {isRefreshing && <TicketLoading spacing="large" />}
    </TicketHeaderBox>
  );
};

const PrioritizedIconStyled = styled(FlagIcon)<IconProps>``;

const PrioritizedIcon = ({ theme }: { theme: IKitchenTheme }) => (
  <PrioritizedIconStyled
    fill={theme.kitchen.colors.ticket.header.prioritizedIconColor}
    color={theme.kitchen.colors.ticket.header.prioritizedIconColor}
    size={theme.kitchen.sizes.ticket.header.prioritizedIcon}
  />
);
const StyledPrioritizedIcon = withTheme(PrioritizedIcon);

const TicketHeaderBox = styled(Box)<{
  status: TICKET_STATUS;
  type: string;
  blinking: boolean;
}>`
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${themeGet('space.2')};
  padding: ${themeGet('space.3')} ${themeGet('space.2')};
  border-top-left-radius: ${themeGet('kitchen.borders.radius.2')};
  border-top-right-radius: ${themeGet('kitchen.borders.radius.2')};
  color: ${themeGet('kitchen.colors.ticket.header.text')};
  border-bottom: ${themeGet('kitchen.borders.ticket.header.bottom')};
  background: ${props => getBackground(props)};
  animation: ${({ status, blinking, type }) =>
      blinking && status === TICKET_STATUS.NEW && type !== 'voided' && 'blink-new'}
    normal 1.8s infinite linear;
  position: relative;
`;
