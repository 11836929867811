export const margins = {
  emptyMessage: {
    padding: '30px 90px',
    paddingY: '90px',
  },
  ticket: {
    header: {
      clockRight: '7px',
      customersRight: '4px',
      onlinePill: '9px',
      tableName: '8px',
      showAndTell: '12px',
    },
    selected: { left: '6px', tableName: '8px' },
  },
  showntell: {
    name: '10px',
  },
  modal: {
    text: {
      left: '56px',
      right: '56px',
    },
  },
}
