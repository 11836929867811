import React, {
  createContext,
  ReactNode,
  useEffect,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import { FloorTableData, TABLE_TYPE } from '../kitchen-types';
import { getTablesMap } from '../utils/tableResolver';
import { kitchenConnector } from '../api/kitchenConnector';
import { AppContext } from './application.provider';

export type TablesContextType = {
  tables: { [key: string]: FloorTableData };
  tablesLoaded: boolean;
  updateTables: () => void;
  getTableName: (tableId: string | number) => string;
  getTableType: (tableId: string) => TABLE_TYPE;
};

export const TablesContext = createContext<TablesContextType>({
  tables: {},
  tablesLoaded: false,
  updateTables: () => {},
  getTableName: () => '',
  getTableType: () => TABLE_TYPE.DINE_IN,
});

export const TablesProvider = ({ children }: { children: ReactNode }) => {
  const [tables, setTables] = useState<{ [key: string]: FloorTableData }>({});
  const [tablesLoaded, setTablesLoaded] = useState<boolean>(false);

  const { appIsReady } = useContext(AppContext);

  const updateTables = useCallback(async () => {
    const floors = await kitchenConnector.loadFloors();
    setTables(getTablesMap(floors));
    setTablesLoaded(true);
  }, []);

  const getTableName = useCallback(
    (tableId: string | number) => (tables[tableId] ? tables[tableId].name : 'No table'),
    [tables],
  );

  const getTableType = useCallback(
    (tableId: string) => (tables[tableId] ? tables[tableId].type : TABLE_TYPE.DINE_IN),
    [tables],
  );

  useEffect(() => {
    if (appIsReady) {
      updateTables();
    } else {
      setTablesLoaded(false);
    }
  }, [updateTables, appIsReady]);

  return useMemo(
    () => (
      <TablesContext.Provider
        value={{
          tables,
          tablesLoaded,
          updateTables,
          getTableName,
          getTableType,
        }}
      >
        {children}
      </TablesContext.Provider>
    ),
    [children, getTableName, getTableType, tables, tablesLoaded, updateTables],
  );
};
