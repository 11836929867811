import React, { createContext, ReactNode, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// import axios from 'axios';

// import { LoginDataType } from '../types/login';
import { KDSDisabledError, WrongCredentialError } from '../api/errors';
import { kitchenConnector } from '../api/kitchenConnector';

import {
  KDS_ACCOUNT,
  KDS_TOKEN_IDENTIFIER,
  KDS_APP_VERSION,
  KDS_LOGIN_INFO,
} from '../types/localStorageAliases';

export type AuthenticationContextType = {
  isUserAuthenticated: boolean | undefined;
  login: (values: any) => void;
  logout: () => void;
};

export const AuthenticationContext = createContext<AuthenticationContextType>({
  isUserAuthenticated: undefined,
  login: () => {},
  logout: () => {},
});

export const AuthenticationProvider = ({ children }: { children: ReactNode }) => {
  const localToken = localStorage.getItem(KDS_TOKEN_IDENTIFIER);
  const localUser = localStorage.getItem(KDS_ACCOUNT);

  const [isUserAuthenticated, setUserAuthenticated] = useState<boolean | undefined>(
    !!(localToken && localUser),
  );

  const history = useHistory();

  const checkAppVersion = useCallback(() => {
    // const appVersion = localStorage.getItem(KDS_APP_VERSION);
    // axios.get(`${process.env.PUBLIC_URL}/manifest.json?t=${Date.now()}`).then(manifest => {
    //   if (manifest && manifest.data && manifest.data.version) {
    //     if (appVersion !== manifest.data.version) {
    //       localStorage.setItem(KDS_APP_VERSION, `${manifest.data.version}`);
    //       if (appVersion !== null) {
    //         window.location.reload(true);
    //       }
    //     }
    //   }
    // });
    localStorage.setItem(KDS_APP_VERSION, `6.6.6`);
  }, []);

  useEffect(() => {
    checkAppVersion();
    const interval = setInterval(() => checkAppVersion(), 60 * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [checkAppVersion]);

  const login = async (values: any) => {
    try {
      await kitchenConnector.login(values.username, values.password);
      setUserAuthenticated(true);
      return values.username;
    } catch (error) {
      setUserAuthenticated(false);
      if (error instanceof WrongCredentialError || error instanceof KDSDisabledError) {
        return Promise.reject(error);
      }
      if (error.data) {
        return Promise.reject(new Error(error.data.message));
      }
      return Promise.reject(new Error());
    }
    // setUserAuthenticated(true);
    // return 'it@eatsmylife.com'
  };

  const logout = () => {
    localStorage.removeItem(KDS_TOKEN_IDENTIFIER);
    localStorage.removeItem(KDS_ACCOUNT);
    localStorage.removeItem(KDS_LOGIN_INFO);
    setUserAuthenticated(false);
    history.push('/login');
  };

  return (
    <AuthenticationContext.Provider
      value={{
        isUserAuthenticated,
        login,
        logout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
