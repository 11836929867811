import React, { FC } from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled from '@emotion/styled';

export const Modifiers: FC<{ modifiers: string[] }> = ({ modifiers }) => (
  <NoBulletsList data-testid="modifiers">
    {modifiers.map((modifier, index) => {
      const key = `${modifier}-${index}`;
      return <li key={key}>{modifier}</li>;
    })}
  </NoBulletsList>
);

const NoBulletsList = styled('ul')`
  list-style: none;
  margin: 0;
  padding-left: ${themeGet('space.2')};
`;
