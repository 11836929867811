import React, { createContext, ReactNode, useEffect, useState } from 'react';

export type WindowContextType = {
  width: number;
  height: number;
};

export const WindowContext = createContext<WindowContextType>({
  width: window.innerWidth,
  height: window.innerHeight,
});

export const WindowProvider = ({ children }: { children: ReactNode }) => {
  const [windowSize, setWindowSize] = useState<WindowContextType>({ width: 0, height: 0 });

  const handleResize = () =>
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WindowContext.Provider
      value={{
        width: windowSize.width,
        height: windowSize.height,
      }}
    >
      {children}
    </WindowContext.Provider>
  );
};
