import { useEffect, useState } from 'react';

import { getActionFromKey } from '../utils/keyMap';

export type bumpBarMap = {
  [key: string]: (e: KeyboardEvent) => void;
};

export const useMoreModalBumpbar = (mapping: bumpBarMap) => {
  const [config, setConfig] = useState<bumpBarMap>(mapping);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (config[getActionFromKey(event.key)]) {
      event.preventDefault();
      event.stopPropagation();
      return config[getActionFromKey(event.key)](event);
    }
    return null;
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyPress);
    return () => {
      window.removeEventListener('keyup', handleKeyPress);
    };
  }, [config]);

  return { changeConfig: setConfig };
};
