export enum ACTION_KEYS {
  BUMP = 'BUMP',
  UNBUMP = 'UNBUMP',
  PRIORITIZE = 'PRIORITIZE',
  DETAILS = 'DETAILS',
  HISTORY = 'HISTORY',
  MENU = 'MENU',
  ENTER = 'ENTER',
  CANCEL = 'CANCEL',
  NUMBER = 'NUMBER',
  UP = 'UP',
  DOWN = 'DOWN',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

const keys2action: { [key: string]: String[] } = {}

keys2action[ACTION_KEYS.BUMP] = ['b', 'B']
keys2action[ACTION_KEYS.UNBUMP] = ['u', 'U']
keys2action[ACTION_KEYS.PRIORITIZE] = ['p', 'P']
keys2action[ACTION_KEYS.DETAILS] = ['l', 'L', 'z', 'Z']
keys2action[ACTION_KEYS.HISTORY] = ['h', 'H']
keys2action[ACTION_KEYS.MENU] = ['a', 'A', 'm', 'M']
keys2action[ACTION_KEYS.ENTER] = ['Enter']
keys2action[ACTION_KEYS.CANCEL] = ['Escape']
keys2action[ACTION_KEYS.NUMBER] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
keys2action[ACTION_KEYS.UP] = ['t', 'T', 'ArrowUp']
keys2action[ACTION_KEYS.DOWN] = ['d', 'D', 'ArrowDown']
keys2action[ACTION_KEYS.LEFT] = ['r', 'R', 'ArrowLeft']
keys2action[ACTION_KEYS.RIGHT] = ['y', 'Y', 'ArrowRight']

export const getActionFromKey = (key: string): string => {
  const entry = Object.keys(keys2action).find((actionKey: string) => {
    return keys2action[actionKey].includes(key)
  })
  return entry || ''
}
