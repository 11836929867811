import React, { Fragment } from 'react';

import { Button } from '@lightspeed/cirrus/Button';
import { Box } from '@lightspeed/cirrus/Core';
import { TextLink } from '@lightspeed/cirrus/Text';

export type LoginActionsElementProps = {
  showForgotPassword?: boolean;
  loading?: boolean;
};

export const LoginActionsElement = ({ showForgotPassword, loading }: LoginActionsElementProps) => {
  return (
    <Fragment>
      <Box width={1}>
        {showForgotPassword && <TextLink size="small">Forgotten password?</TextLink>}
      </Box>
      <Button
        variant="primary"
        type="submit"
        title="Login"
        data-test-id="login-submit"
        loading={loading}
        fill
      >
        Login
      </Button>
    </Fragment>
  );
};
