import { TicketItem, ticketItemBuilder, ExtendedTicketItem } from './ticketItem';
import { createBuilder } from './typesBuilder';
import { TABLE_TYPE } from './tableTypes';

export type TicketDetails = {
  receiptSource: string;
  waiterName: string;
  numberOfCustomers: number;
  creationDate?: string;
  type: string;
  customerName?: string;
  modificationDate: string;
  tableId: number;
  id: string;
  prioritized: boolean;
  deliveryDate?: string;
  items: TicketItem[];
  receiptId: number;
  kdsStatusId: number;
  status: string;
  navigationId: string;
  orderReference?: string | null;
};

const defaultTicket: TicketDetails = {
  receiptSource: 'inHouse',
  waiterName: 'Ober',
  numberOfCustomers: 0,
  type: 'in house',
  modificationDate: '2019-05-01T05:26:08.000Z',
  tableId: 184806,
  id: '304585\u001c3412038\u001c3412039\u001c3412040',
  navigationId: '304585_3412038_3412039_3412040',
  prioritized: false,
  deliveryDate: '2019-05-01T07:26:08.000Z',
  items: [
    ticketItemBuilder({
      productId: 678987,
    }),
    ticketItemBuilder({
      productId: 1234,
      name: 'Steak',
      kitchenName: 'Steak',
    }),
    ticketItemBuilder({
      productId: 9865,
      name: 'Loempia',
      kitchenName: 'Loempia',
    }),
  ],
  receiptId: 304585,
  kdsStatusId: 0,
  status: 'in progress',
};

export const ticketDetailsBuilder = createBuilder<TicketDetails>(defaultTicket);

export type ExtendedTicketDetails = {
  tableName: string;
  tableType: TABLE_TYPE;
  items: ExtendedTicketItem[];
} & TicketDetails;
