import React from 'react';
import { Flex } from '@lightspeed/cirrus/Core';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { ScrollPosition } from '../../../../types/scroll-position';

import { ReactComponent as IconArrowUp } from '../../icons/arrow_up.svg';
import { ReactComponent as IconArrowDown } from '../../icons/arrow_down.svg';

export const ArrowsContainer = styled(Flex)<{
  scrollPosition: ScrollPosition;
}>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 28px;
  margin-left: 6px;
  margin-top: ${({ scrollPosition }) => {
    if (scrollPosition === ScrollPosition.BOTTOM) {
      return '5px';
    }
    if (scrollPosition === ScrollPosition.TOP) {
      return '-4px';
    }
    return '0';
  }};
  transition: margin-top 0.2s ease-in-out;
`;

const ArrowUpIconStyled = styled(IconArrowUp)<{
  scrollPosition: ScrollPosition;
}>`
  opacity: ${({ scrollPosition }) => (scrollPosition !== ScrollPosition.TOP ? 1 : 0)};
  margin-bottom: 1px;
  transition: opacity 0.2s ease-in-out;
`;

const ArrowUpIcon = ({ position }: any) => <ArrowUpIconStyled scrollPosition={position} />;

export const ArrowUp = withTheme(ArrowUpIcon);

const ArrowDownIconStyled = styled(IconArrowDown)<{
  scrollPosition: ScrollPosition;
}>`
  opacity: ${({ scrollPosition }) => (scrollPosition !== ScrollPosition.BOTTOM ? 1 : 0)};
  margin-top: 1px;
  transition: opacity 0.2s ease-in-out;
`;

const ArrowDownIcon = ({ position }: any) => <ArrowDownIconStyled scrollPosition={position} />;

export const ArrowDown = withTheme(ArrowDownIcon);
