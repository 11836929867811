import React, { MutableRefObject } from 'react';

import { Footer } from '../../../shared/components/footer/footer';
import { Scroll } from './scroll-indicator';

export const DynamicFooter = ({
  kitchenBodyRef,
}: {
  kitchenBodyRef: MutableRefObject<HTMLElement | undefined>;
}) => (
  <Footer data-testid="kitchen-footer">
    <Scroll containerWrapper={kitchenBodyRef} />
  </Footer>
);
