import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

import { Box } from '@lightspeed/cirrus/Core';

export const AppContainer = styled(Box)`
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-rows: ${themeGet('kitchen.sizes.header.height')} auto ${themeGet(
      'kitchen.sizes.footer.height',
    )};
  user-select: none;
  background: ${themeGet('kitchen.colors.background')};
  position: relative;
  @media print {
    display: none;
  }
`;
