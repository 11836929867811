import { withTheme } from '@emotion/react';
import React, { useMemo, FC } from 'react';
import { themeGet } from '@styled-system/theme-get';

import styled from '@emotion/styled';
import { Box, Flex } from '@lightspeed/cirrus/Core';
import { IconHamburger } from '@lightspeed/cirrus/Icon/Hamburger';
import { IconProps } from '@lightspeed/cirrus/Icon/utils/iconFactory';
import { TABLE_TYPES, getReadableTableType } from '../../types/TableTypes';

interface kitchenHeaderMoreAndTitleProps {
  currentProfileName: string;
  currentFilter: TABLE_TYPES;
  onMoreClicked: (event: Event) => void;
  selected?: boolean;
}

export const MoreButton: FC<kitchenHeaderMoreAndTitleProps> = React.memo(
  ({ currentProfileName, currentFilter, onMoreClicked, selected }) => (
    <MoreAndTitleWrapper selected={selected} onClick={onMoreClicked} data-testid="more_button">
      <HamburgerIconWrapper>
        <StyledMoreIcon />
      </HamburgerIconWrapper>
      <Title data-testid="header_title">
        {currentFilter !== TABLE_TYPES.TABLE_TYPE_ALL
          ? `${currentProfileName} - ${getReadableTableType(currentFilter)}`
          : currentProfileName}
      </Title>
    </MoreAndTitleWrapper>
  ),
);

const MoreAndTitleWrapper = styled(Flex)<{ selected?: boolean }>`
  justify-content: left;
  align-items: baseline;
  height: ${themeGet('kitchen.sizes.header.contentHeight')};
  line-height: ${themeGet('kitchen.sizes.header.contentHeight')};
  background: ${({ selected, theme }) =>
    selected ? theme.kitchen.colors.header.itemSelected : 'transparent'};
  border-radius: ${themeGet('kitchen.borders.radius.1')};
  cursor: pointer;

  &:active {
    background: ${themeGet('kitchen.colors.header.itemSelected')};
  }
`;

const HamburgerIconWrapper = styled(Box)`
  color: ${themeGet('kitchen.colors.header.text')};
`;

const MoreIconStyled = styled(IconHamburger)<IconProps>`
  size: ${themeGet('kitchen.sizes.header.moreIcon')};
  margin-left: ${themeGet('space.2')};
`;

const MoreIcon = ({
  theme: {
    kitchen: {
      colors: {
        header: { text: textColor },
      },
    },
  },
}: any) => useMemo(() => <MoreIconStyled color={textColor} />, [textColor]);

const StyledMoreIcon = withTheme(MoreIcon);

const Title = styled(Box)`
  margin-left: ${themeGet('space.2')};
  color: ${themeGet('kitchen.colors.header.text')};
  margin-right: ${themeGet('space.2')};
  font-size: ${themeGet('kitchen.sizes.header.text')};
`;
