import { useBumpTracking } from './bump-tracking.hook';
import { useDeprioritizeTracking } from './deprioritize-tracking.hook';
import { usePrioritizeTracking } from './prioritize-tracking.hook';
import { useMoreCloseTracking } from './more-close-tracking.hook';
import { useMoreOpenTracking } from './more-open-tracking.hook';
import { useNumberPressTracking } from './number-press-tracking.hook';
import { useUnbumpTracking } from './unbump-tracking.hook';
import { useZoomInTracking } from './zoom-in-tracking.hook';
import { useZoomOutTracking } from './zoom-out-tracking.hook';
import { useArrowPressTracking } from './arrow-press-tracking.hook';
import { useQuickFilterTracking } from './quick-filter-tracking.hook';

export const useTracking = () => {
  const trackBump = useBumpTracking();
  const trackDeprioritize = useDeprioritizeTracking();
  const trackPrioritize = usePrioritizeTracking();
  const trackMoreClose = useMoreCloseTracking();
  const trackMoreOpen = useMoreOpenTracking();
  const trackNumberPress = useNumberPressTracking();
  const trackUnbump = useUnbumpTracking();
  const trackZoomIn = useZoomInTracking();
  const trackZoomOut = useZoomOutTracking();
  const trackArrowPress = useArrowPressTracking();
  const trackQuickFilter = useQuickFilterTracking();

  return {
    trackBump,
    trackUnbump,
    trackDeprioritize,
    trackPrioritize,
    trackMoreClose,
    trackMoreOpen,
    trackNumberPress,
    trackZoomIn,
    trackZoomOut,
    trackArrowPress,
    trackQuickFilter,
  };
};
