import { useContext, useCallback } from 'react';
import { MixpanelEvents } from '../../types/mixpanel-events';
import { TICKET_STATUS } from '../../types/TicketStatuses';
import { MixpanelContext } from '../../state/mixpanel.provider';

export const useBumpTracking = () => {
  const { track, getSourceType, getInteractionType } = useContext(MixpanelContext);

  const getEvent = (type: string, kdsStatusId: TICKET_STATUS) => {
    if (type === 'voided') {
      return MixpanelEvents.TICKET_BUMP_VOID_TO_ARCHIVE;
    }
    switch (kdsStatusId) {
      case TICKET_STATUS.NEW:
        return MixpanelEvents.TICKET_BUMP_TO_PREPARING;

      case TICKET_STATUS.PREPARING:
        return MixpanelEvents.TICKET_BUMP_TO_DONE;

      case TICKET_STATUS.DONE:
        return MixpanelEvents.TICKET_BUMP_TO_ARCHIVED;
      default:
        return null;
    }
  };

  const trackBump = useCallback(
    (event: Event, kdsStatusId: number, type: string, receiptId: number) => {
      try {
        const mixpanelEvent: MixpanelEvents | null = getEvent(type, kdsStatusId);
        if (mixpanelEvent) {
          track(mixpanelEvent, getInteractionType(event), getSourceType(), {
            receiptId,
          });
        }
      } catch (error) {
        // silently ignore the error
      }
    },
    [getInteractionType, getSourceType, track],
  );

  return trackBump;
};
