import React from 'react';

import { Footer } from '../../../shared/components/footer/footer';
import { Pagination } from './pagination';

export const GridFooter = () => (
  <Footer>
    <Pagination />
  </Footer>
);
