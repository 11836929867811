import React, { useState, useRef, useEffect, useContext } from 'react';
import { themeGet } from '@styled-system/theme-get';

import styled from '@emotion/styled';
import { Flex } from '@lightspeed/cirrus/Core';

import { bumpBarMap, useMoreModalBumpbar } from '../../../hooks/useMoreModalBumpbar';
import { MORE_MODAL_SCREENS } from '../../../types/MoreModal';
import { ACTION_KEYS } from '../../../utils/keyMap';
import { Action, BackAction } from './components/modal-action';
import { ActionDivider } from './components/modal-action-divider';
import { ActionList } from './components/modal-action-list';
import { KitchenModal } from './components/modal';
import { MixpanelContext } from '../../../state/mixpanel.provider';
import { MixpanelEvents, MixpanelInteractionType } from '../../../types/mixpanel-events';
import { AuthenticationContext } from '../../../state';

type AccountModalProps = {
  account: string;
  kitchenVersion: string;
  isLiteServer: boolean;
  backendVersion: string;
  onCloseModal: () => void;
  onBack: (previousModal: string | null) => void;
};

const actions = ['Back', 'Sign out'];

export const AccountModal = ({
  account,
  kitchenVersion,
  isLiteServer,
  backendVersion,
  onCloseModal,
  onBack,
}: AccountModalProps) => {
  const { logout } = useContext(AuthenticationContext);
  const [selection, setSelection] = useState(0);

  const { track, getSourceType } = useContext(MixpanelContext);

  const refSelectedElement = useRef(selection);

  useEffect(() => {
    refSelectedElement.current = selection;
  });

  const bumpBarMap: bumpBarMap = {
    [ACTION_KEYS.UP]: () => {
      if (refSelectedElement.current > 0) {
        setSelection(refSelectedElement.current - 1);
      }
    },
    [ACTION_KEYS.DOWN]: () => {
      if (refSelectedElement.current < actions.length - 1) {
        setSelection(refSelectedElement.current + 1);
      }
    },
    [ACTION_KEYS.CANCEL]: () => {
      track(MixpanelEvents.MORE_ACCOUNT_CLOSE, MixpanelInteractionType.BUMPBAR, getSourceType());
      onCloseModal();
    },
    [ACTION_KEYS.ENTER]: () => {
      if (actions[refSelectedElement.current] === 'Back') {
        track(MixpanelEvents.MORE_ACCOUNT_BACK, MixpanelInteractionType.BUMPBAR, getSourceType());
        onBack(MORE_MODAL_SCREENS.OPTIONS);
      } else {
        track(
          MixpanelEvents.MORE_ACCOUNT_SIGNOUT,
          MixpanelInteractionType.BUMPBAR,
          getSourceType(),
        );
        logout();
      }
    },
  };

  useMoreModalBumpbar(bumpBarMap);

  return (
    <KitchenModal
      title="Account"
      onCloseModal={(event: MouseEvent | KeyboardEvent) => {
        if (event && event.type === 'keydown') {
          track(
            MixpanelEvents.MORE_ACCOUNT_CLOSE,
            MixpanelInteractionType.BUMPBAR,
            getSourceType(),
          );
        } else {
          track(MixpanelEvents.MORE_ACCOUNT_CLOSE, MixpanelInteractionType.TOUCH, getSourceType());
        }
        onCloseModal();
      }}
    >
      <ActionList>
        <BackAction
          selected={selection === 0}
          onClick={() => {
            track(MixpanelEvents.MORE_ACCOUNT_BACK, MixpanelInteractionType.TOUCH, getSourceType());
            onBack(MORE_MODAL_SCREENS.OPTIONS);
          }}
          onSelectState={() => setSelection(0)}
        />
        <MessageContainer>
          <AccountInformationWrapper>
            <AccountDetail>Account</AccountDetail>
            <AccountValue data-testid="account_modal_account">{account}</AccountValue>
            <AccountDetail>Kitchen</AccountDetail>
            <AccountValue data-testid="account_modal_feversion">{kitchenVersion}</AccountValue>
            <AccountDetail data-testid="account_modal_betype">
              {isLiteServer ? 'Liteserver' : 'Cloud'}
            </AccountDetail>
            <AccountValue data-testid="account_modal_beversion">{backendVersion}</AccountValue>
          </AccountInformationWrapper>
        </MessageContainer>
        <ActionDivider />
        <Action
          selected={selection === 1}
          text="Sign out"
          onClick={() => {
            track(
              MixpanelEvents.MORE_ACCOUNT_SIGNOUT,
              MixpanelInteractionType.TOUCH,
              getSourceType(),
            );
            logout();
          }}
          noChevron
          onSelectState={() => setSelection(1)}
        />
        <ActionDivider />
      </ActionList>
    </KitchenModal>
  );
};

const MessageContainer = styled(Flex)`
  font-size: ${themeGet('kitchen.fonts.sizes.2')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  line-height: ${themeGet('space.4')};
  margin-left: ${themeGet('kitchen.margins.modal.text.left')};
  margin-right: ${themeGet('kitchen.margins.modal.text.right')};
  margin-top: ${themeGet('space.1')};
  margin-bottom: ${themeGet('space.1')};
`;

const AccountInformationWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
`;

const AccountDetail = styled(Flex)`
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${themeGet('kitchen.colors.modal.account.label')};
`;

const AccountValue = styled(AccountDetail)`
  font-weight: bold;
  margin-bottom: ${themeGet('space.4')};
  color: ${themeGet('kitchen.colors.modal.account.value')};
`;
