import styled from '@emotion/styled';
import { Box } from '@lightspeed/cirrus/Core';
import { themeGet } from '@styled-system/theme-get';

export const InfoSeparator = styled(Box)<{ size?: string }>`
  width: ${themeGet('kitchen.sizes.ticket.header.separatorWidth')};
  min-width: ${themeGet('kitchen.sizes.ticket.header.separatorWidth')};
  height: ${({ size }) =>
    size ? themeGet(size) : themeGet('kitchen.sizes.ticket.header.separatorHeight')};
  background: ${themeGet('kitchen.colors.ticket.header.infoSeparator')};
`;
