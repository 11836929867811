import React, { FC, useRef, useLayoutEffect, MutableRefObject } from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

import { Flex, Box } from '@lightspeed/cirrus/Core';

import { TicketDetails, ExtendedTicketItem } from 'kitchen-types';

import { TicketItem } from './ticket-item';
import { useShowAndTellBumpBar } from '../../../show-and-tell.provider';
import { useScrollInfo } from '../../../../hooks';
import { ContinuedIndicator, TicketItemListMoreSpacer } from '../../../../shared/components';
import { kitchenDarkTheme } from '../../../../theming/themes';

export const Body: FC<{
  ticket: { items: ExtendedTicketItem[] } & TicketDetails;
}> = ({ ticket }) => {
  const { selectedElement, setSelectedElement } = useShowAndTellBumpBar();

  const itemListRef = useRef<HTMLElement>();

  const { visible, position } = useScrollInfo(itemListRef);
  const selectedItem: MutableRefObject<HTMLElement | undefined> = useRef();

  useLayoutEffect(() => {
    const selectedDomElement = selectedItem.current;
    const continuedContainerHeight = parseInt(
      kitchenDarkTheme.kitchen.sizes.ticket.continued.spacer.height,
      10,
    );
    if (selectedDomElement && selectedDomElement.parentElement) {
      (selectedDomElement as any).scrollIntoViewIfNeeded(false);
      if (
        selectedDomElement.getBoundingClientRect().bottom + continuedContainerHeight >
        selectedDomElement.parentElement.getBoundingClientRect().bottom
      ) {
        selectedDomElement.parentElement.scrollBy({
          top: continuedContainerHeight,
        });
      }
    }
  }, [selectedElement]);

  return (
    <TicketContainer>
      <TicketItems ref={itemListRef}>
        {ticket.items.map((item, index) => {
          const key = `${ticket.receiptId}-${item.productId}-${index}`;
          return (
            <TicketItem
              key={key}
              quantity={item.amount}
              name={item.kitchenName}
              modifiers={item.modifiers}
              selected={index === selectedElement}
              index={index}
              plu={item.productPlu}
              onClick={() => setSelectedElement(index)}
              ref={index === selectedElement ? selectedItem : null}
            />
          );
        })}
        {visible && <TicketItemListMoreSpacer />}
      </TicketItems>
      {visible && (
        <TicketItemListMoreContainer>
          <ContinuedIndicator selected={false} position={position} />
        </TicketItemListMoreContainer>
      )}
      <ShowAndTellItemDetails ticket={ticket} index={selectedElement} />
    </TicketContainer>
  );
};

const TicketContainer = styled(Box)`
  display: grid;
  grid-template-columns: ${themeGet('kitchen.sizes.ticket.showAndTell.listWrapper')} 1fr;
  background: white;
  border-bottom-left-radius: ${themeGet('kitchen.borders.radius.2')};
  border-bottom-right-radius: ${themeGet('kitchen.borders.radius.2')};
  overflow: hidden;
  position: relative;
`;

const TicketItems = styled(Box)`
  width: ${themeGet('kitchen.sizes.ticket.showAndTell.listWrapper')};
  overflow: auto;
  position: absolute;
  grid-area: 1/1;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TicketItemListMoreContainer = styled(Box)`
  width: ${themeGet('kitchen.sizes.ticket.showAndTell.listWrapper')};
  position: absolute;
  bottom: 0;
  left: 0;
`;

const ShowAndTellItemDetails = ({
  ticket,
  index,
}: {
  ticket: { items: ExtendedTicketItem[] } & TicketDetails;
  index: string | number;
}) => {
  const {
    productImage: imageUrl,
    productPlu: plu,
    name,
    productDescription: description,
  } = ticket.items[typeof index === 'number' ? index : 0];

  return (
    <ShowAndTellItemDetailsWrapper>
      {imageUrl.length > 0 && <ImageViewer data-testid="snt_imageViewer" imageUrl={imageUrl} />}
      <ShowAndTellDescriptionWrapper>
        <ShowAndTellPlu>{plu}</ShowAndTellPlu>
        <ShowAndTellItemName>{name}</ShowAndTellItemName>
        {description.length > 0 && <ShowAndTellItemInfo>{description}</ShowAndTellItemInfo>}
      </ShowAndTellDescriptionWrapper>
    </ShowAndTellItemDetailsWrapper>
  );
};

const ShowAndTellItemDetailsWrapper = styled(Flex)`
  border-left: ${themeGet('kitchen.borders.ticket.item')};
  flex-direction: column;
  flex-grow: 1;
  grid-area: 1/2;
`;

const ImageViewer = styled(Flex)<{ imageUrl: string }>`
  background: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-grow: 1;
`;

const ShowAndTellDescriptionWrapper = styled(Flex)`
  background: ${themeGet('kitchen.colors.showntell.description_background')};
  color: ${themeGet('kitchen.colors.showntell.description_color')};
  flex-direction: column;
  padding: 30px;
`;

const ShowAndTellItemName = styled(Flex)`
  font-size: ${themeGet('kitchen.fonts.size.5')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  height: ${themeGet('kitchen.fonts.size.5')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  line-height: ${themeGet('kitchen.fonts.size.5')};
  margin-bottom: ${themeGet('kitchen.margins.showntell.name')};
`;

const ShowAndTellPlu = styled(Flex)`
  font-size: ${themeGet('space.2')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  height: ${themeGet('space.3')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  line-height: ${themeGet('space.3')};
  margin-bottom: ${themeGet('space.3')};
`;

const ShowAndTellItemInfo = styled(Flex)`
  font-size: ${themeGet('kitchen.fonts.size.1')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  height: ${themeGet('kitchen.fonts.size.5')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  line-height: ${themeGet('kitchen.fonts.size.5')};
  height: min-content;
`;
