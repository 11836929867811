import { useLayoutEffect, MutableRefObject, useState, useContext } from 'react';

import { WindowContext } from '../state';

import { ScrollPosition } from '../types/scroll-position';

export const useScrollInfo = (itemListRef: MutableRefObject<HTMLElement | undefined>) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState<ScrollPosition>(ScrollPosition.TOP);
  const { width, height } = useContext(WindowContext);

  useLayoutEffect(() => {
    if (itemListRef.current) {
      const itemList = itemListRef.current;

      const checkAlignment = () => {
        if (itemList.scrollTop === 0) {
          setPosition(ScrollPosition.TOP);
        } else if (itemList.scrollTop + itemList.clientHeight === itemList.scrollHeight) {
          setPosition(ScrollPosition.BOTTOM);
        } else {
          setPosition(ScrollPosition.MIDDLE);
        }
      };

      setVisible(itemList.clientHeight < itemList.scrollHeight);
      checkAlignment();

      itemList.addEventListener('scroll', checkAlignment);

      return () => {
        itemList.removeEventListener('scroll', checkAlignment);
      };
    }

    return () => {};
  }, [width, height, itemListRef.current]);

  return { visible, position };
};
