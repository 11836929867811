export const sizes = {
  header: {
    height: '72px',
    contentHeight: '48px',
    moreIcon: '16px',
    text: '14px',
    selectionLine: '4px',
  },
  footer: {
    height: '41px',
    padding: '11px',
    scrollbar: {
      radius: '5px',
    },
  },
  ticket: {
    emptyMessage: '14px',
    selected: {
      bumpIcon: '16px',
      statusText: '18px',
      gradientWidth: '18px',
    },
    header: {
      showAndTell: '18px',
      prioritizedIcon: '16px',
      sequenceNumber: '22px',
      tableName: '22px',
      separatorWidth: '1px',
      separatorHeight: '12px',
      clock: '12px',
      customers: '12px',
      showAndTellIconSize: '14px',
    },
    items: {
      showAndTellFontSize: '22px',
      quantityWidth: '24px',
    },
    continued: {
      padding: {
        top: '10px',
        right: '18px',
        bottom: '8px',
        left: '18px',
      },
      spacer: {
        height: '52px',
      },
    },
    showAndTell: {
      listWrapper: '428px',
    },
  },
  modal: {
    width: '468px',
    height: '624px',
    action: {
      height: '60px',
      text: {
        height: '22px',
      },
      icon: {
        size: '16px',
        containerWidth: '56px',
      },
      divider: {
        height: '1px',
      },
    },
  },
  body: {
    emptyMessage: {
      height: '74px',
    },
  },
};
