import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { KDSDisabledError, WrongCredentialError } from '../../api/errors';
import { AuthenticationContext } from '../../state';
import { KDS_LOGIN_INFO } from '../../types/localStorageAliases';
import { PageWrapper, LoginComponent } from '../components';

export const Login = ({ externalError }: { externalError?: string }) => {
  const { login, isUserAuthenticated } = useContext(AuthenticationContext);
  const [error, setError] = useState(externalError);
  const [disabled, setDisabled] = useState(false);
  const [loginInfo, setLoginInfo] = useState<any | undefined>(undefined);

  const history = useHistory();

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        await login(values);
      } catch (error) {
        if (error instanceof KDSDisabledError) {
          setDisabled(true);
        } else if (error instanceof WrongCredentialError) {
          setError(error.errorMessage);
        } else {
          setError(
            error && error.message
              ? error.message
              : 'Oops! Please verify your internet connection and try logging in again.',
          );
        }
      }
    },
    [login],
  );

  useEffect(() => {
    if (isUserAuthenticated) {
      history.push('/');
    }
  }, [isUserAuthenticated, history]);

  useEffect(() => {
    const loginInfo = localStorage.getItem(KDS_LOGIN_INFO);
    if (loginInfo) {
      setLoginInfo(JSON.parse(loginInfo));
      onSubmit(JSON.parse(loginInfo));
    }
  }, [onSubmit]);

  return disabled ? (
    <Redirect to="/disabled" />
  ) : (
    <PageWrapper>
        <LoginComponent
          onSubmit={onSubmit}
          appName="Kitchen Display"
          loginError={error}
          initialValues={loginInfo}
        />
    </PageWrapper>
  );
};
