export enum TABLE_TYPES {
  TABLE_TYPE_ALL = 'all',
  TABLE_TYPE_DELIVERY = 'delivery',
  TABLE_TYPE_DINE_IN = 'dinein',
  TABLE_TYPE_TAKEOUT = 'takeout',
  TABLE_TYPE_TAKEOUT_DELIVERY = 'takeoutdelivery',
}

export const tableTypeDelivery = ['delivery']
export const tableTypeDineIn = ['restaurant', 'tab', 'bar']
export const tableTypeTakeOut = ['takeaway', 'takeaway_simple']
export const tableTypeTakeOutAndDelivery = [...tableTypeDelivery, ...tableTypeTakeOut]

export const getMappedTableType = (value: TABLE_TYPES): string[] => {
  switch (value) {
    case TABLE_TYPES.TABLE_TYPE_DELIVERY:
      return tableTypeDelivery
    case TABLE_TYPES.TABLE_TYPE_DINE_IN:
      return tableTypeDineIn
    case TABLE_TYPES.TABLE_TYPE_TAKEOUT:
      return tableTypeTakeOut
    case TABLE_TYPES.TABLE_TYPE_TAKEOUT_DELIVERY:
      return tableTypeTakeOutAndDelivery
    default:
      return []
  }
}

export const getReadableTableType = (type: TABLE_TYPES): string => {
  switch (type) {
    case TABLE_TYPES.TABLE_TYPE_DELIVERY:
      return 'Delivery only'
    case TABLE_TYPES.TABLE_TYPE_DINE_IN:
      return 'Dine in only'
    case TABLE_TYPES.TABLE_TYPE_TAKEOUT:
      return 'Takeout only'
    case TABLE_TYPES.TABLE_TYPE_TAKEOUT_DELIVERY:
      return 'Takeout & Delivery only'
    default:
      return 'All'
  }
}
