import React, { useContext, useState } from 'react';

import { KitchenProfileData } from 'kitchen-types';

import { AppContext } from '../../../state/application.provider';
import { TicketsContext } from '../../../state/tickets.provider';
import { LAYOUTS } from '../../../types/Layouts';
import { MORE_MODAL_SCREENS } from '../../../types/MoreModal';
import { TABLE_TYPES } from '../../../types/TableTypes';
import { AccountModal } from './account-modal';
import { ClearTicketModal } from './clear-tickets-modal';
import { FilterTicketsModal } from './filter-tickets-modal';
import { LayoutModal } from './layout-modal';
import { KitchenProfileModal } from './profile-modal';
import { OptionModal } from './options-modal';
import { SettingsModal } from './settings-modal';
import { KDS_APP_VERSION, KDS_ACCOUNT } from '../../../types/localStorageAliases';

export interface MoreModalProps {
  onCloseModal: () => void;
}

export const MoreModal = ({ onCloseModal }: MoreModalProps) => {
  const appVersion = localStorage.getItem(KDS_APP_VERSION) || '';
  const username = localStorage.getItem(KDS_ACCOUNT) || '';

  const {
    appSettings: {
      companySettings: { enforceLiteserver },
      backendVersion,
      displayLayout,
      deviceProfiles,
      currentProfile,
      tableFilter,
    },
    changeTableFilter,
    changeSelectedProfile,
    changeSelectedLayout,
  } = useContext(AppContext);

  const { clearTickets } = useContext(TicketsContext);

  const [modalState, setModalState] = useState<string | null>(null);

  const handleBack = (previousModal: string | null) => {
    if (previousModal === null) {
      onCloseModal();
    } else {
      setModalState(previousModal);
    }
  };

  const onClearTickets = () => {
    clearTickets();
    onCloseModal();
  };

  const filterTickets = (filter: TABLE_TYPES) => {
    changeTableFilter(filter);
    onCloseModal();
  };

  const onProfileSelected = (profile: KitchenProfileData) => {
    changeSelectedProfile(profile);
    onCloseModal();
  };

  const onLayoutSwitch = (layout: LAYOUTS) => {
    changeSelectedLayout(layout);
    onCloseModal();
  };

  switch (modalState) {
    case MORE_MODAL_SCREENS.ACCOUNT:
      return (
        <AccountModal
          account={username}
          kitchenVersion={appVersion || ''}
          isLiteServer={enforceLiteserver}
          backendVersion={backendVersion}
          onCloseModal={onCloseModal}
          onBack={handleBack}
          data-testid="account_modal"
        />
      );

    case MORE_MODAL_SCREENS.LAYOUT:
      return (
        <LayoutModal
          activeLayout={displayLayout}
          onCloseModal={onCloseModal}
          onLayoutSwitch={onLayoutSwitch}
          onBack={handleBack}
        />
      );

    case MORE_MODAL_SCREENS.DEVICE_PROFILES:
      return (
        <KitchenProfileModal
          profiles={deviceProfiles}
          activeProfile={currentProfile}
          onCloseModal={onCloseModal}
          onProfileSelected={onProfileSelected}
          onBack={handleBack}
        />
      );
    case MORE_MODAL_SCREENS.SETTINGS:
      return (
        <SettingsModal onCloseModal={onCloseModal} onAction={setModalState} onBack={handleBack} />
      );

    case MORE_MODAL_SCREENS.FILTER_TICKETS:
      return (
        <FilterTicketsModal
          activeFilter={tableFilter}
          onCloseModal={onCloseModal}
          onFilter={filterTickets}
          onBack={handleBack}
        />
      );

    case MORE_MODAL_SCREENS.CLEAR_TICKETS:
      return (
        <ClearTicketModal
          onCloseModal={onCloseModal}
          onAction={onClearTickets}
          onBack={handleBack}
        />
      );

    default:
      return (
        <OptionModal onCloseModal={onCloseModal} onAction={setModalState} onBack={handleBack} />
      );
  }
};
