import React from 'react';
import { withTheme } from '@emotion/react';

import { IconExport } from '@lightspeed/cirrus/Icon/Export';

import { TICKET_STATUS, getStatusAsText } from '../../../types/TicketStatuses';
import { ButtonWrapper } from './button-wrapper';

export const Bump = ({
  selected,
  bump,
  currentStatus,
  voided,
}: {
  selected: boolean;
  bump: (event: Event, id: string) => Promise<void>;
  currentStatus: TICKET_STATUS;
  voided: boolean;
}) => (
  <ButtonWrapper selected={selected} onClick={bump}>
    <BumpIcon />
    <span>
      {voided
        ? getStatusAsText(TICKET_STATUS.ARCHIVED)
        : getStatusAsText(currentStatus + 1, voided)}
    </span>
  </ButtonWrapper>
);

const BumpIcon = withTheme(({ theme: { kitchen: { colors: { header: { text } } } } }: any) => (
  <IconExport color={text} />
));
