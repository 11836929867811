import React, { useState, useRef, useEffect, useContext } from 'react';

import { bumpBarMap, useMoreModalBumpbar } from '../../../hooks/useMoreModalBumpbar';
import { MORE_MODAL_SCREENS } from '../../../types/MoreModal';
import { ACTION_KEYS } from '../../../utils/keyMap';
import { Action, BackAction } from './components/modal-action';
import { ActionDivider } from './components/modal-action-divider';
import { ActionList } from './components/modal-action-list';
import {
  StyledAccountIcon,
  StyledDeleteIcon,
  StyledFilterIcon,
  StyledSettingsIcon,
} from './components/modal-icons';
import { KitchenModal } from './components/modal';
import { MixpanelContext } from '../../../state/mixpanel.provider';
import { MixpanelEvents, MixpanelInteractionType } from '../../../types/mixpanel-events';

type OptionModalProps = {
  onCloseModal: () => void;
  onAction: (type: string) => void;
  onBack: (previousModal: string | null) => void;
};

export const OptionModal = ({ onCloseModal, onAction, onBack }: OptionModalProps) => {
  const [selection, setSelection] = useState(0);
  const { track, getSourceType } = useContext(MixpanelContext);

  const refSelectedElement = useRef(selection);

  useEffect(() => {
    refSelectedElement.current = selection;
  });

  const actionParameters: (MORE_MODAL_SCREENS | null)[] = [
    null,
    MORE_MODAL_SCREENS.CLEAR_TICKETS,
    MORE_MODAL_SCREENS.FILTER_TICKETS,
    MORE_MODAL_SCREENS.SETTINGS,
    MORE_MODAL_SCREENS.ACCOUNT,
  ];

  const bumpBarMap: bumpBarMap = {
    [ACTION_KEYS.UP]: () => {
      if (refSelectedElement.current > 0) {
        setSelection(refSelectedElement.current - 1);
      }
    },
    [ACTION_KEYS.DOWN]: () => {
      setSelection(Math.min(refSelectedElement.current + 1, actionParameters.length - 1));
    },
    [ACTION_KEYS.CANCEL]: () => {
      track(MixpanelEvents.MORE_CLOSE, MixpanelInteractionType.BUMPBAR, getSourceType());
      onCloseModal();
    },
    [ACTION_KEYS.ENTER]: () => {
      if (refSelectedElement.current === 0) {
        track(MixpanelEvents.MORE_CLOSE, MixpanelInteractionType.BUMPBAR, getSourceType());
        onBack(actionParameters[refSelectedElement.current]);
      } else {
        switch (refSelectedElement.current) {
          case 1:
            track(MixpanelEvents.MORE_CLEAR, MixpanelInteractionType.BUMPBAR, getSourceType());
            break;
          case 2:
            track(MixpanelEvents.MORE_FILTER, MixpanelInteractionType.BUMPBAR, getSourceType());
            break;
          case 3:
            track(MixpanelEvents.MORE_SETTINGS, MixpanelInteractionType.BUMPBAR, getSourceType());
            break;
          default:
            track(MixpanelEvents.MORE_ACCOUNT, MixpanelInteractionType.BUMPBAR, getSourceType());
        }
        onAction(actionParameters[refSelectedElement.current] as string);
      }
    },
  };

  useMoreModalBumpbar(bumpBarMap);

  return (
    <KitchenModal
      title="Options"
      onCloseModal={(event: MouseEvent | KeyboardEvent) => {
        if (event && event.type === 'keydown') {
          track(MixpanelEvents.MORE_CLOSE, MixpanelInteractionType.BUMPBAR, getSourceType());
        } else {
          track(MixpanelEvents.MORE_CLOSE, MixpanelInteractionType.TOUCH, getSourceType());
        }
        onCloseModal();
      }}
    >
      <ActionList>
        <BackAction
          selected={selection === 0}
          onClick={() => {
            track(MixpanelEvents.MORE_CLOSE, MixpanelInteractionType.TOUCH, getSourceType());
            onBack(null);
          }}
          onSelectState={() => setSelection(0)}
        />
        <ActionDivider />
        <Action
          selected={selection === 1}
          text="Clear all tickets"
          onClick={() => {
            track(MixpanelEvents.MORE_CLEAR, MixpanelInteractionType.TOUCH, getSourceType());
            onAction(MORE_MODAL_SCREENS.CLEAR_TICKETS);
          }}
          onSelectState={() => setSelection(1)}
        >
          <StyledDeleteIcon selected={selection === 1} />
        </Action>
        <ActionDivider />
        <Action
          selected={selection === 2}
          text="Filter tickets"
          onClick={() => {
            track(MixpanelEvents.MORE_FILTER, MixpanelInteractionType.TOUCH, getSourceType());
            onAction(MORE_MODAL_SCREENS.FILTER_TICKETS);
          }}
          onSelectState={() => setSelection(2)}
        >
          <StyledFilterIcon selected={selection === 2} />
        </Action>
        <ActionDivider />
        <Action
          selected={selection === 3}
          text="Settings"
          onClick={() => {
            track(MixpanelEvents.MORE_SETTINGS, MixpanelInteractionType.TOUCH, getSourceType());
            onAction(MORE_MODAL_SCREENS.SETTINGS);
          }}
          onSelectState={() => setSelection(3)}
        >
          <StyledSettingsIcon selected={selection === 3} />
        </Action>
        <ActionDivider />
        <Action
          selected={selection === 4}
          text="Account"
          onClick={() => {
            track(MixpanelEvents.MORE_ACCOUNT, MixpanelInteractionType.TOUCH, getSourceType());
            onAction(MORE_MODAL_SCREENS.ACCOUNT);
          }}
          onSelectState={() => setSelection(4)}
        >
          <StyledAccountIcon selected={selection === 4} />
        </Action>
        <ActionDivider />
      </ActionList>
    </KitchenModal>
  );
};
