import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { TicketsContext } from '../state';
import { TICKET_STATUS } from '../types/TicketStatuses';
import { getActionFromKey, ACTION_KEYS } from '../utils/keyMap';
import { HEADER_BUTTONS } from '../shared/components/header/header-buttons';
import { useTracking } from './mixpanel/tracking.hook';

export const useDynamicBumpBar = (disabled: boolean, onMoreClicked: () => void) => {
  const {
    state: {
      tickets,
      selectedTicket,
      selectedButton,
      pagination: { selectedPage, numberOfPages },
    },
    selectTicket,
    selectButton,
    filterTickets,
    bump,
    unbump,
    nextPage,
    previousPage,
    prioritise,
  } = useContext(TicketsContext);
  const history = useHistory();
  const { trackArrowPress, trackMoreOpen, trackZoomIn, trackNumberPress } = useTracking();

  useEffect(() => {
    const handleKeypress = (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const key = getActionFromKey(event.key);
      if (key === ACTION_KEYS.MENU) {
        trackMoreOpen(event);
        onMoreClicked();
        return;
      }
      if (key === ACTION_KEYS.HISTORY) {
        filterTickets(event, TICKET_STATUS.ARCHIVED);
        return;
      }
      if (key === ACTION_KEYS.NUMBER) {
        const desiredIndex = Number.parseInt(event.key, 10) - 1;
        if (tickets && tickets[desiredIndex]) {
          trackNumberPress(desiredIndex);
          selectTicket(tickets[desiredIndex].id);
        }
        return;
      }
      if (selectedTicket) {
        switch (key) {
          case ACTION_KEYS.RIGHT: {
            const newTicket = tickets[tickets.findIndex(({ id }) => id === selectedTicket) + 1];
            if (newTicket) {
              selectTicket(newTicket.id);
            }
            trackArrowPress('Right');
            break;
          }

          case ACTION_KEYS.LEFT: {
            const newTicket = tickets[tickets.findIndex(({ id }) => id === selectedTicket) - 1];
            if (newTicket) {
              selectTicket(newTicket.id);
            }
            trackArrowPress('Left');
            break;
          }

          case ACTION_KEYS.DOWN: {
            if (selectedButton !== null && tickets[0]) {
              selectTicket(tickets[0].id);
            }
            trackArrowPress('Down');
            break;
          }

          case ACTION_KEYS.UP: {
            selectButton('more');
            trackArrowPress('Up');
            break;
          }

          case ACTION_KEYS.BUMP: {
            const ticket = tickets.find(t => t.id === selectedTicket);
            if (ticket) {
              bump(event, ticket.id, ticket.kdsStatusId, ticket.type, ticket.receiptId);
            }
            break;
          }

          case ACTION_KEYS.UNBUMP: {
            const ticket = tickets.find(t => t.id === selectedTicket);
            if (ticket) {
              unbump(event, ticket.id, ticket.kdsStatusId, ticket.receiptId);
            }
            break;
          }

          case ACTION_KEYS.PRIORITIZE: {
            prioritise(event, selectedTicket);
            break;
          }

          case ACTION_KEYS.DETAILS:
          case ACTION_KEYS.ENTER: {
            const ticket = tickets.find(({ id }) => id === selectedTicket);
            if (ticket) {
              trackZoomIn(event, ticket.receiptId);
              history.push(`/ticket/${ticket.id}`);
            }
            break;
          }

          case ACTION_KEYS.CANCEL: {
            console.log('canceling');
            break;
          }

          default:
        }
      } else if (selectedButton) {
        const currentIndex = HEADER_BUTTONS.findIndex(button => button.id === selectedButton);
        switch (key) {
          case ACTION_KEYS.RIGHT: {
            if (currentIndex + 1 < HEADER_BUTTONS.length) {
              selectButton(HEADER_BUTTONS[(currentIndex + 1) % HEADER_BUTTONS.length].id);
            }
            trackArrowPress('Right');
            break;
          }
          case ACTION_KEYS.LEFT: {
            if (currentIndex > 0) {
              selectButton(
                HEADER_BUTTONS[(currentIndex - 1 + HEADER_BUTTONS.length) % HEADER_BUTTONS.length]
                  .id,
              );
            }
            trackArrowPress('Left');
            break;
          }
          case ACTION_KEYS.DOWN: {
            if (tickets[0]) {
              selectTicket(tickets[0].id);
            }
            trackArrowPress('Down');
            break;
          }
          case ACTION_KEYS.ENTER: {
            if (selectedButton === 'more') {
              trackMoreOpen(event);
              onMoreClicked();
            } else {
              const { status } = HEADER_BUTTONS[currentIndex];
              status !== undefined && filterTickets(event, status);
            }

            break;
          }
          default:
        }
      }
    };
    if (!disabled) {
      window.addEventListener('keyup', handleKeypress);
    }
    return () => window.removeEventListener('keyup', handleKeypress);
  }, [
    tickets,
    selectTicket,
    selectedTicket,
    selectButton,
    selectedButton,
    filterTickets,
    bump,
    unbump,
    selectedPage,
    numberOfPages,
    nextPage,
    previousPage,
    prioritise,
    history,
    disabled,
    onMoreClicked,
    trackMoreOpen,
    trackArrowPress,
    trackZoomIn,
  ]);
};
