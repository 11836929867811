import { useContext, useEffect } from 'react';
import { TicketsContext } from '../state';

export const useTicketClickHandler = () => {
  const { bump } = useContext(TicketsContext);

  const clicks: number[] = [];
  let clickTimeout: NodeJS.Timer;

  const clickHandler = (
    event: Event,
    ticketId: string,
    kdsStatusId: number,
    type: string,
    receiptId: number,
    selectTicket?: (id: string) => void,
  ) => {
    clicks.push(new Date().getTime());
    clickTimeout && clearTimeout(clickTimeout);
    clickTimeout = setTimeout(() => {
      if (clicks.length > 1 && clicks[clicks.length - 1] - clicks[clicks.length - 2] < 250) {
        selectTicket && selectTicket(ticketId);
        bump(event, ticketId, kdsStatusId, type, receiptId);
      } else {
        selectTicket && selectTicket(ticketId);
      }
    }, 250);
  };

  useEffect(() => {
    return () => clickTimeout && clearTimeout(clickTimeout);
  }, []);

  return clickHandler;
};
