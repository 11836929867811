import { createBuilder } from './typesBuilder';

export type PaginationInfo = {
  page: number;
  size: number;
  totalPages: number;
  itemsInPage: number;
  totalItems: number;
  totals?: {
    map: {
      [key: number]: number;
    };
  };
};

const defaultPaginationInfo: PaginationInfo = {
  page: 0,
  size: 50,
  totalPages: 1,
  itemsInPage: 1,
  totalItems: 1,
  totals: {
    map: {
      0: 0,
    },
  },
};

export const paginationInfoBuilder = createBuilder<PaginationInfo>(defaultPaginationInfo);
