import { sizes } from './sizes';
import { shadows } from './shadows';
import { fonts } from './fonts';
import { borders } from './borders';
import { margins } from './margins';

export const baseTheme = {
  sizes,
  shadows,
  fonts,
  borders,
  margins,
};
