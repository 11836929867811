import moment from 'moment'

export const getFormattedTime = (ticketDate: string) => {
  const { hours, minutes, seconds } = getDifference(ticketDate)
  let formattedMinutes = minutes
  let formattedSeconds = seconds
  let roundedSeconds = Math.round(seconds / 10) * 10
  if (roundedSeconds === 60) {
    formattedMinutes = minutes + 1
    formattedSeconds = 0
    roundedSeconds = 0
  }

  if (hours > 0) {
    return `${hours}h ${formattedMinutes > 9 ? formattedMinutes : `0${formattedMinutes}`}m`
  }
  if (formattedMinutes > 0) {
    return `${formattedMinutes}m ${roundedSeconds > 0 ? roundedSeconds : '00'}s`
  }
  return `${formattedSeconds}s`
}

const getDifference = (ticketDate: string) => {
  const difference = moment.duration(moment().diff(moment(ticketDate)))
  return {
    hours: difference.get('hours'),
    minutes: difference.get('minutes'),
    seconds: difference.get('second'),
  }
}
