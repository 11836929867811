import React, { FC } from 'react';
import { Box, Flex } from '@lightspeed/cirrus/Core';
import styled from '@emotion/styled';
import { WidthProps } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { ScrollPosition } from '../../../../types/scroll-position';
import { ArrowsContainer, ArrowDown, ArrowUp } from './arrows';

export const ContinuedIndicatorTransparent: FC<
  {
    position?: ScrollPosition;
    text?: string;
    onClick?: () => void;
  } & WidthProps
> = ({ position, text, onClick, ...rest }) => (
  <IndicatorContainer {...rest}>
    <Indicator onClick={onClick}>
      <TextContainer>{text || 'Continued'}</TextContainer>
      {position && (
        <ArrowsContainer scrollPosition={position}>
          <ArrowUp position={position} />
          <ArrowDown position={position} />
        </ArrowsContainer>
      )}
    </Indicator>
  </IndicatorContainer>
);

const TextContainer = styled(Box)`
  color: rgb(255, 255, 255);
  font-family: 'Lato';
  font-size: 14px;
  font-weight: 600;
  height: 28px;
  letter-spacing: 0px;
  line-height: 28px;
`;

const Indicator = styled(Flex)`
  width: 120px;
  height: 28px;
  background: ${({ ...theme }) => themeGet('kitchen.colors.background')(theme)};
  opacity: 0.6;
  border-radius: 4px;
  justify-content: center;
`;

const IndicatorContainer = styled(Flex)<WidthProps>`
  position: absolute;
  bottom: 0;
  width: 100%;
  min-width: 100%;
  background: transparent;
  justify-content: center;
  margin-bottom: 12px;
`;
