import React, { ReactNode, createContext } from 'react';
import { useShortTimeFormat } from '../hooks/useShortTimeFormat';
import { useShortDateFormat } from '../hooks/useShortDateFormat';

export type FormattersContextType = {
  locale: string;
  timeFormatter: Intl.DateTimeFormat;
  dateFormatter: Intl.DateTimeFormat;
};

export const FormattersContext = createContext<FormattersContextType>({
  locale: 'en-US',
  timeFormatter: new Intl.DateTimeFormat(),
  dateFormatter: new Intl.DateTimeFormat(),
});

export const FormattersProvider = ({
  timezone,
  locale,
  children,
}: {
  timezone: string;
  locale: string;
  children: ReactNode;
}) => {
  return (
    <FormattersContext.Provider
      value={{
        locale,
        timeFormatter: useShortTimeFormat(timezone, locale),
        dateFormatter: useShortDateFormat(timezone, locale),
      }}
    >
      {children}
    </FormattersContext.Provider>
  );
};
