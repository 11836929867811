import React, { useContext, useMemo, FC } from 'react';

import { Box, Flex } from '@lightspeed/cirrus/Core';
import { PillBadge } from '@lightspeed/cirrus/Badge';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

import { TicketsContext } from '../../../state';
import { TICKET_STATUS } from '../../../types/TicketStatuses';
import { MixpanelEvents } from '../../../types/mixpanel-events';

export const HeaderFilters: FC<{}> = () => {
  const {
    state: { stateFilter, ticketsCounts, selectedButton },
    filterTickets,
  } = useContext(TicketsContext);

  return useMemo(
    () => (
      <Flex data-testid="state_filtering_header">
        {states.map(({ text, filter, saveStatus }) => (
          <Box key={filter}>
            <HeaderOption
              data-testid={`state_filter_${filter}`}
              onClick={() => {
                filterTickets(new Event('click'), saveStatus);
              }}
              highlighted={selectedButton === filter}
            >
              <OptionWithText
                text={text}
                amount={ticketsCounts[filter]}
                data-testid={`state_filter_${filter}_text`}
              />
              {selectedButton !== filter && stateFilter === saveStatus && (
                <OptionSelected data-testid={`state_filter_${filter}_selected`} />
              )}
            </HeaderOption>
          </Box>
        ))}
      </Flex>
    ),
    [filterTickets, selectedButton, stateFilter, ticketsCounts],
  );
};

const HeaderOption = styled.button<{ highlighted: boolean }>`
  flex-direction: column;
  align-items: center;
  height: ${themeGet('kitchen.sizes.header.contentHeight')};
  font-family: ${themeGet('fontFamily.sans-serif')};
  padding-left: ${themeGet('space.2')};
  padding-right: ${themeGet('space.2')};
  margin-left: ${themeGet('space.4')};
  outline: 0px;
  cursor: pointer;
  background: ${({ highlighted, ...theme }) =>
    highlighted ? themeGet('kitchen.colors.header.itemSelected')(theme) : 'transparent'};
  color: ${themeGet('kitchen.colors.header.text')};
  border: 0;
  border-radius: ${themeGet('kitchen.borders.radius.1')};
`;

const Option = styled(Flex)`
  align-items: center;
  line-height: ${themeGet('kitchen.sizes.header.contentHeight')};
  font-size: ${themeGet('kitchen.sizes.header.text')};
  height: calc(${themeGet('kitchen.sizes.header.contentHeight')} - 4px);
`;

const OptionSelected = styled(Box)`
  width: 100%;
  height: ${themeGet('kitchen.sizes.header.selectionLine')};
  line-height: ${themeGet('kitchen.sizes.header.contentHeight')};
  font-size: ${themeGet('kitchen.sizes.header.text')};
  border: ${themeGet('kitchen.borders.header.selectedItem')};
  border-radius: ${themeGet('kitchen.borders.radius.1')};
`;

const OptionWithText = ({ text, amount }: { text: string; amount: number }) => {
  return (
    <Option>
      <span>{text}</span>
      {amount > -1 && (
        <Box ml={2}>
          <PillBadge bg="#2f3137" color="white" ml={2}>
            {amount > 99 ? '99+' : amount}
          </PillBadge>
        </Box>
      )}
    </Option>
  );
};

const states: {
  text: string;
  filter: any;
  mixPanelEvent: MixpanelEvents;
  saveStatus: TICKET_STATUS;
}[] = [
  {
    text: 'All active',
    filter: 'all',
    mixPanelEvent: MixpanelEvents.FILTER_ALL,
    saveStatus: TICKET_STATUS.ALL,
  },
  {
    text: 'New',
    filter: 'new',
    mixPanelEvent: MixpanelEvents.FILTER_NEW,
    saveStatus: TICKET_STATUS.NEW,
  },
  {
    text: 'Preparing',
    filter: 'preparing',
    mixPanelEvent: MixpanelEvents.FILTER_PREPARING,
    saveStatus: TICKET_STATUS.PREPARING,
  },
  {
    text: 'Done',
    filter: 'done',
    mixPanelEvent: MixpanelEvents.FILTER_DONE,
    saveStatus: TICKET_STATUS.DONE,
  },
  {
    text: 'Archived',
    filter: 'archived',
    mixPanelEvent: MixpanelEvents.FILTER_ARCHIVED,
    saveStatus: TICKET_STATUS.ARCHIVED,
  },
];
