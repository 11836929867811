import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from '@lightspeed/cirrus/Core'

import { SelectedHeader } from './selected-header'
import { NormalHeader } from './normal-header'
import { TICKET_STATUS } from '../../../../types/TicketStatuses'
import { IKitchenTheme } from '../../../../theming/themes'
import { useBlinking } from '../../../hooks/app-loader'
import { AppContext, TicketsContext } from '../../../../state'
import { getBackground } from '../../../../utils/header-background'
import { useRefreshing } from '../../../hooks/refreshing-ticket.hook'
import { useSelection } from '../../../../hooks/tickets/ticket-selection.hook'
import { isLowerTanIOS13 } from '../../../../utils/is-lower-tan-ios-13'
import { TicketLoading } from '../body/ticket-loading'
import { useTicketClickHandler } from '../../../../hooks'

export const Header: FC<{
  selected: boolean
  number: number
  ticketId: string
  modificationDate: string
  navigationId: string
  kdsStatusId: number
  type: string
  receiptId: number
  orderReference?: string | null
}> = ({ number, ticketId, modificationDate, kdsStatusId, type, receiptId, orderReference }) => {
  const {
    appSettings: { blinkingTimeout },
  } = useContext(AppContext)
  const isBlinking = useBlinking(modificationDate, blinkingTimeout)
  const isRefreshing = useRefreshing(ticketId)
  const { selected, selectTicket } = useSelection(ticketId)
  const clickHandler = useTicketClickHandler()
  const { bump } = useContext(TicketsContext)

  const onClick = (event: Event) => {
    event.preventDefault()
    event.stopPropagation()
    if (isLowerTanIOS13) {
      clickHandler(event, ticketId, kdsStatusId, type, receiptId, selectTicket)
    } else {
      selectTicket(ticketId)
    }
  }
  const onDoubleClick = (event: Event) => {
    event.preventDefault()
    event.stopPropagation()
    bump(event, ticketId, kdsStatusId, type, receiptId)
  }

  return (
    <TicketHeaderBox
      type={type}
      status={kdsStatusId}
      blinking={isBlinking}
      selected={selected}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {selected ? (
        <SelectedHeader
          ticketId={ticketId}
          number={number}
          type={type}
          kdsStatusId={kdsStatusId}
          receiptId={receiptId}
          orderReference={orderReference}
        />
      ) : (
        <NormalHeader ticketId={ticketId} number={number} />
      )}
      {isRefreshing && <TicketLoading selected={selected} />}
    </TicketHeaderBox>
  )
}

const TicketHeaderBox = styled(Flex)<{
  status: TICKET_STATUS | null
  type: string
  selected: boolean
  blinking?: boolean
}>`
  height: auto;
  flex-direction: column;
  width: 100%;
  color: ${themeGet('kitchen.colors.ticket.header.text')};
  border-bottom: ${themeGet('kitchen.borders.ticket.header.bottom')};
  border-radius: ${(props) => getBorderRadius(props)};
  background: ${(props) => getBackground(props)};
  animation: ${({ blinking, status, type }) =>
      blinking && status === TICKET_STATUS.NEW && type !== 'voided' ? 'blink-new' : ''}
    normal 1.8s infinite linear;
  flex-shrink: 0;
  position: relative;
`

const getBorderRadius = ({ selected, ...theme }: { selected: boolean; theme: IKitchenTheme }) =>
  selected
    ? 0
    : `${themeGet('kitchen.borders.radius.2')(theme)} ${themeGet('kitchen.borders.radius.2')(
        theme,
      )} ${themeGet('kitchen.borders.radius.0')(theme)} ${themeGet('kitchen.borders.radius.0')(
        theme,
      )}`
