import { RowsColumns } from './kitchenSettings';
import { createBuilder } from './typesBuilder';

export type KitchenProfileBase = {
  defaultProfile: boolean;
  name: string;
  oid?: number;
};

export type KitchenProfileData = KitchenProfileBase & {
  settings: { [key: string]: string | number | boolean | any[] | RowsColumns | null };
};

const defaultKitchenProfileData: KitchenProfileData = {
  name: 'test',
  defaultProfile: true,
  oid: 1946,
  settings: {},
};

export const kitchenProfileDataBuilder = createBuilder<KitchenProfileData>(
  defaultKitchenProfileData,
);

export interface KitchenSetting {
  settingKey: string;
  settingValue: string | number;
}

export interface KitchenProfile {
  deviceProfile: KitchenProfileBase;
  settings: KitchenSetting[];
}
