import React, { FC } from 'react';
import { themeGet } from '@styled-system/theme-get';

import styled from '@emotion/styled';
import { Flex } from '@lightspeed/cirrus/Core';
import { Spinner } from '@lightspeed/cirrus/Spinner';

type appLoaderProps = {
  width?: number | string;
  height?: number | string;
  ticketsOnly?: boolean;
};

export const AppLoader: FC<appLoaderProps> = ({ width, height, ticketsOnly }) => (
  <LoaderContainer width={width} height={height} ticketsOnly={ticketsOnly} data-testid="loading">
    <Spinner size="xxlarge" color="white" />
  </LoaderContainer>
);

const LoaderContainer = styled(Flex)<appLoaderProps>`
  height: ${({ height, ticketsOnly, theme }) => {
    return height || ticketsOnly
      ? `calc(100vh - ${theme.kitchen.sizes.header.height} - ${theme.kitchen.sizes.footer.height})`
      : '100vh';
  }};

  width: ${({ width }) => width || '100vw'};
  background: ${themeGet('kitchen.colors.background')};
  justify-content: center;
  align-items: center;
`;
