import { TicketDetails } from 'kitchen-types'
import { TotalsIndexEntry } from 'types/TotalsIndex'
import { TICKET_STATUS } from '../types/TicketStatuses'

export type loadTickets = {
  type: 'LOAD_TICKETS'
  payload: {
    tickets: TicketDetails[]
    numberOfPages: number
    totals: {
      [key: string]: number
    }
    selectedTicketIndex?: number
    originatingTicket?: string
    keepSelection?: boolean
    totalsIndex: { [key: string]: TotalsIndexEntry }
  }
}

export const loadTicketsAction = (payload: {
  tickets: TicketDetails[]
  numberOfPages: number
  totals: {
    [key: string]: number
  }
  selectedTicketIndex?: number
  originatingTicket?: string
  keepSelection?: boolean
  totalsIndex: { [key: string]: TotalsIndexEntry }
}): KitchenAction => ({
  type: 'LOAD_TICKETS',
  payload,
})

export type bumpTicket = {
  type: 'BUMP_TICKET'
  payload: { id: string }
}

export const bumpTicketAction = (payload: { id: string }): KitchenAction => ({
  type: 'BUMP_TICKET',
  payload,
})

export type unbumpTicket = {
  type: 'UNBUMP_TICKET'
  payload: { id: string }
}

export const unbumpTicketAction = (payload: { id: string }): KitchenAction => ({
  type: 'UNBUMP_TICKET',
  payload,
})

export type selectTicket = {
  type: 'SELECT_TICKET'
  payload: { id: string }
}

export const selectTicketAction = (payload: { id: string }): KitchenAction => ({
  type: 'SELECT_TICKET',
  payload,
})

export type selectButton = {
  type: 'SELECT_BUTTON'
  payload: { id: string }
}

export const selectButtonAction = (payload: { id: string }): KitchenAction => ({
  type: 'SELECT_BUTTON',
  payload,
})

export type filterTickets = {
  type: 'FILTER_TICKETS'
  payload: { status: TICKET_STATUS }
}

export const filterTicketsAction = (payload: { status: TICKET_STATUS }): KitchenAction => ({
  type: 'FILTER_TICKETS',
  payload,
})

export type selectPage = {
  type: 'SELECT_PAGE'
  payload: { page: number }
}

export const selectPageAction = (payload: { page: number }): KitchenAction => ({
  type: 'SELECT_PAGE',
  payload,
})

export type ticketRefreshed = {
  type: 'TICKET_REFRESHED'
  payload: { id: string }
}

export const ticketRefreshedAction = (payload: { id: string }): KitchenAction => ({
  type: 'TICKET_REFRESHED',
  payload,
})

export type receiptRefreshed = {
  type: 'RECEIPT_REFRESHED'
  payload: { id: number }
}

export const receiptRefreshedAction = (payload: { id: number }): KitchenAction => ({
  type: 'RECEIPT_REFRESHED',
  payload,
})

export type prioritiseTicket = {
  type: 'PRIORITISE_TICKET'
  payload: { receiptId: number }
}

export const prioritiseTicketAction = (payload: { receiptId: number }): KitchenAction => ({
  type: 'PRIORITISE_TICKET',
  payload,
})

export type deprioritiseTicket = {
  type: 'DEPRIORITISE_TICKET'
  payload: { receiptId: number }
}

export const deprioritiseTicketAction = (payload: { receiptId: number }): KitchenAction => ({
  type: 'DEPRIORITISE_TICKET',
  payload,
})

// export type setTicketToPrint = {
//   type: 'SET_TICKET_TO_PRINT';
//   payload: { id: string };
// };

// export const setTicketToPrintAction = (payload: { id: string }): KitchenAction => ({
//   type: 'SET_TICKET_TO_PRINT',
//   payload,
// });
export type toggleItem = {
  type: 'TOGGLE_ITEM'
}

export const toggleItemAction = (): KitchenAction => ({
  type: 'TOGGLE_ITEM',
})

export type updateClearedItems = {
  type: 'UPDATE_CLEARED_ITEMS'
  payload: {
    clearedIndex: { [key: string]: number }
  }
}

export const updateClearedItemsAction = (payload: {
  clearedIndex: { [key: string]: number }
}): KitchenAction => ({
  type: 'UPDATE_CLEARED_ITEMS',
  payload,
})

export type updateModifierTotals = {
  type: 'UPDATE_MODIFIER_TOTALS'
  payload: { totalsIndex: { [key: string]: TotalsIndexEntry } }
}

export const updateModifierTotalsAction = (payload: {
  totalsIndex: { [key: string]: TotalsIndexEntry }
}): KitchenAction => ({
  type: 'UPDATE_MODIFIER_TOTALS',
  payload,
})

export type KitchenAction =
  | loadTickets
  | bumpTicket
  | unbumpTicket
  | selectTicket
  | filterTickets
  | selectButton
  | selectPage
  | ticketRefreshed
  | receiptRefreshed
  | prioritiseTicket
  | deprioritiseTicket
  | toggleItem
  | updateClearedItems
  | updateModifierTotals
// | setTicketToPrint;
