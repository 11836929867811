import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { themeGet } from '@styled-system/theme-get'

import { Box } from '@lightspeed/cirrus/Core'

import { useTicketDetails } from '../../../hooks'
import { DynamicTicketItem } from './dynamic-ticket-item'
import { TicketsContext } from '../../../state'

// import debug from 'debug'
// const log = debug('@kds')

export const DynamicTicketBody: FC<{ selected: boolean; ticketId: string }> = ({
  selected,
  ticketId,
}) => {
  const { receiptId, items, kdsStatusId } = useTicketDetails(ticketId)

  const {
    state: { totalsIndex },
  } = useContext(TicketsContext)

  return (
    <TicketBody selected={selected}>
      {items.map((item, index, tickets) => {
        const key = `${receiptId}-${item.amount}-${item.productId}-${index}`
        const entry = totalsIndex[item.productId.toString()]
        const color = !!entry && entry.quantity > 1 ? entry.color : null
        return (
          <DynamicTicketItem
            key={key}
            ticketId={ticketId}
            quantity={item.amount}
            name={item.kitchenName}
            color={color}
            modifiers={item.modifiers}
            selected={selected}
            index={index}
            last={index + 1 === tickets.length}
            item={item}
            kdsStatusId={kdsStatusId}
          />
        )
      })}
    </TicketBody>
  )
}

const TicketBody = styled(Box)<{
  selected: boolean
}>`
  background: ${({ ...props }) => themeGet('kitchen.colors.ticket.body.background')(props)};
  overflow: 'visible';
  border-bottom-left-radius: ${({ selected, ...theme }) =>
    selected ? 0 : themeGet('radii.radius-2')(theme)};
  border-bottom-right-radius: ${({ selected, ...theme }) =>
    selected ? 0 : themeGet('radii.radius-2')(theme)};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`
