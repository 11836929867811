import { ProductData, productsDataBuilder } from './products';
import { createBuilder } from './typesBuilder';

export type ProductCategoryData = {
  name: string;
  oid: number;
  products: ProductData[];
};

const defaultProductCategoryData: ProductCategoryData = {
  oid: 52255,
  products: [
    productsDataBuilder(),
    productsDataBuilder({
      kitchenName: 'Campari',
      name: 'Campari',
      oid: 138218,
    }),
  ],
  name: 'Aperitif',
};

export const productCategoryDataBuilder = createBuilder<ProductCategoryData>(
  defaultProductCategoryData,
);
