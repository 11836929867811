import React, {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useCallback,
  useContext,
} from 'react';
import { useHistory } from 'react-router-dom';

import {
  CompanySettingsData,
  KitchenProfileData,
  ProductData,
  RowsColumns,
} from 'kitchen-types';

import { kitchenConnector } from '../api/kitchenConnector';
import {
  KDS_DISPLAY_LAYOUT,
  KDS_STATE_FILTER,
  KDS_TABLE_FILTER,
  KDS_PROFILE_IDENTIFIER,
} from '../types/localStorageAliases';

import { LAYOUTS } from '../types/Layouts';
import { TICKET_STATUS } from '../types/TicketStatuses';
import { TABLE_TYPES } from '../types/TableTypes';
import { getProducts } from '../utils/productResolver';
import { KDSDisabledError, KDSRedirect } from '../api';
import { AuthenticationContext } from './authentication.provider';

type KitchenAppSettingsType = {
  companySettings: CompanySettingsData;
  deviceProfiles: KitchenProfileData[];
  currentProfile: KitchenProfileData;
  products: { [key: string]: ProductData };
  displayLayout: LAYOUTS;
  statusFilter: TICKET_STATUS;
  tableFilter: TABLE_TYPES;
  backendVersion: string;
  blinkingTimeout: number;
  formatOptions: { timezone: string; locale: string };
};

const defaultSettings: KitchenAppSettingsType = {
  companySettings: {} as CompanySettingsData,
  deviceProfiles: [],
  currentProfile: {} as KitchenProfileData,
  products: {},
  displayLayout: LAYOUTS.LAYOUT_GRID,
  statusFilter: TICKET_STATUS.ALL,
  tableFilter: TABLE_TYPES.TABLE_TYPE_ALL,
  backendVersion: '',
  blinkingTimeout: 0,
  formatOptions: { timezone: 'UTC', locale: 'en-US' },
};

export type AppContextType = {
  appSettings: KitchenAppSettingsType;
  appIsReady: boolean;
  getGridDimensions: () => RowsColumns;
  changeTableFilter: (newTableFilter: TABLE_TYPES) => void;
  changeSelectedProfile: (selectedProfile: KitchenProfileData) => Promise<void>;
  changeSelectedLayout: (selectedLayout: LAYOUTS) => void;
  updateSelectedLayout: (selectedLayout: LAYOUTS) => void;
};

export const AppContext = createContext<AppContextType>({
  appSettings: defaultSettings,
  appIsReady: false,
  getGridDimensions: () => ({} as RowsColumns),
  changeTableFilter: () => {},
  changeSelectedProfile: () => Promise.resolve(),
  changeSelectedLayout: () => {},
  updateSelectedLayout: () => {},
});

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [appSettings, setAppSettings] = useState<KitchenAppSettingsType>(defaultSettings);
  const { logout } = useContext(AuthenticationContext);

  const [appIsReady, setAppIsReady] = useState<boolean>(false);

  const history = useHistory();

  const initializeApp = useCallback(async () => {
    try {
      const {
        companySettings,
        productCategories,
        deviceProfiles,
        deviceProfile,
        backendVersion,
      } = await kitchenConnector.loadApplicationSettings();

      setAppSettings({
        companySettings,
        deviceProfiles,
        currentProfile: deviceProfile,
        products: getProducts(productCategories),
        displayLayout: ((localStorage.getItem(KDS_DISPLAY_LAYOUT) as keyof typeof LAYOUTS) ||
          LAYOUTS.LAYOUT_GRID) as LAYOUTS,
        statusFilter: localStorage.getItem(KDS_STATE_FILTER)
          ? +localStorage.getItem(KDS_STATE_FILTER)!
          : TICKET_STATUS.ALL,
        tableFilter: ((localStorage.getItem(KDS_TABLE_FILTER) as keyof typeof TABLE_TYPES) ||
          TABLE_TYPES.TABLE_TYPE_ALL) as TABLE_TYPES,
        backendVersion,
        blinkingTimeout:
          deviceProfile && deviceProfile.settings && deviceProfile.settings.blinkInNew
            ? JSON.parse(deviceProfile.settings.blinkInNew as string)
            : 0,
        formatOptions: {
          timezone: companySettings.timeZoneID,
          locale: companySettings.locale,
        },
      });
      setAppIsReady(true);
    } catch (error) {
      if (error instanceof KDSDisabledError) {
        history.push('/disabled');
      } else if (error instanceof KDSRedirect) {
        window.location.href = '/kitchen-old/';
      } else {
        logout();
      }
    }
  }, []);

  useEffect(() => {
    initializeApp();
  }, [initializeApp]);

  const getGridDimensions = useCallback((): RowsColumns => {
    if (
      appSettings.currentProfile.settings &&
      appSettings.currentProfile.settings.ticketRowColumn
    ) {
      return appSettings.currentProfile.settings.ticketRowColumn as RowsColumns;
    }
    return { rows: 2, columns: 4 };
  }, [appSettings.currentProfile]);

  const changeTableFilter = useCallback(
    (newTableFilter: TABLE_TYPES) => {
      setAppIsReady(false);
      localStorage.setItem(KDS_TABLE_FILTER, newTableFilter);
      initializeApp();
    },
    [initializeApp],
  );

  const changeSelectedProfile = useCallback(
    async (selectedProfile: KitchenProfileData) => {
      setAppIsReady(false);
      localStorage.setItem(KDS_PROFILE_IDENTIFIER, `${selectedProfile.oid}`);
      initializeApp();
    },
    [initializeApp],
  );

  const changeSelectedLayout = useCallback(
    (selectedLayout: LAYOUTS) => {
      setAppIsReady(false);
      localStorage.setItem(KDS_DISPLAY_LAYOUT, selectedLayout);
      initializeApp();
      history.push(`/${selectedLayout}`);
    },
    [history, initializeApp],
  );

  const updateSelectedLayout = useCallback(
    (selectedLayout: LAYOUTS) => {
      localStorage.setItem(KDS_DISPLAY_LAYOUT, selectedLayout);
      setAppSettings({
        ...appSettings,
        displayLayout: selectedLayout,
      });
    },
    [appSettings],
  );

  return (
    <AppContext.Provider
      value={{
        appSettings,
        appIsReady,
        getGridDimensions,
        changeTableFilter,
        changeSelectedProfile,
        changeSelectedLayout,
        updateSelectedLayout,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
