import moment from 'moment';
import React, { useEffect, useState, FC } from 'react';

import styled from '@emotion/styled';
import { Flex, Box } from '@lightspeed/cirrus/Core';
import { themeGet } from '@styled-system/theme-get';
import { TICKET_STATUS, getStatusAsText } from '../../../../types/TicketStatuses';
import { InfoSeparator } from './info-separator';

interface TimeAndStatusProps {
  status: TICKET_STATUS;
  type: string;
  date?: string;
  noMargin?: boolean;
  inShowAndTell?: boolean;
}

export const TimeAndStatus: FC<TimeAndStatusProps> = ({
  date,
  status,
  noMargin,
  inShowAndTell,
  type,
}) => {
  const [dateState, setDate] = useState('');
  const voided = type === 'voided';

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (date) {
      setDate(getFormattedTime(date));
      interval = setInterval(() => {
        setDate(getFormattedTime(date));
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [date]);

  return (
    <TimeAndStatusWrapper
      data-testid="timeAndStatus"
      noMargin={noMargin}
      inShowAndTell={inShowAndTell}
    >
      <Box>{getStatusAsText(status, voided)}</Box>
      {dateState !== '' && status < TICKET_STATUS.ARCHIVED && type !== 'voided' && (
        <>
          <InfoSeparator mx={1} />
          <TicketTime data-testid="ticketTime">{dateState}</TicketTime>
        </>
      )}
    </TimeAndStatusWrapper>
  );
};

const TimeAndStatusWrapper = styled(Flex)<{ noMargin?: boolean; inShowAndTell?: boolean }>`
  margin-left: ${props => (props.noMargin ? 0 : props.theme.space[2])};
  margin-top: ${props => (props.inShowAndTell ? themeGet('space.1') : 0)};
  font-size: ${props =>
    props.inShowAndTell ? themeGet('kitchen.fonts.size.2') : themeGet('kitchen.fonts.size.1')};
  font-weight: ${themeGet('kitchen.fonts.weight.bolder')};
  height: ${themeGet('space.3')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  line-height: ${props =>
    props.inShowAndTell
      ? themeGet('kitchen.sizes.ticket.header.showAndTell')
      : themeGet('kitchen.sizes.ticket.header.sequenceNumber')};
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
`;

const getFormattedTime = (ticketDate: string) => {
  const { hours, minutes, seconds } = getDifference(ticketDate);
  let formattedMinutes = minutes;
  let formattedSeconds = seconds;
  let roundedSeconds = Math.round(seconds / 10) * 10;
  if (roundedSeconds === 60) {
    formattedMinutes = minutes + 1;
    formattedSeconds = 0;
    roundedSeconds = 0;
  }

  if (hours > 0) {
    return `${hours}h ${formattedMinutes > 9 ? formattedMinutes : `0${formattedMinutes}`}m`;
  }
  if (formattedMinutes > 0) {
    return `${formattedMinutes}m ${roundedSeconds > 0 ? roundedSeconds : '00'}s`;
  }
  return `${formattedSeconds}s`;
};

const getDifference = (ticketDate: string) => {
  const difference = moment.duration(moment().diff(moment(ticketDate)));
  return {
    hours: difference.get('hours'),
    minutes: difference.get('minutes'),
    seconds: difference.get('second'),
  };
};

const TicketTime = styled(Box)`
  white-space: nowrap;
`;
