import { useContext } from 'react';
import { MixpanelEvents } from '../../types/mixpanel-events';
import { MixpanelContext } from '../../state/mixpanel.provider';

export const useMoreCloseTracking = () => {
  const { track, getInteractionType, getSourceType } = useContext(MixpanelContext);

  const trackMoreClose = (event: Event) => {
    try {
      track(MixpanelEvents.MORE_CLOSE, getInteractionType(event), getSourceType());
    } catch (error) {
      // silently ignore the error
    }
  };

  return trackMoreClose;
};
