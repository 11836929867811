import React, { useCallback, useContext, FC, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { themeGet } from '@styled-system/theme-get'

import { Box } from '@lightspeed/cirrus/Core'

import { TicketsContext, AppContext } from '../../state'
import { DynamicTicket } from '../components/ticket/dynamic-ticket'
import { DynamicFooter } from '../components/footer/dynamic-footer'
import { useDynamicBumpBar } from '../../hooks/dynamic-layout-bump-bar.hook'
import { useSynchronizedAnimation } from '../../shared/hooks/synchronized-animation.hook'
import { AppContainer, AppLoader, KitchenHeader, MoreModal } from '../../shared/components'
import { LAYOUTS } from '../../types/Layouts'
import { KDS_DISPLAY_LAYOUT } from '../../types/localStorageAliases'

// import debug from 'debug'
// const log = debug('@kds')

export const DynamicLayout: FC<{}> = () => {
  const { getGridDimensions, updateSelectedLayout } = useContext(AppContext)
  const {
    state: { tickets, loading, totalsIndex },
  } = useContext(TicketsContext)

  const kitchenBodyRef = useRef<HTMLElement>()

  const [showMoreModal, setShowMoreModal] = useState<boolean>(false)

  const onMoreClicked = useCallback(() => {
    setShowMoreModal(!showMoreModal)
  }, [showMoreModal])

  if (localStorage.getItem(KDS_DISPLAY_LAYOUT) !== LAYOUTS.LAYOUT_DYNAMIC) {
    updateSelectedLayout(LAYOUTS.LAYOUT_DYNAMIC)
  }

  useSynchronizedAnimation(['blink-new'])
  useDynamicBumpBar(showMoreModal, onMoreClicked)

  const showApp = () =>
    tickets === undefined || tickets.length === 0 ? (
      <NoTickets>
        <EmptyTicketListMessage>No tickets available</EmptyTicketListMessage>
      </NoTickets>
    ) : (
      tickets.map((ticket, index) => {
        return (
          <DynamicTicket
            ticketId={ticket.id}
            modificationDate={ticket.modificationDate}
            navigationId={ticket.navigationId}
            number={index + 1}
            kdsStatusId={ticket.kdsStatusId}
            type={ticket.type}
            receiptId={ticket.receiptId}
            key={ticket.id}
            orderReference={ticket.receiptSource === 'onlineOrder' ? ticket.orderReference : null}
          />
        )
      })
    )

  return (
    <AppContainer>
      {showMoreModal && <MoreModal onCloseModal={onMoreClicked} />}
      <KitchenHeader data-testid="kitchen-header" onMoreClicked={onMoreClicked} />
      <ContentWrapper>
        <DynamicTicketsWrapper
          data-testid="kitchen-body"
          numberOfColumns={getGridDimensions().columns || 4}
          ref={kitchenBodyRef}
        >
          {loading ? <AppLoader /> : showApp()}
        </DynamicTicketsWrapper>
      </ContentWrapper>
      <DynamicFooter kitchenBodyRef={kitchenBodyRef} />
    </AppContainer>
  )
}

const ContentWrapper = styled(Box)`
  overflow-x: auto;
  position: absolute;
  height: auto;
  height: calc(100% - ${themeGet('kitchen.sizes.footer.height')});
  width: calc(100% - ${themeGet('space.2')} - ${themeGet('space.2')});
  margin-left: ${themeGet('space.2')};
  grid-area: 2 / 1;
`

const DynamicTicketsWrapper = styled(Box)<{ numberOfColumns: number }>`
  column-fill: auto;
  columns: ${({ numberOfColumns }) => `auto ${numberOfColumns}`};
  column-gap: ${themeGet('space.1')};
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  background: ${themeGet('kitchen.colors.background')};
  height: 100%;
  position: absolute;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`

const NoTickets = styled(Box)`
  display: grid;
  width: 100vw;
  height: 100%;
`

const EmptyTicketListMessage = styled(Box)`
  color: ${themeGet('kitchen.colors.emptyTicket.message')};
  font-size: ${themeGet('kitchen.fonts.size.1')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  line-height: ${themeGet('kitchen.sizes.body.emptyMessage.height')};
  background: ${themeGet('kitchen.colors.emptyTicket.messageBox')};
  box-shadow: ${themeGet('kitchen.shadows.normal')};
  border-radius: ${themeGet('kitchen.borders.radius.2')};
  padding: 0 ${themeGet('kitchen.margins.emptyMessage.paddingY')};

  justify-self: center;
  align-self: center;
`
