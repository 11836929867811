import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { CompanySettingsData } from 'kitchen-types';
import { KDS_TOKEN_IDENTIFIER } from '../types/localStorageAliases';
import { refreshToken, retryRequest } from './rpcUtils';
import { WrongCredentialError, KDSDisabledError } from './errors';

// const getBaseUrl = ({ origin }: Location) => `${origin.replace(/-web/, '')}/PosServer/JSON-RPC`;
const getBaseUrl = (origin: string | undefined) => `${origin}/PosServer/JSON-RPC`;

export const rpcClient = axios.create({
  // baseURL: getBaseUrl(window.location),
  baseURL: getBaseUrl(process.env.REACT_APP_LS_PROXY),
});

export const rpcLogin = axios.create({
  // baseURL: getBaseUrl(window.location),
  baseURL: getBaseUrl(process.env.REACT_APP_LS_PROXY),
});

rpcClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const updatedConfig = { ...config };
  const token = localStorage.getItem(KDS_TOKEN_IDENTIFIER);
  if (config.data) {
    if (Array.isArray(config.data.params)) {
      updatedConfig.data.params.unshift(token);
    } else if (typeof config.data.params !== 'undefined') {
      updatedConfig.data.params = [token, config.data.params];
    } else {
      updatedConfig.data.params = [token];
    }
  }
  return updatedConfig;
});

rpcClient.interceptors.response.use(
  (response): AxiosResponse<any> | Promise<AxiosResponse<any>> => {
    const {
      data: { error },
    } = response;
    if (error) {
      if (error.code === 1001 || error.code === 591) {
        localStorage.removeItem(KDS_TOKEN_IDENTIFIER);

        return refreshToken()
          .then(() => retryRequest(response))
          .catch(() => Promise.reject(response));
      }
      if (error.code === 1403) {
        return Promise.resolve({} as AxiosResponse);
      }
      return Promise.reject(response);
    }
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

rpcLogin.interceptors.response.use(
  (response): AxiosResponse<any> | Promise<AxiosResponse<any>> => {
    const {
      data: { error },
    } = response;
    if (error) {
      if (error.code === 1006) {
        return Promise.reject(new WrongCredentialError());
      }
      if (error.code === 11305) {
        return Promise.reject(new KDSDisabledError({} as CompanySettingsData));
      }
      response.data = error;
      return Promise.reject(response);
    }
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);
