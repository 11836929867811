import { useContext } from 'react';
import { TicketDetails } from 'kitchen-types';
import { TicketsContext } from '../state/tickets.provider';

export const useTicketDetails = (ticketId: string) => {
  const {
    state: { tickets },
  } = useContext(TicketsContext);

  const ticket = tickets.find(ticket => ticket.id === ticketId);

  return ticket || ({} as TicketDetails);
};
