import { init, Dict, Mixpanel } from 'mixpanel-browser';
import { useContext, useEffect, useCallback } from 'react';

import { AppContext } from '../state';
import { MixpanelInteractionType, MixpanelSourceType } from '../types/mixpanel-events';
import { KDS_DISPLAY_LAYOUT, KDS_LOGIN_INFO, KDS_APP_VERSION } from '../types/localStorageAliases';
import { LAYOUTS } from '../types/Layouts';

export const useMixpanel = () => {
  const {
    appSettings: { companySettings, deviceProfiles, currentProfile, backendVersion },
  } = useContext(AppContext);

  const defaultProperties = {
    'KDS - Timezone': companySettings.timeZoneID,
    'KDS - Locale': companySettings.locale,
    'KDS - Company Id': companySettings.oid,
    'KDS - Liteserver enforced': companySettings.enforceLiteserver,
    'KDS - Autoprint enabled': companySettings.kitchenAutoPrinting,
    'KDS - Number of profiles': deviceProfiles.length,
    'KDS - Current profile': currentProfile.name,
    'KDS - Backend version': backendVersion,
  };

  const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

  const enableTracking: boolean = mixpanelToken ? Boolean(mixpanelToken) : false;

  const mixpanel: Mixpanel | Partial<Mixpanel> = (() => {
    if (enableTracking) {
      try {
        return init(
          process.env.REACT_APP_MIXPANEL_TOKEN as string,
          { track_pageview: false },
          process.env.NODE_ENV === 'production' ? 'KDS Prod' : 'KDS Test',
        );
      } catch (error) {
        return {
          track: () => {},
        };
      }
    }
    return {
      track: () => {},
    };
  })();

  const track = useCallback(
    (
      eventName: string,
      interactionType: MixpanelInteractionType,
      sourceType: MixpanelSourceType,
      properties?: Dict,
      callback?: () => void,
    ) => {
      if (mixpanel && mixpanel.track && enableTracking) {
        try {
          mixpanel.track(
            eventName,
            {
              'KDS - Interaction type': interactionType,
              'KDS - Source type': sourceType,
              'KDS - Frontend version': localStorage.getItem(KDS_APP_VERSION),
              ...defaultProperties,
              ...properties,
            },
            callback,
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(`Tracking ${eventName} from ${sourceType} via ${interactionType}`);
      }
    },
    [defaultProperties, enableTracking, mixpanel],
  );

  useEffect(() => {
    const loginInfo = localStorage.getItem(KDS_LOGIN_INFO);

    if (mixpanel && mixpanel.reset && mixpanel.identify && mixpanel.people && loginInfo) {
      const { username } = JSON.parse(loginInfo);

      try {
        mixpanel.reset();
        mixpanel.identify(username || 'unknown');
        mixpanel.people.set_once({
          'KDS - First login date': new Date(),
          'KDS - Account': username || 'unknown',
        });
        mixpanel.people.set({
          'KDS - Last connection date': new Date(),
          'KDS - Starting Layout': localStorage.getItem(KDS_DISPLAY_LAYOUT) || LAYOUTS.LAYOUT_GRID,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [mixpanel]);

  return { mixpanel: { ...mixpanel }, track };
};
