export enum MixpanelInteractionType {
  TOUCH = 'Touch/Click',
  BUMPBAR = 'Bumpbar',
  PINCH = 'Pinch',
}

export enum MixpanelSourceType {
  NORMAL = 'Grid/Dynamic Layout',
  SNT = 'Show and Tell',
  GRID = 'Grid Layout',
  DYNAMIC = 'Dynamic Layout',
  UNKNOWN = 'Unknown',
}

export enum MixpanelEvents {
  FILTER_ALL = 'Filter "All"',
  FILTER_NEW = 'Filter "New"',
  FILTER_PREPARING = 'Filter "Preparing"',
  FILTER_DONE = 'Filter "Done"',
  FILTER_ARCHIVED = 'Filter "Archived"',

  MORE_OPEN = 'More - Open',
  MORE_CLOSE = 'More - Close',

  MORE_CLEAR = 'More - Clear (Open)',
  MORE_CLEAR_BACK = 'More - Clear (Back)',
  MORE_CLEAR_CLOSE = 'More - Clear (Close)',
  MORE_CLEAR_CONFIRM = 'More - Clear (Confirm)',

  MORE_FILTER = 'More - Filter (Open)',
  MORE_FILTER_BACK = 'More - Filter (Back)',
  MORE_FILTER_CLOSE = 'More - Filter (Close)',
  MORE_FILTER_ALL = 'More - Filter "All"',
  MORE_FILTER_DINEIN = 'More - Filter "Dine-In"',
  MORE_FILTER_DELIVERY = 'More - Filter "Delivery"',
  MORE_FILTER_TAKEOUT = 'More - Filter "Takeout"',
  MORE_FILTER_TAKEOUT_DELIVERY = 'More - Filter "Takeout & Delivery"',

  MORE_SETTINGS = 'More - Settings (Open)',
  MORE_SETTINGS_BACK = 'More - Settings (Back)',
  MORE_SETTINGS_CLOSE = 'More - Settings (Close)',
  MORE_SETTINGS_DEVICES = 'More - Settings "Devices"',
  MORE_SETTINGS_LAYOUTS = 'More - Settings "Layouts"',

  MORE_DEVICE = 'More - Device Profile (Open)',
  MORE_DEVICE_BACK = 'More - Device Profile (Back)',
  MORE_DEVICE_CLOSE = 'More - Device Profile (Close)',
  MORE_DEVICE_SELECTION = 'More - Device Profile Switch',

  MORE_LAYOUTS = 'More - Layout (Open)',
  MORE_LAYOUTS_BACK = 'More - Layout (Back)',
  MORE_LAYOUTS_CLOSE = 'More - Layout (Close)',
  MORE_LAYOUTS_STATIC = 'More - Layout Switch to static',
  MORE_LAYOUTS_DYNAMIC = 'More - Layout Switch to dynamic',
  MORE_LAYOUTS_STATION = 'More - Layout Switch to station',

  MORE_ACCOUNT = 'More - Account (Open)',
  MORE_ACCOUNT_BACK = 'More - Account (Back)',
  MORE_ACCOUNT_CLOSE = 'More - Account (Close)',
  MORE_ACCOUNT_SIGNOUT = 'More - Account Signout',

  TICKET_BUMP_TO_PREPARING = 'Bump ticket to "Preparing"',
  TICKET_BUMP_TO_DONE = 'Bump ticket to "Done"',
  TICKET_BUMP_TO_ARCHIVED = 'Bump ticket to "Archive"',
  TICKET_BUMP_VOID_TO_ARCHIVE = 'Bump voided ticket to "Archive"',
  TICKET_UNBUMP_TO_DONE = 'Unbump ticket to "Done"',
  TICKET_UNBUMP_TO_PREPARING = 'Unbump ticket to "Preparing"',
  TICKET_UNBUMP_TO_NEW = 'Unbump ticket to "New"',
  TICKET_PRIORITIZE = 'Prioritize ticket',
  TICKET_DEPRIORITIZE = 'Deprioritize ticket',

  TICKET_ZOOM_IN = 'Show and Tell - Open',
  TICKET_ZOOM_OUT = 'Show and Tell - Close',

  BUMPBAR_NUMBER_PRESS = 'Bumpbar number pressed',
  BUMPBAR_ARROW_PRESS = 'Bumpbar arrow pressed',

  CONTINUED_PRESS = 'Continued pill pressed',
}
