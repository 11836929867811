import React, { ReactNode, createContext, useCallback } from 'react';
import { Mixpanel, Dict } from 'mixpanel-browser';
import { useMixpanel } from '../hooks/useMixpanel';
import { MixpanelInteractionType, MixpanelSourceType } from '../types/mixpanel-events';

export type MixpanelContextType = {
  track: (
    eventName: string,
    interactionType: MixpanelInteractionType,
    sourceType: MixpanelSourceType,
    properties?: Dict,
    callback?: () => void,
  ) => void;
  mixpanel: Mixpanel | Partial<Mixpanel>;
  getSourceType: () => MixpanelSourceType;
  getInteractionType: (e: Event) => MixpanelInteractionType;
};

export const MixpanelContext = createContext<MixpanelContextType>({
  track: () => {},
  mixpanel: {
    track: () => {},
  },
  getSourceType: () => MixpanelSourceType.UNKNOWN,
  getInteractionType: () => MixpanelInteractionType.BUMPBAR,
});

export const MixpanelProvider = ({ children }: { children: ReactNode }) => {
  const { track, mixpanel } = useMixpanel();

  const getSourceType = useCallback(() => {
    if (window.location.hash.match(/\/dynamic/)) {
      return MixpanelSourceType.DYNAMIC;
    }
    if (window.location.hash.match(/\/grid/)) {
      return MixpanelSourceType.GRID;
    }
    if (window.location.hash.match(/\/ticket\//)) {
      return MixpanelSourceType.SNT;
    }
    return MixpanelSourceType.UNKNOWN;
  }, []);

  const getInteractionType = useCallback((event: Event) => {
    if (['keyup', 'keydown', 'keypress'].includes(event.type)) {
      return MixpanelInteractionType.BUMPBAR;
    }
    if (event.type === 'pinch') {
      return MixpanelInteractionType.PINCH;
    }
    return MixpanelInteractionType.TOUCH;
  }, []);

  return (
    <MixpanelContext.Provider
      value={{
        track,
        mixpanel,
        getSourceType,
        getInteractionType,
      }}
    >
      {children}
    </MixpanelContext.Provider>
  );
};
