import React, { useEffect, useState, FC } from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

import { Flex, Box } from '@lightspeed/cirrus/Core';

import { TICKET_STATUS, getStatusAsText } from '../../../../types/TicketStatuses';
import { InfoSeparator } from '../../../../shared/components';
import { getFormattedTime } from '../../../../utils/format-time';

interface TimeAndStatusProps {
  date: string;
  status: TICKET_STATUS;
  noMargin?: boolean;
  voided: boolean;
}

export const TimeAndStatus: FC<TimeAndStatusProps> = ({ date, status, noMargin, voided }) => {
  const [dateState, setDate] = useState(getFormattedTime(date));

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(getFormattedTime(date));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [date]);

  return (
    <TimeAndStatusWrapper data-testid="timeAndStatus" noMargin={noMargin}>
      <Box>{getStatusAsText(status, voided)}</Box>
      {status < TICKET_STATUS.ARCHIVED && (
        <>
          <InfoSeparator mx={2} />
          <TicketTime data-testid="ticketTime">{dateState}</TicketTime>
        </>
      )}
    </TimeAndStatusWrapper>
  );
};

const TimeAndStatusWrapper = styled(Flex)<{ noMargin?: boolean }>`
  margin-left: ${props => (props.noMargin ? 0 : props.theme.space[2])};
  margin-top: ${themeGet('space.1')};
  font-size: ${themeGet('kitchen.fonts.size.2')};
  font-weight: ${themeGet('kitchen.fonts.weight.bolder')};
  height: ${themeGet('space.3')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  line-height: ${themeGet('kitchen.sizes.ticket.header.showAndTell')};
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
`;

const TicketTime = styled(Box)`
  white-space: nowrap;
`;
