import React, { useState, useEffect, useRef, useContext } from 'react'

import { bumpBarMap, useMoreModalBumpbar } from '../../../hooks/useMoreModalBumpbar'
import { LAYOUTS } from '../../../types/Layouts'
import { MORE_MODAL_SCREENS } from '../../../types/MoreModal'
import { ACTION_KEYS } from '../../../utils/keyMap'
import { Action, BackAction } from './components/modal-action'
import { ActionDivider } from './components/modal-action-divider'
import { ActionList } from './components/modal-action-list'
import { StyledCheckmarkIcon } from './components/modal-icons'
import { KitchenModal } from './components/modal'
import { MixpanelContext } from '../../../state/mixpanel.provider'
import { MixpanelEvents, MixpanelInteractionType } from '../../../types/mixpanel-events'

type LayoutModalProps = {
  activeLayout: LAYOUTS
  onCloseModal: () => void
  onLayoutSwitch: (layout: LAYOUTS) => void
  onBack: (previousModal: string | null) => void
}

export const LayoutModal = ({
  activeLayout,
  onCloseModal,
  onLayoutSwitch,
  onBack,
}: LayoutModalProps) => {
  const [selection, setSelection] = useState(0)
  const { track, getSourceType } = useContext(MixpanelContext)

  const refSelectedElement = useRef(selection)

  useEffect(() => {
    refSelectedElement.current = selection
  })

  const bumpBarMap: bumpBarMap = {
    [ACTION_KEYS.UP]: () => {
      if (refSelectedElement.current > 0) {
        setSelection(refSelectedElement.current - 1)
      }
    },
    [ACTION_KEYS.DOWN]: () => {
      if (refSelectedElement.current < 2) {
        setSelection(refSelectedElement.current + 1)
      }
    },
    [ACTION_KEYS.CANCEL]: () => {
      track(MixpanelEvents.MORE_LAYOUTS_CLOSE, MixpanelInteractionType.BUMPBAR, getSourceType())
      onCloseModal()
    },
    [ACTION_KEYS.ENTER]: () => {
      switch (refSelectedElement.current) {
        case 0:
          track(MixpanelEvents.MORE_LAYOUTS_BACK, MixpanelInteractionType.BUMPBAR, getSourceType())
          onBack(MORE_MODAL_SCREENS.SETTINGS)
          break
        case 1:
          track(
            MixpanelEvents.MORE_LAYOUTS_STATIC,
            MixpanelInteractionType.BUMPBAR,
            getSourceType(),
          )
          onLayoutSwitch(LAYOUTS.LAYOUT_GRID)
          break
        default:
          track(
            MixpanelEvents.MORE_LAYOUTS_DYNAMIC,
            MixpanelInteractionType.BUMPBAR,
            getSourceType(),
          )
          onLayoutSwitch(LAYOUTS.LAYOUT_DYNAMIC)
      }
    },
  }

  useMoreModalBumpbar(bumpBarMap)

  return (
    <KitchenModal
      title="Layout"
      onCloseModal={(event: MouseEvent | KeyboardEvent) => {
        if (event && event.type === 'keydown') {
          track(MixpanelEvents.MORE_LAYOUTS_CLOSE, MixpanelInteractionType.BUMPBAR, getSourceType())
        } else {
          track(MixpanelEvents.MORE_LAYOUTS_CLOSE, MixpanelInteractionType.TOUCH, getSourceType())
        }
        onCloseModal()
      }}
    >
      <ActionList>
        <BackAction
          selected={selection === 0}
          onClick={() => {
            track(MixpanelEvents.MORE_LAYOUTS_BACK, MixpanelInteractionType.TOUCH, getSourceType())
            onBack(MORE_MODAL_SCREENS.SETTINGS)
          }}
          onSelectState={() => setSelection(0)}
        />
        <ActionDivider />
        <Action
          selected={selection === 1}
          text="Grid"
          onClick={() => {
            track(
              MixpanelEvents.MORE_LAYOUTS_STATIC,
              MixpanelInteractionType.TOUCH,
              getSourceType(),
            )
            onLayoutSwitch(LAYOUTS.LAYOUT_GRID)
          }}
          noChevron
          onSelectState={() => setSelection(1)}
        >
          {(activeLayout === LAYOUTS.LAYOUT_GRID || (activeLayout as string) === 'LAYOUT_GRID') && (
            <StyledCheckmarkIcon selected={selection === 1} />
          )}
        </Action>
        <ActionDivider />
        <Action
          selected={selection === 2}
          text="Dynamic"
          onClick={() => {
            track(
              MixpanelEvents.MORE_LAYOUTS_DYNAMIC,
              MixpanelInteractionType.TOUCH,
              getSourceType(),
            )
            onLayoutSwitch(LAYOUTS.LAYOUT_DYNAMIC)
          }}
          noChevron
          onSelectState={() => setSelection(2)}
        >
          {(activeLayout === LAYOUTS.LAYOUT_DYNAMIC ||
            (activeLayout as string) === 'LAYOUT_DYNAMIC') && (
            <StyledCheckmarkIcon selected={selection === 2} />
          )}
        </Action>
        <ActionDivider />
      </ActionList>
    </KitchenModal>
  )
}
