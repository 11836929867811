import React, { useContext, useMemo, FC, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

import { Flex } from '@lightspeed/cirrus/Core';

import { Header } from '../../../shared/components/ticket/header/header';
import { Body } from './body';
import { useSelection } from '../../../hooks/tickets/ticket-selection.hook';
import { isLowerTanIOS13 } from '../../../utils/is-lower-tan-ios-13';
import { useTicketClickHandler, useTicketPinchOut, useTracking } from '../../../hooks';
import { TicketsContext } from '../../../state';

export const Ticket: FC<{
  ticketId: string;
  modificationDate: string;
  number: number;
  navigationId: string;
  kdsStatusId: number;
  type: string;
  receiptId: number;
  orderReference?: string | null;
}> = ({
  ticketId,
  modificationDate,
  number,
  navigationId,
  kdsStatusId,
  type,
  receiptId,
  orderReference,
}) => {
  const { selected, selectTicket } = useSelection(ticketId);
  const { bump } = useContext(TicketsContext);
  const { trackZoomIn } = useTracking();
  const clickHandler = useTicketClickHandler();
  const history = useHistory();
  const ticketRef = useRef(null);

  useTicketPinchOut(ticketRef, () => {
    trackZoomIn(new Event('pinch'), receiptId);
    history.push(`/ticket/${ticketId}`);
  });

  return useMemo(() => {
    return (
      <TicketWrapper
        data-testid={`ticket-wrapper-${ticketId}`}
        ref={ticketRef}
        selected={selected}
        onClick={(event: Event) => {
          event.preventDefault();
          event.stopPropagation();
          if (isLowerTanIOS13) {
            clickHandler(event, ticketId, kdsStatusId, type, receiptId, selectTicket);
          } else {
            selectTicket(ticketId);
          }
        }}
        onDoubleClick={(event: Event) => {
          event.preventDefault();
          event.stopPropagation();
          bump(event, ticketId, kdsStatusId, type, receiptId);
        }}
      >
        <Header
          selected={selected}
          ticketId={ticketId}
          modificationDate={modificationDate}
          number={number}
          navigationId={navigationId}
          kdsStatusId={kdsStatusId}
          type={type}
          receiptId={receiptId}
          orderReference={orderReference}
        />
        <Body selected={selected} ticketId={ticketId} />
      </TicketWrapper>
    );
  }, [
    selected,
    ticketId,
    modificationDate,
    number,
    navigationId,
    clickHandler,
    selectTicket,
    bump,
    kdsStatusId,
    type,
    receiptId,
    orderReference,
  ]);
};

const TicketWrapper = styled(Flex)<{ selected: boolean }>`
  position: relative;
  flex-direction: column;
  flex: 1;
  user-select: none;
  border-radius: ${themeGet('space.1')};
  break-inside: avoid-column;
  border: ${({ selected, theme }) =>
    selected ? theme.kitchen.borders.ticket.selected : theme.kitchen.borders.ticket.notSelected};
`;
