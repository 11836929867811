// import {
//   KitchenSettings,
//   GenericSettingObject,
//   Section,
//   Setting,
//   SubSection,
// } from 'kitchen-types';

export interface KitchenSettings {
  [any: string]:any
}
export interface GenericSettingObject {
  [any: string]:any
}
export interface Section {
  [any: string]:any
}
export interface Setting {
  [any: string]:any
}
export interface SubSection {
  [any: string]:any
}

export const defaultKitchenSettings: KitchenSettings = {
  settingsDefinitionVersion: 34,
  kitchen: {
    sections: [
      {
        key: 'layoutSettings',
        label: 'Layout Settings',
        description: 'Specify how many columns and/or rows you wish to use on your displays.',
        subsections: [
          {
            title: '',
            settings: [
              {
                key: 'ticketRowColumn',
                title: 'Show kitchen items',
                type: 'rowColumnSelect',
                defaultValue: {
                  rows: 2,
                  columns: 4,
                },
                max: {
                  rows: 6,
                  columns: 6,
                },
              },
            ],
          },
        ],
      },
      {
        key: 'displaySettings',
        label: 'Display Settings',
        description:
          'Specify which orders and products appear on the kitchen display when this profile is selected.',
        subsections: [
          {
            title: '',
            settings: [
              {
                key: 'showKitchenItems',
                title: 'Show kitchen items',
                type: 'boolean',
                visible: false,
              },
              {
                key: 'showBarItems',
                title: 'Show bar items',
                type: 'boolean',
                visible: false,
              },
              {
                key: 'filterTicketsByFloor',
                description:
                  'When this profile is selected, only orders from the specified floors will be displayed.',
                title: 'Floors',
                type: 'multi-checkbox',
                defaultValue: [],
                defaultAsync: 'floors',
              },
              {
                key: 'filterTicketsByProductCategory',
                description:
                  'When this profile is selected, only products from the specified categories will be displayed.',
                title: 'Product Categories',
                type: 'multi-checkbox',
                defaultValue: [],
                defaultAsync: 'productCategories',
              },

              {
                key: 'printerFilter',
                title: 'Printer Template Types',
                description:
                  'When this profile is selected, only products whithin categories assigned to the specified printer template type(s) will be displayed.',
                type: 'select',
                defaultValue: '0',
                items: [
                  { value: '0', name: 'Kitchen type items only' },
                  { value: '1', name: 'Bar type items only' },
                  { value: '2', name: 'Kitchen and bar type items' },
                ],
              },
            ],
          },
        ],
      },
      {
        key: 'alertSettings',
        label: 'Alert Settings',
        description:
          'Specify if new tickets cause an alert sound to play and if tickets flash on-screen after a period of inactivity.',
        subsections: [
          {
            title: '',
            settings: [
              {
                key: 'ringOnNewOrder',
                title: 'New order alert sound',
                description: 'Play a bell sound when a new ticket appears on the kitchen display.',
                type: 'boolean',
                defaultValue: false,
              },
              {
                key: 'blinkInNew',
                title: "Tickets with 'new' status flash when inactive",
                type: 'select',
                defaultValue: 0,
                items: [
                  { value: 0, name: 'No' },
                  { value: 1, name: 'After 1 minute' },
                  { value: 2, name: 'After 2 minutes' },
                  { value: 3, name: 'After 3 minutes' },
                  { value: 4, name: 'After 4 minutes' },
                  { value: 5, name: 'After 5 minutes' },
                  { value: 6, name: 'After 6 minutes' },
                  { value: 7, name: 'After 7 minutes' },
                  { value: 8, name: 'After 8 minutes' },
                  { value: 9, name: 'After 9 minutes' },
                  { value: 10, name: 'After 10 minutes' },
                  { value: 11, name: 'After 11 minutes' },
                  { value: 12, name: 'After 12 minutes' },
                  { value: 13, name: 'After 13 minutes' },
                  { value: 14, name: 'After 14 minutes' },
                  { value: 15, name: 'After 15 minutes' },
                  { value: 16, name: 'After 16 minutes' },
                  { value: 17, name: 'After 17 minutes' },
                  { value: 18, name: 'After 18 minutes' },
                  { value: 19, name: 'After 19 minutes' },
                  { value: 20, name: 'After 20 minutes' },
                ],
              },
              // Kept as it will be added in an issue following HOS-16076
              // {
              //   key: 'blinkInCooking',
              //   title: "Tickets with 'cooking' status flash when inactive",
              //   type: 'select',
              //   defaultValue: '0',
              //   items: [
              //     { value: '0', name: 'No' },
              //     { value: '1', name: 'After 1 minute' },
              //     { value: '3', name: 'After 3 minutes' },
              //     { value: '5', name: 'After 5 minutes' },
              //     { value: '10', name: 'After 10 minutes' },
              //     { value: '15', name: 'After 15 minutes' }
              //   ]
              // }
            ],
          },
        ],
      },
    ],
  },
};

export const getKitchenDefaultSettings = (): GenericSettingObject => {
  return defaultKitchenSettings.kitchen.sections.reduce(
    (allSettings: GenericSettingObject, section: Section) => {
      return section.subsections.reduce(
        (allSettingsInSubsection: GenericSettingObject, subsection: SubSection) => {
          return subsection.settings.reduce(
            (finalSettings: GenericSettingObject, setting: Setting) => {
              return {
                ...finalSettings,
                [setting.key]: setting.defaultValue !== undefined ? setting.defaultValue : null,
              };
            },
            allSettingsInSubsection,
          );
        },
        allSettings,
      );
    },
    {},
  );
};
