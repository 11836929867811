import React, { FC } from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled from '@emotion/styled';

import { Box, Flex } from '@lightspeed/cirrus/Core';

import { Modifiers } from '../../../shared/components/ticket/body/modifiers';

interface TicketItemProps {
  quantity: number;
  name: string;
  modifiers?: string[];
  selected: boolean;
  index?: number;
}

export const TicketItem: FC<TicketItemProps> = ({ quantity, name, modifiers, selected, index }) => (
  <TicketItemWrapperContainer data-testid={`ticketItemContainer-${index}`}>
    <TicketItemWrapper data-testid={`ticketItem-${index}`} selected={selected}>
      <Box data-testid={`itemQuantity-${index}`}>{quantity}</Box>
      <ItemName data-testid={`itemName-${index}`}>{name}</ItemName>
      <Box />
      <ModifierWrapper>
        {modifiers && modifiers.length ? <Modifiers modifiers={modifiers} /> : null}
      </ModifierWrapper>
    </TicketItemWrapper>
  </TicketItemWrapperContainer>
);

const TicketItemWrapperContainer = styled(Box)`
  width: 100%;
  border-bottom: ${themeGet('kitchen.borders.ticket.item')};
`;

const TicketItemWrapper = styled(Flex)<{ selected: boolean }>`
  display: grid;
  grid-template-columns: ${themeGet('kitchen.sizes.ticket.items.quantityWidth')} 1fr;
  grid-template-rows: auto minmax(0, auto);
  width: ${props =>
    props.selected
      ? `calc(100% - ${props.theme.space[2]} - ${props.theme.space[1]})`
      : `calc(100% - ${props.theme.space[3]} - ${props.theme.space[2]})`};
  word-wrap: break-word;
  margin-left: ${themeGet('space.2')};
  padding-top: ${themeGet('space.2')};
  padding-bottom: ${themeGet('space.2')};
  line-height: ${themeGet('kitchen.fonts.size.3')};
  font-size: ${themeGet('kitchen.fonts.size.1')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  color: ${themeGet('kitchen.colors.ticket.items.text')};
  flex-direction: row;
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
`;

const ItemName = styled(Box)`
  word-break: break-all;
`;

const ModifierWrapper = styled(Flex)`
  color: ${themeGet('kitchen.colors.ticket.items.modifier')};
  font-size: ${themeGet('kitchen.fonts.size.0')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  min-height: ${props => (props.children ? themeGet('space.3') : themeGet('space.0'))};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  line-height: ${props => (props.children ? themeGet('space.3') : themeGet('space.0'))};
  flex-wrap: wrap;
  word-break: break-all;
  margin-top: ${props => (props.children ? themeGet('space.2') : themeGet('space.0'))};
`;
