import { createBuilder } from './typesBuilder';

export type ProductData = {
  imageLocationKitchen: string;
  imageLocation: string;
  kitchenName: string;
  name: string;
  oid: number;
  info: string;
  pId: string;
};

const defaultProductData: ProductData = {
  imageLocationKitchen: 'some-url',
  imageLocation: 'some-url',
  kitchenName: 'Loempia',
  name: 'Loempia',
  oid: 138221,
  info: 'information',
  pId: 'a plu',
};

export const productsDataBuilder = createBuilder<ProductData>(defaultProductData);
