import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import { Box } from '@lightspeed/cirrus/Core';
import { IconCross } from '@lightspeed/cirrus/Icon/Cross';

import { TicketDetails } from 'kitchen-types';

import { StyledHeader } from '../../../shared/components/header/header';
import { ButtonWrapper } from './button-wrapper';
import { HeaderActions } from './header-actions';
import { useShowAndTellBumpBar } from '../../show-and-tell.provider';
import { BUTTONS } from '../../../shared/components/header/header-buttons';
import { useTracking } from '../../../hooks';

export const ShowAndTellHeader = ({ ticket }: { ticket: TicketDetails }) => {
  const history = useHistory();

  const { selectedElement } = useShowAndTellBumpBar();
  const { trackZoomOut } = useTracking();

  return (
    <StyledHeader>
      <ButtonWrapper
        selected={selectedElement === BUTTONS.CLOSE}
        data-testid="showAndTellCancelButton"
        onClick={(event: Event) => {
          trackZoomOut(event, ticket.receiptId);
          history.push('/');
        }}
      >
        <CancelIcon />
        <span>Close</span>
      </ButtonWrapper>

      <ActionsContainer>
        <HeaderActions ticket={ticket} />
      </ActionsContainer>
    </StyledHeader>
  );
};

const ActionsContainer = styled(Box)`
  display: grid;
  grid-auto-flow: column;
`;

const CancelIcon = withTheme(({ theme: { kitchen: { colors: { header: { text } } } } }: any) => (
  <IconCross color={text} />
));
