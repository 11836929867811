import React, { useState, useRef, useEffect, useContext } from 'react';

import { KitchenProfileData } from 'kitchen-types';
import { Box } from '@lightspeed/cirrus/Core';

import { bumpBarMap, useMoreModalBumpbar } from '../../../hooks/useMoreModalBumpbar';
import { MORE_MODAL_SCREENS } from '../../../types/MoreModal';
import { ACTION_KEYS } from '../../../utils/keyMap';
import { Action, BackAction } from './components/modal-action';
import { ActionDivider } from './components/modal-action-divider';
import { ActionList } from './components/modal-action-list';
import { StyledCheckmarkIcon } from './components/modal-icons';
import { KitchenModal } from './components/modal';
import { MixpanelContext } from '../../../state/mixpanel.provider';
import { MixpanelEvents, MixpanelInteractionType } from '../../../types/mixpanel-events';

type KitchenProfileModalProps = {
  profiles: KitchenProfileData[] | null;
  activeProfile: KitchenProfileData | null;
  onCloseModal: () => void;
  onProfileSelected: (profile: KitchenProfileData) => void;
  onBack: (previousModal: string | null) => void;
};

export const KitchenProfileModal = ({
  profiles,
  activeProfile,
  onCloseModal,
  onProfileSelected,
  onBack,
}: KitchenProfileModalProps) => {
  const [selection, setSelection] = useState(0);
  const { track, getSourceType } = useContext(MixpanelContext);

  const refSelectedElement = useRef(selection);

  useEffect(() => {
    refSelectedElement.current = selection;
  });

  const bumpBarMap: bumpBarMap = {
    [ACTION_KEYS.UP]: () => {
      if (refSelectedElement.current > 0) {
        setSelection(refSelectedElement.current - 1);
      }
    },
    [ACTION_KEYS.DOWN]: () => {
      if (profiles) {
        if (refSelectedElement.current < profiles.length) {
          setSelection(refSelectedElement.current + 1);
        }
      }
    },
    [ACTION_KEYS.CANCEL]: () => {
      track(MixpanelEvents.MORE_DEVICE_CLOSE, MixpanelInteractionType.BUMPBAR, getSourceType());
      onCloseModal();
    },
    [ACTION_KEYS.ENTER]: () => {
      if (refSelectedElement.current === 0) {
        track(MixpanelEvents.MORE_DEVICE_BACK, MixpanelInteractionType.BUMPBAR, getSourceType());
        onBack(MORE_MODAL_SCREENS.SETTINGS);
      } else {
        track(
          MixpanelEvents.MORE_DEVICE_SELECTION,
          MixpanelInteractionType.BUMPBAR,
          getSourceType(),
        );
        onProfileSelected(profiles![refSelectedElement.current - 1]);
      }
    },
  };

  useMoreModalBumpbar(bumpBarMap);

  return (
    <KitchenModal
      title="Device profile"
      onCloseModal={(event: MouseEvent | KeyboardEvent) => {
        if (event && event.type === 'keydown') {
          track(MixpanelEvents.MORE_DEVICE_CLOSE, MixpanelInteractionType.BUMPBAR, getSourceType());
        } else {
          track(MixpanelEvents.MORE_DEVICE_CLOSE, MixpanelInteractionType.TOUCH, getSourceType());
        }
        onCloseModal();
      }}
    >
      <ActionList>
        <BackAction
          selected={selection === 0}
          onClick={() => {
            track(MixpanelEvents.MORE_DEVICE_BACK, MixpanelInteractionType.TOUCH, getSourceType());
            onBack(MORE_MODAL_SCREENS.SETTINGS);
          }}
          onSelectState={() => setSelection(0)}
        />
        <ActionDivider />
        {profiles &&
          profiles.map((profile, index) => {
            return (
              <Box key={profile.oid}>
                <Action
                  selected={selection === index + 1}
                  text={profile.name}
                  onClick={() => {
                    track(
                      MixpanelEvents.MORE_DEVICE_SELECTION,
                      MixpanelInteractionType.TOUCH,
                      getSourceType(),
                    );
                    onProfileSelected(profile);
                  }}
                  noChevron
                  onSelectState={() => setSelection(index + 1)}
                >
                  {activeProfile && activeProfile.oid === profile.oid && (
                    <StyledCheckmarkIcon selected={selection === index + 1} />
                  )}
                </Action>
                <ActionDivider />
              </Box>
            );
          })}
      </ActionList>
    </KitchenModal>
  );
};
