import { createBuilder } from './typesBuilder';
import { TABLE_TYPE } from './tableTypes';

export type FloorTableData = {
  floorId: number;
  name: string;
  numberOfClients: number;
  oid: string;
  type: TABLE_TYPE;
};

const defaultFloorTableData: FloorTableData = {
  oid: '184866',
  type: TABLE_TYPE.DINE_IN,
  floorId: 8029,
  numberOfClients: 4,
  name: 'test-table',
};

export const floorTableDataBuilder = createBuilder<FloorTableData>(defaultFloorTableData);
