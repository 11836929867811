import { createContext, useMemo, useContext } from 'react'
import type { ReactNode } from 'react'

import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

import { KDS_ACCOUNT } from '../types/localStorageAliases'

// import debug from 'debug'
// const log = debug('@kds')

interface FirestoreContextType {
  [key: string]: any
}

export const FirestoreContext = createContext<FirestoreContextType>({} as FirestoreContextType)

export const FirestoreProvider = ({ children }: { children: ReactNode }) => {
  const collectionName = localStorage.getItem(KDS_ACCOUNT)
  const db = useMemo(() => {
    var firebaseConfig = {
      apiKey: 'AIzaSyCqO-c_0n5TBopcxKrbq5MtxWmvcb6ILR0',
      authDomain: 'supper-deck-kds.firebaseapp.com',
      projectId: 'supper-deck-kds',
      storageBucket: 'supper-deck-kds.appspot.com',
      messagingSenderId: '747414748550',
      appId: '1:747414748550:web:cd0a78dd5951121ab98007',
    }
    const app = initializeApp(firebaseConfig)
    return getFirestore(app)
  }, [])

  return (
    <FirestoreContext.Provider value={{ db, collectionName }}>{children}</FirestoreContext.Provider>
  )
}

export const useFirestore = () => {
  const props = useContext(FirestoreContext)
  return { ...props }
}
