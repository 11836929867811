import React, { useContext, useMemo, FC } from 'react';

import styled from '@emotion/styled';
import { Flex } from '@lightspeed/cirrus/Core';

import { TicketsContext } from '../../../state';
import { PaginationDot } from './pagination-dot';

export const Pagination: FC<{}> = () => {
  const {
    state: {
      pagination: { numberOfPages, selectedPage },
    },
  } = useContext(TicketsContext);

  return useMemo(() => {
    const pagination = Array.from({ length: numberOfPages }, (_value, key) => key);
    return (
      <PaginationWrapper data-testid="kitchenNavigation">
        {pagination.length > 1 &&
          pagination.map(page => (
            <PaginationDot key={page} selected={selectedPage === page} value={page} />
          ))}
      </PaginationWrapper>
    );
  }, [numberOfPages, selectedPage]);
};

const PaginationWrapper = styled(Flex)`
  position: absolute;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: ${({ theme }) => `calc(100vw - (2*${theme.space[2]}))`};
  align-items: center;
  justify-content: center;
`;
