import React, { useContext, useMemo, FC } from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled from '@emotion/styled';

import { Box } from '@lightspeed/cirrus/Core';

import { AppContext, TicketsContext } from '../../../state';
import { MoreButton } from '../more-button';
import { HeaderFilters } from './header-filters';
import { useTracking } from '../../../hooks';

export const KitchenHeader: FC<{ onMoreClicked: () => void }> = ({ onMoreClicked }) => {
  const {
    appSettings: { currentProfile, tableFilter },
  } = useContext(AppContext);
  const {
    state: { selectedButton },
  } = useContext(TicketsContext);

  const { trackMoreOpen } = useTracking();

  return useMemo(
    () => (
      <StyledHeader>
        <MoreButton
          currentProfileName={currentProfile.name}
          currentFilter={tableFilter}
          selected={selectedButton === 'more'}
          onMoreClicked={(event: Event) => {
            trackMoreOpen(event);
            onMoreClicked();
          }}
        />
        <HeaderFilters />
      </StyledHeader>
    ),
    [currentProfile.name, onMoreClicked, selectedButton, tableFilter],
  );
};

export const StyledHeader = styled(Box)`
  display: grid;
  grid-auto-flow: column;
  height: ${themeGet('kitchen.sizes.header.height')};
  background-color: ${themeGet('kitchen.colors.header.background')};
  backface-visibility: hidden;
  align-items: center;
  color: ${themeGet('kitchen.colors.header.text')};
  padding: 0 ${themeGet('space.2')};
  justify-content: space-between;
  grid-area: 1/1;
`;
