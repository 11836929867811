import React, { useState, useEffect, MutableRefObject, useContext } from 'react';

import styled from '@emotion/styled';

import { themeGet } from '@styled-system/theme-get';
import { TicketsContext } from '../../../state';

const SCROLLBAR_WIDTH = 89;

export const Scroll = ({
  containerWrapper,
}: {
  containerWrapper: MutableRefObject<HTMLElement | undefined>;
}) => {
  const [scrollbarOffset, setScrollbarOffset] = useState(0);
  const [widthIndicator, setWidthIndicator] = useState(SCROLLBAR_WIDTH);
  const {
    state: { tickets },
  } = useContext(TicketsContext);

  useEffect(() => {
    const container = containerWrapper && containerWrapper.current;
    const width = container
      ? SCROLLBAR_WIDTH / (container.scrollWidth / container.clientWidth)
      : SCROLLBAR_WIDTH;
    setWidthIndicator(width);

    const handleResize = () => {
      if (container) {
        setWidthIndicator(width);
      }
    };

    const handleScroll = () => {
      if (container) {
        // Prevent scrolling when "under scrolling"
        if (container.scrollLeft < 0) {
          setScrollbarOffset(0);
        }
        // Prevent scrolling when "over scrolling"
        else if (container.scrollLeft + container.clientWidth > container.scrollWidth) {
          setScrollbarOffset(1 - width / SCROLLBAR_WIDTH);
        } else {
          const scrollBarOffset = container.scrollLeft / container.scrollWidth;
          setScrollbarOffset(scrollBarOffset);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerWrapper, tickets]);

  return widthIndicator < SCROLLBAR_WIDTH ? (
    <ScrollBar data-testid="scrollbar">
      <ScrollIndicator width={widthIndicator} offset={scrollbarOffset} />
    </ScrollBar>
  ) : null;
};

const ScrollBar = styled('div')`
  width: ${SCROLLBAR_WIDTH}px;
  height: 10px;
  background-color: ${themeGet('kitchen.colors.footer.scrollbar.background')};
  border-radius: ${themeGet('kitchen.sizes.footer.scrollbar.radius')};
`;

const ScrollIndicator = styled('div')<{ width: number; offset: number }>`
  width: ${({ width }) => width}px;
  height: 10px;
  background-color: white;
  border-radius: ${themeGet('kitchen.sizes.footer.scrollbar.radius')};
  margin-left: calc(${({ offset }) => offset} * 100%);
  border: 1px solid ${themeGet('kitchen.colors.footer.scrollbar.background')};
`;
