import { useContext } from 'react'

import { buildProductKey, ITEM_TYPES } from '../types/ExpeditedTickets'
import { TicketItem } from '../kitchen-types'
import { TicketsContext } from '../state'

export const useExpeditedItem = (ticketId: string, item: TicketItem) => {
  const { state, toggleItem } = useContext(TicketsContext)
  const productKey = buildProductKey(item)

  let itemType: ITEM_TYPES | undefined = undefined
  if (ticketId in state.items) {
    itemType = state.items[ticketId].items.get(productKey)
  }

  const highlighted = itemType === ITEM_TYPES.HIGHLIGHTED
  const cleared = itemType === ITEM_TYPES.CLEARED

  const toggle = () => {
    if (itemType === undefined) {
      itemType = ITEM_TYPES.HIGHLIGHTED
    } else if (highlighted) {
      itemType = ITEM_TYPES.CLEARED
    } else {
      itemType = undefined
    }
    toggleItem(ticketId, item)
  }

  return {
    toggle,
    highlighted,
    cleared,
  }
}

// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
export const hashString = (input: string) => {
  let hash = 0,
    i,
    chr
  if (input.length === 0) return hash
  for (i = 0; i < input.length; i++) {
    chr = input.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}
