import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { IconExport } from '@lightspeed/cirrus/Icon/Export';
import { IconProps } from '@lightspeed/cirrus/Icon/utils/iconFactory';

import { TICKET_STATUS, getStatusAsText } from '../../../types/TicketStatuses';
import { ButtonWrapper } from './button-wrapper';

export const UnBump = ({
  selected,
  unBump,
  currentStatus,
  voided,
}: {
  selected: boolean;
  unBump: (event: Event, id: string) => Promise<void>;
  currentStatus: TICKET_STATUS;
  voided: boolean;
}) => (
  <ButtonWrapper selected={selected} onClick={unBump}>
    <BumpIcon />
    <span>{getStatusAsText(currentStatus - 1, voided)}</span>
  </ButtonWrapper>
);

const BumpIcon = withTheme(({ theme: { kitchen: { colors: { header: { text } } } } }: any) => (
  <Icon color={text} />
));

const Icon = styled(IconExport)<IconProps>`
  transform: rotate(180deg);
`;
