import React, { useContext, FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AuthenticationContext } from '../../../state';

export const AuthenticatedApp: FC<
  {
    component: any;
  } & RouteProps
> = ({ component: Component, ...rest }) => {
  const { isUserAuthenticated } = useContext(AuthenticationContext);

  return (
    <Route
      {...rest}
      render={(props: RouteProps) =>
        isUserAuthenticated === true ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};
