import React, { FC, useRef, useContext } from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

import { Flex, Box } from '@lightspeed/cirrus/Core';

import { TicketItem } from './ticket-item';
import { useTicketDetails, useScrollInfo } from '../../../hooks';
import { ContinuedIndicator, } from '../../../shared/components/ticket/continued-indicator/continued-indicator';
import { TicketItemListMoreSpacer } from '../../../shared/components/ticket/ticket-list-continued-spacer';
import { ContinuedIndicatorTransparent, } from '../../../shared/components/ticket/continued-indicator/continued-indicator-transparent';
import { MixpanelContext } from '../../../state';
import {
  MixpanelEvents,
  MixpanelInteractionType,
  MixpanelSourceType,
} from '../../../types/mixpanel-events';

export const Body: FC<{ selected: boolean; ticketId: string }> = ({ selected, ticketId }) => {
  const { receiptId, items } = useTicketDetails(ticketId);

  const itemListRef = useRef<HTMLElement>();

  const { visible } = useScrollInfo(itemListRef);
  const { track } = useContext(MixpanelContext);

  const logClickInMixpanel = () => {
    track(MixpanelEvents.CONTINUED_PRESS, MixpanelInteractionType.TOUCH, MixpanelSourceType.NORMAL);
  };

  return (
    <TicketBodyFlex selected={selected}>
      <TicketItemList ref={itemListRef} numberOfItems={items.length}>
        {items.map((item, index) => {
          const key = `${receiptId}-${item.productId}-${index}`;
          return (
            <TicketItem
              key={key}
              quantity={item.amount}
              name={item.kitchenName}
              modifiers={item.modifiers}
              selected={selected}
              index={index}
            />
          );
        })}
        {visible && <TicketItemListMoreSpacer />}
      </TicketItemList>
      {visible &&
        (selected ? (
          <ContinuedIndicator selected={true} onClick={logClickInMixpanel} />
        ) : (
          <ContinuedIndicatorTransparent onClick={logClickInMixpanel} />
        ))}
    </TicketBodyFlex>
  );
};

const TicketBodyFlex = styled(Flex)<{ selected: boolean }>`
  background: ${({ ...theme }) => themeGet('kitchen.colors.ticket.body.background')(theme)};
  overflow: auto;
  align-items: '';
  justify-content: 'center';
  flex-direction: row;
  flex-grow: 1;
  border-bottom-left-radius: ${({ selected, ...theme }) =>
    selected ? 0 : themeGet('radii.radius-2')(theme)};
  border-bottom-right-radius: ${({ selected, ...theme }) =>
    selected ? 0 : themeGet('radii.radius-2')(theme)};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TicketItemList = styled(Box)<{ numberOfItems: number }>`
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  grid-template-rows: ${({ numberOfItems }) => `repeat(${numberOfItems}, max-content)`};
`;
