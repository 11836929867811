import React, { useState, useEffect, FC } from 'react';
import { themeGet } from '@styled-system/theme-get';

import styled from '@emotion/styled';
import { Text } from '@lightspeed/cirrus/Text';
import { FormattedTime } from './formatted-time';

const TimeText = styled(Text)`
  color: ${themeGet('kitchen.colors.footer.clock')};
  font-size: ${themeGet('fontSizes.text-xs')};
  font-weight: ${themeGet('kitchen.fonts.weight.bolder')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  text-align: right;
  width: 65px;
`;

export const Clock: FC<{}> = () => {
  const [currentTime, setCurrentTime] = useState<string>(new Date().toISOString());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toISOString());
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <TimeText data-testid="timeText">
      <FormattedTime time={currentTime} />
    </TimeText>
  );
};
