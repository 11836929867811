import { useContext } from 'react';
import { MixpanelEvents } from '../../types/mixpanel-events';
import { MixpanelContext } from '../../state/mixpanel.provider';

export const useZoomOutTracking = () => {
  const { track, getInteractionType, getSourceType } = useContext(MixpanelContext);

  const trackZoomOut = (event: Event, receiptId: number) => {
    try {
      track(MixpanelEvents.TICKET_ZOOM_OUT, getInteractionType(event), getSourceType(), {
        receiptId,
      });
    } catch (error) {
      // silently ignore the error
    }
  };

  return trackZoomOut;
};
