import { ProductCategoryData, ProductData } from 'kitchen-types'

export const getProducts = (
  productsCategories: ProductCategoryData[] | null,
): { [key: string]: ProductData } => {
  if (productsCategories === null) return {}
  return productsCategories!
    .map((productCategory) => productCategory.products)
    .reduce((categoryProducts, productArray) => [...categoryProducts, ...productArray], [])
    .reduce((allProducts, product) => ({ ...allProducts, [product.oid]: product }), {})
}
