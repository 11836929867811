export interface TotalsIndexEntry {
  productId: number
  name: string
  quantity: number
  modifiers: {
    [key: string]: number
  }
  color: string
}

export const sortTotalsIndex = (a: TotalsIndexEntry, b: TotalsIndexEntry) => {
  var nameA = a.name.toUpperCase()
  var nameB = b.name.toUpperCase()
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}

export const randomColor = () => {
  return `rgb(${[1, 2, 3].map(() => (Math.random() * 256) | 0)})`
}
