export class ApiError extends Error {
  code: number;

  constructor(message: string, code: number) {
    super(message);
    this.code = code;
  }

  getMessage = () => this.message;

  getCode = () => this.code;
}
