import React, {
  ReactNode,
  createContext,
  useReducer,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import {
  collection,
  doc,
  setDoc,
  deleteDoc,
  getDocs,
  query,
  where,
  onSnapshot,
  serverTimestamp,
  writeBatch,
} from 'firebase/firestore'
import type { QuerySnapshot } from 'firebase/firestore'
import { Howl } from 'howler'
import { ticketReducer, KitchenState } from './tickets.reducer'
import { AppContext } from './application.provider'

import { RowsColumns, Tickets, TicketDetails, TicketItem } from 'kitchen-types'
import { TICKET_STATUS } from '../types/TicketStatuses'
import { QuickFilterEventsMapping } from '../types/quick-filter-events-mapping'
import { kitchenConnector } from '../api/kitchenConnector'
import { ikewApi } from '../api/ikew'
import { LAYOUTS } from '../types/Layouts'
import { KDS_STATE_FILTER } from '../types/localStorageAliases'
import { TICKET_ACTIONS } from '../types/TicketActions'
import { TotalsIndexEntry, randomColor } from '../types/TotalsIndex'
import {
  ITEM_TYPES,
  ExpeditedTicket,
  ExpeditedItem,
  buildProductKey,
} from '../types/ExpeditedTickets'

import {
  loadTicketsAction,
  selectButtonAction,
  selectTicketAction,
  filterTicketsAction,
  selectPageAction,
  bumpTicketAction,
  unbumpTicketAction,
  prioritiseTicketAction,
  receiptRefreshedAction,
  toggleItemAction,
  updateClearedItemsAction,
} from './tickets.action'
import { AuthenticationContext } from './authentication.provider'
import { useTracking, useFirestore } from '../hooks'

import debug from 'debug'
const log = debug('@kds')

interface TicketsCtx {
  state: KitchenState
  bump: (
    event: Event,
    id: string,
    kdsStatusId: number,
    type: string,
    receiptId: number,
  ) => Promise<void>
  unbump: (event: Event, id: string, kdsStatusId: number, receiptId: number) => Promise<void>
  selectTicket: (id: string) => void
  selectButton: (id: string) => void
  filterTickets: (event: Event, state: TICKET_STATUS) => Promise<void>
  reloadTicketsWebSocket: () => Promise<void>
  selectPage: (page: number) => Promise<void>
  nextPage: (index?: number) => Promise<void>
  previousPage: (index?: number) => Promise<void>
  prioritise: (event: Event, id: string) => Promise<void>
  clearTickets: () => Promise<void>
  getTicket: (ticketId: string) => TicketDetails | undefined
  toggleItem: (ticketId: string, item: TicketItem) => Promise<void>
  purgeTrackedItems: (ticketId: string) => Promise<void>
  ikewEnabled: boolean
  ikewGroups: { [key: string]: string }
  ikewSlots: { [ticketId: string]: string }
  ikewInProgress: (ticket: TicketDetails) => Promise<void>
  ikewReady: (ticket: TicketDetails) => Promise<void>
  dispatch: any
}

export const TicketsContext = createContext<TicketsCtx>({} as TicketsCtx)

const sound = new Howl({
  src: [`${process.env.PUBLIC_URL}/sound/order.mp3`],
})

export const TicketsProvider = ({
  children,
  setTicketToPrint,
}: {
  children: ReactNode
  setTicketToPrint: React.Dispatch<React.SetStateAction<string | null>>
}) => {
  const {
    appSettings: {
      statusFilter,
      tableFilter,
      displayLayout,
      currentProfile,
      companySettings: { kitchenAutoPrinting },
    },
  } = useContext(AppContext)

  const { logout } = useContext(AuthenticationContext)

  const { trackBump, trackUnbump, trackPrioritize, trackDeprioritize, trackQuickFilter } =
    useTracking()

  const [state, dispatch] = useReducer(ticketReducer, {
    tickets: [],
    ticketsCounts: {
      all: -1,
      new: -1,
      preparing: -1,
      done: -1,
      archived: -1,
    },
    selectedTicket: null,
    refreshingTickets: [],
    selectedButton: null,
    stateFilter: statusFilter,
    pagination: { numberOfPages: 0, selectedPage: 0 },
    loading: true,
    lastTicketReceivedAt: null,
    items: {},
    totalsIndex: {},
    clearedIndex: {},
  })

  const { db, collectionName } = useFirestore()

  async function withLogout<T>(promise: Promise<T>): Promise<T | void> {
    return promise.catch(() => logout())
  }

  const selectedTicketIndex = state.selectedTicket
    ? state.tickets.findIndex((ticket) => ticket.id === state.selectedTicket)
    : 0

  const getTicket = (ticketId: string) => state.tickets.find((ticket) => ticket.id === ticketId)

  const getNumberOfTicketsToLoad = useCallback(() => {
    if (displayLayout === LAYOUTS.LAYOUT_DYNAMIC) {
      return 50
    }
    if (currentProfile.settings && currentProfile.settings.ticketRowColumn) {
      const { rows, columns } = currentProfile.settings.ticketRowColumn as RowsColumns
      return rows * columns
    }
    return 12
  }, [currentProfile.settings, displayLayout])

  const showBarItems = !!currentProfile.settings.showBarItems
  const showKitchenItems = !!currentProfile.settings.showKitchenItems
  const productCategoriesFilter =
    (currentProfile.settings.filterTicketsByProductCategory as number[]) || []
  const floorsFilter = (currentProfile.settings.filterTicketsByFloor as number[]) || []

  const clearTickets = async () => {
    await withLogout(kitchenConnector.clearTickets())
  }

  let totalsIndex = useRef<{ [key: string]: TotalsIndexEntry }>({})
  let clearedItemsIndex = useRef<{ [productId: string]: number }>({})

  const loadTickets = useCallback(
    async (params: { page?: number; status?: TICKET_STATUS }) => {
      const showBar = showBarItems
      let showKitchen = showKitchenItems

      if (!showBar && !showKitchen) {
        showKitchen = !showKitchen
      }

      const internalStatus = params.status !== undefined ? params.status : state.stateFilter

      let { content, totalPages, totals } =
        internalStatus === TICKET_STATUS.ARCHIVED
          ? ((await withLogout<Tickets>(
              kitchenConnector.loadHistoryTickets(
                params.page !== undefined ? params.page : state.pagination.selectedPage,
                getNumberOfTicketsToLoad(),
                showKitchen,
                showBar,
              ),
            )) as Tickets)
          : ((await withLogout<Tickets>(
              kitchenConnector.loadTickets(
                params.page !== undefined ? params.page : state.pagination.selectedPage,
                getNumberOfTicketsToLoad(),
                internalStatus,
                tableFilter,
                showKitchen,
                showBar,
                productCategoriesFilter,
                floorsFilter,
              ),
            )) as Tickets)

      let transformedTotals = {
        all: -1,
        new: -1,
        preparing: -1,
        done: -1,
        archived: -1,
      }
      if (totals !== undefined && totals.map) {
        const { map: amountOfTicketsPerFilter } = totals
        transformedTotals = {
          all:
            amountOfTicketsPerFilter[0] + amountOfTicketsPerFilter[1] + amountOfTicketsPerFilter[2],
          new: amountOfTicketsPerFilter[0],
          preparing: amountOfTicketsPerFilter[1],
          done: amountOfTicketsPerFilter[2],
          archived: amountOfTicketsPerFilter[3],
        }
      }

      const transformedContent: TicketDetails[] = []
      const index: { [key: string]: TotalsIndexEntry } = {}

      content.forEach((currentTicket) => {
        const items = currentTicket.items.map((item) => ({
          ...item,
          kitchenName: item.kitchenName || item.name,
        }))
        transformedContent.push({
          ...currentTicket,
          items,
        })

        const { kdsStatusId } = currentTicket
        const includeTotals =
          kdsStatusId === TICKET_STATUS.NEW ||
          kdsStatusId === TICKET_STATUS.PREPARING ||
          kdsStatusId === TICKET_STATUS.DONE

        if (!includeTotals) {
          return
        }

        items.forEach((item) => {
          if (!index.hasOwnProperty(item.productId)) {
            index[item.productId] = {
              productId: item.productId,
              name: item.name,
              quantity: item.amount,
              color: randomColor(),
              modifiers: {},
            }
          } else {
            index[item.productId].quantity += item.amount
          }

          item.modifiers.forEach((modifier) => {
            if (modifier in index[item.productId].modifiers) {
              index[item.productId].modifiers[modifier] += item.amount
              return
            }
            index[item.productId].modifiers[modifier] = item.amount
          })
        })
      })

      totalsIndex.current = index

      return {
        tickets: transformedContent,
        numberOfPages: totalPages,
        totals: transformedTotals,
        totalsIndex: index,
      }
    },
    [
      getNumberOfTicketsToLoad,
      showBarItems,
      showKitchenItems,
      state.pagination.selectedPage,
      state.stateFilter,
      tableFilter,
    ],
  )

  useEffect(() => {
    withLogout(
      loadTickets({}).then(({ tickets, numberOfPages, totals, totalsIndex }) =>
        dispatch(loadTicketsAction({ tickets, numberOfPages, totals, totalsIndex })),
      ),
    )
  }, [])

  useEffect(() => {
    if (state.lastTicketReceivedAt && !!currentProfile.settings.ringOnNewOrder) {
      sound.play()
    }
  }, [currentProfile.settings.ringOnNewOrder, state.lastTicketReceivedAt])

  const selectPage = async (page: number, originatingTicket?: string) => {
    dispatch(selectPageAction({ page }))
    const { tickets, numberOfPages, totals, totalsIndex } = await loadTickets({ page })
    dispatch(loadTicketsAction({ tickets, numberOfPages, totals, originatingTicket, totalsIndex }))
  }

  const bumpTicket = async (ticketId: string) => {
    const ticket = state.tickets.find((t) => t.id === ticketId)
    if (ticket && ticket.kdsStatusId === TICKET_STATUS.PREPARING && kitchenAutoPrinting) {
      setTicketToPrint(ticketId)
    }
    if (ticket && ticket.kdsStatusId === TICKET_STATUS.DONE) {
      purgeTrackedItems(ticketId)
    }
    await withLogout(kitchenConnector.updateTicket(ticketId, TICKET_ACTIONS.BUMP))
    const { tickets, numberOfPages, totals, totalsIndex } = await loadTickets({})
    if (tickets.length === 0) {
      if (state.pagination.selectedPage > 0) {
        selectPage(state.pagination.selectedPage - 1, ticketId)
      } else {
        dispatch(
          loadTicketsAction({
            tickets,
            numberOfPages,
            totals,
            originatingTicket: ticketId,
            totalsIndex,
          }),
        )
      }
    } else {
      dispatch(
        loadTicketsAction({
          tickets,
          numberOfPages,
          totals,
          originatingTicket: ticketId,
          selectedTicketIndex,
          totalsIndex,
        }),
      )
    }
  }

  const unBumpTicket = async (ticketId: string) => {
    await withLogout(kitchenConnector.updateTicket(ticketId, TICKET_ACTIONS.UNBUMP))
    const { tickets, numberOfPages, totals, totalsIndex } = await loadTickets({})
    if (tickets.length === 0) {
      if (state.pagination.selectedPage > 0) {
        selectPage(state.pagination.selectedPage - 1, ticketId)
      } else {
        dispatch(
          loadTicketsAction({
            tickets,
            numberOfPages,
            totals,
            originatingTicket: ticketId,
            totalsIndex,
          }),
        )
      }
    } else {
      dispatch(
        loadTicketsAction({
          tickets,
          numberOfPages,
          totals,
          originatingTicket: ticketId,
          selectedTicketIndex,
          totalsIndex,
        }),
      )
    }
  }

  const selectButton = (id: string) => dispatch(selectButtonAction({ id }))

  const selectTicket = (id: string) => dispatch(selectTicketAction({ id }))

  const filterTickets = async (event: Event, status: TICKET_STATUS) => {
    trackQuickFilter(event, QuickFilterEventsMapping[(status as number) + 1])
    localStorage.setItem(KDS_STATE_FILTER, `${status}`)
    dispatch(selectPageAction({ page: 0 }))
    dispatch(filterTicketsAction({ status }))
    const { tickets, numberOfPages, totals, totalsIndex } = await loadTickets({
      status,
      page: 0,
    })
    dispatch(
      loadTicketsAction({
        tickets,
        numberOfPages,
        totals,
        totalsIndex,
      }),
    )
  }

  const reloadTicketsWebSocket = useCallback(async () => {
    const { tickets, numberOfPages, totals, totalsIndex } = await loadTickets({})
    dispatch(
      loadTicketsAction({ tickets, numberOfPages, totals, keepSelection: true, totalsIndex }),
    )
  }, [loadTickets])

  const ticketIsNotLoading = (id: string) =>
    state.refreshingTickets.findIndex((ticketId) => ticketId === id) === -1

  const bump = async (
    event: Event,
    id: string,
    kdsStatusId: number,
    type: string,
    receiptId: number,
  ) => {
    if (ticketIsNotLoading(id) && kdsStatusId !== TICKET_STATUS.ARCHIVED) {
      trackBump(event, kdsStatusId, type, receiptId)
      dispatch(bumpTicketAction({ id }))
      await bumpTicket(id)
    }
    if (kdsStatusId === TICKET_STATUS.PREPARING && ikewEnabled) {
      const ticket = getTicket(id)
      ticket && ikewReady(ticket)
    }
    if (kdsStatusId === TICKET_STATUS.DONE && ikewEnabled) {
      const slotMap = ikewSlots.current
      delete slotMap[id]
      ikewSlots.current = slotMap
    }
  }

  const unbump = async (event: Event, id: string, kdsStatusId: number, receiptId: number) => {
    if (ticketIsNotLoading(id) && kdsStatusId !== TICKET_STATUS.NEW) {
      dispatch(unbumpTicketAction({ id }))
      trackUnbump(event, kdsStatusId, receiptId)

      await unBumpTicket(id)
      if (state.stateFilter === TICKET_STATUS.ARCHIVED) {
        filterTickets(event, TICKET_STATUS.ALL)
      } else {
        dispatch(selectTicketAction({ id }))
      }

      if (kdsStatusId === TICKET_STATUS.DONE && ikewEnabled) {
        const ticket = getTicket(id)
        ticket && ikewUnbump(ticket)
      }
    }
  }

  const nextPage = async (nextSelectionIndex?: number) => {
    const page = (state.pagination.selectedPage + 1) % state.pagination.numberOfPages
    dispatch(selectPageAction({ page }))
    const { tickets, numberOfPages, totals, totalsIndex } = await loadTickets({ page })
    dispatch(
      loadTicketsAction({
        tickets,
        numberOfPages,
        totals,
        selectedTicketIndex: nextSelectionIndex,
        totalsIndex,
      }),
    )
  }

  const previousPage = async (nextSelectionIndex?: number) => {
    const page =
      (state.pagination.selectedPage - 1 + state.pagination.numberOfPages) %
      state.pagination.numberOfPages

    dispatch(selectPageAction({ page }))
    const { tickets, numberOfPages, totals, totalsIndex } = await loadTickets({ page })

    dispatch(
      loadTicketsAction({
        tickets,
        numberOfPages,
        totals,
        selectedTicketIndex: nextSelectionIndex,
        totalsIndex,
      }),
    )
  }

  const prioritise = async (event: Event, id: string) => {
    if (ticketIsNotLoading(id)) {
      const ticket = getTicket(id)
      if (ticket) {
        dispatch(prioritiseTicketAction({ receiptId: ticket.receiptId }))
        if (ticket.prioritized) {
          trackDeprioritize(event, ticket)
          await withLogout(
            kitchenConnector.updateTicket(`${ticket.receiptId}`, TICKET_ACTIONS.DEPRIORITISE),
          )
        } else {
          trackPrioritize(event, ticket)
          await withLogout(
            kitchenConnector.updateTicket(`${ticket.receiptId}`, TICKET_ACTIONS.PRIORITISE),
          )
        }
        dispatch(selectPageAction({ page: 0 }))
        const { tickets, numberOfPages, totals, totalsIndex } = await loadTickets({ page: 0 })
        dispatch(
          loadTicketsAction({
            tickets,
            numberOfPages,
            totals,
            selectedTicketIndex: 0,
            totalsIndex,
          }),
        )
        dispatch(receiptRefreshedAction({ id: ticket.receiptId }))
      }
    }
  }

  const getExpeditedTicket = (ticketId: string): ExpeditedTicket => {
    let ticket = state.items[ticketId]
    if (!ticket) {
      state.items[ticketId] = { items: new Map() }
    }
    return state.items[ticketId]
  }

  useEffect(() => {
    if (!collectionName) {
      return
    }

    const q = query(collection(db, collectionName))
    onSnapshot(q, (snapshot) => {
      const cleared = clearedItemsIndex.current

      snapshot.docChanges().forEach((change: any) => {
        const { doc } = change
        const { items } = state

        const expItem = doc.data() as ExpeditedItem
        const [, productKey] = doc.id.split('.')
        const { product, quantity } = expItem
        const [id] = product.split('_')
        const productId = Number.parseInt(id)

        if (!cleared[productId]) {
          cleared[productId] = 0
        }

        if (
          (!doc.metadata.hasPendingWrites &&
            change.type === 'added' &&
            (expItem.state === ITEM_TYPES.CLEARED || expItem.state === ITEM_TYPES.HIGHLIGHTED)) ||
          (!doc.metadata.hasPendingWrites &&
            change.type === 'modified' &&
            expItem.state === ITEM_TYPES.HIGHLIGHTED)
        ) {
          cleared[productId] += quantity
        } else if (change.type === 'removed' && !doc.metadata.hasPendingWrites) {
          cleared[productId] -= quantity
        }

        if (change.type === 'added' || change.type === 'modified') {
          const ticket = getExpeditedTicket(expItem.ticket)
          ticket.items.set(productKey, expItem.state)
        }
        if (change.type === 'removed') {
          const ticket = getExpeditedTicket(expItem.ticket)
          ticket.items.delete(expItem.product)
          if (ticket && ticket.items.size === 0) {
            delete items[expItem.ticket]
          }
        }
      })

      clearedItemsIndex.current = cleared
      dispatch(updateClearedItemsAction({ clearedIndex: cleared }))
    })
  }, [])

  const toggleItem = async (ticketId: string, item: TicketItem) => {
    if (!collectionName) {
      return
    }

    const { id, items, kdsStatusId, type, receiptId } = getTicket(ticketId) as TicketDetails

    let expeditedTicket = state.items[ticketId]
    if (!expeditedTicket) {
      state.items[ticketId] = { items: new Map() }
      expeditedTicket = state.items[ticketId]
    }

    let clearedItems = 0
    expeditedTicket.items.forEach((status: number) => {
      if (status === ITEM_TYPES.CLEARED) {
        clearedItems += 1
      }
    })

    const productKey = buildProductKey(item)
    const expItem = expeditedTicket.items.get(productKey)
    const timestamp = serverTimestamp()
    const docId = `${ticketId}.${productKey}`

    if (!expItem) {
      await setDoc(doc(db, collectionName, docId), {
        ticket: ticketId,
        product: productKey,
        quantity: item.amount,
        state: ITEM_TYPES.HIGHLIGHTED,
        timestamp,
      })
    } else if (expItem && expItem === ITEM_TYPES.HIGHLIGHTED) {
      await setDoc(
        doc(db, collectionName, docId),
        {
          state: ITEM_TYPES.CLEARED,
          timestamp,
        },
        {
          merge: true,
        },
      )

      clearedItems += 1
    } else {
      await deleteDoc(doc(db, collectionName, docId))
      clearedItems -= 1
    }

    dispatch(toggleItemAction())

    // bump when all 1) items are cleared, 2) ticket is new
    const ticketCleared = kdsStatusId === TICKET_STATUS.PREPARING && items.length === clearedItems
    const newAndToggled = kdsStatusId === TICKET_STATUS.NEW
    if (newAndToggled || ticketCleared) {
      var event = new MouseEvent('dblclick', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      bump(event, id, kdsStatusId, type, receiptId)
    }
  }

  const deleteBatch = async (snapshot: QuerySnapshot) => {
    const batch = writeBatch(db)
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref)
    })
    await batch.commit()
  }

  const purgeTrackedItems = async (ticketId?: string | undefined) => {
    const ref = collection(db, collectionName)
    let querySnapshot: QuerySnapshot<any>
    if (ticketId) {
      log(`Purging tracked tickets: ${ticketId}`)
      let q = query(ref, where('ticket', '==', ticketId))
      querySnapshot = await getDocs(q)
    } else {
      log('Purging tracked tickets: all')
      querySnapshot = await getDocs(ref)
    }
    querySnapshot.forEach(() => {
      const batchSize = querySnapshot.size
      if (batchSize === 0) {
        return
      }
      deleteBatch(querySnapshot)
    })
  }

  // iKew Integration

  const ikewEnabled = !!window.localStorage.getItem('ikew')
  const ikewGroups: { [key: string]: string } = {
    '262606': 'grabfood',
    '262697': 'grabfood',
    '262548': 'deliveroo',
  }
  let ikewSlots = useRef<{ [ticketId: string]: string }>({})
  const platfromIdRegex = /(\w+\s-\s)(?<orderId>.+)/

  const ikewInProgress = async (ticket: TicketDetails) => {
    if (!ticket) {
      return
    }
    const { orderReference, tableId, id } = ticket
    if (!orderReference) {
      return
    }
    const group = ikewGroups[tableId.toString()]
    if (!group) {
      log(`ikew: Cannot assign group to ${ticket.id}`)
      return
    }
    const match = orderReference.match(platfromIdRegex)
    if (match?.groups) {
      const { orderId: label } = match.groups
      const slotId = await ikewApi.inProgress({ group, label })
      const slotMap = ikewSlots.current
      slotMap[id] = slotId
      ikewSlots.current = slotMap
    }
  }

  const ikewReady = async (ticket: TicketDetails) => {
    const { id } = ticket
    const slotMap = ikewSlots.current
    const slotID = slotMap[id]
    if (!slotID) {
      return
    }
    await ikewApi.ready(slotID)
  }

  const ikewUnbump = async (ticket: TicketDetails) => {
    const { id } = ticket
    const slotMap = ikewSlots.current
    const slotID = slotMap[id]
    if (!slotID) {
      return
    }

    await ikewApi.unbump(slotID)
  }

  return (
    <TicketsContext.Provider
      value={{
        state,
        selectButton,
        filterTickets,
        selectTicket,
        reloadTicketsWebSocket,
        bump,
        unbump,
        selectPage,
        nextPage,
        previousPage,
        prioritise,
        clearTickets,
        getTicket,
        purgeTrackedItems,
        dispatch,
        toggleItem,
        ikewEnabled,
        ikewGroups,
        ikewSlots: ikewSlots.current,
        ikewInProgress,
        ikewReady,
      }}
    >
      {children}
    </TicketsContext.Provider>
  )
}
