import { useGesture } from 'react-use-gesture';
import { useEffect } from 'react';

export const useTicketPinchOut = (domTarget: any, callback?: Function, threshold: number = 50) => {
  const pinchOut = useGesture(
    {
      onPinch: ({ offset: [distance] }) => {
        if (distance > threshold && callback) {
          callback();
        }
      },
    },
    { domTarget, eventOptions: { passive: false } },
  ) as () => void;

  useEffect(pinchOut, [pinchOut]);

  return {
    pinchOut,
  };
};

export const useTicketPinchIn = (domTarget: any, callback?: Function, threshold: number = -50) => {
  const pinchIn = useGesture(
    {
      onPinch: ({ offset: [distance] }) => {
        if (distance < threshold && callback) {
          callback();
        }
      },
    },
    { domTarget, eventOptions: { passive: false } },
  ) as () => void;

  useEffect(pinchIn, [pinchIn]);

  return {
    pinchIn,
  };
};
