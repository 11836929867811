import { withTheme } from '@emotion/react';
import React from 'react';
import { themeGet } from '@styled-system/theme-get';

import styled from '@emotion/styled';
import { IconAppsPurchased } from '@lightspeed/cirrus/Icon/AppsPurchased';
import { IconCheckmark } from '@lightspeed/cirrus/Icon/Checkmark';
import { IconChevronLeft } from '@lightspeed/cirrus/Icon/ChevronLeft';
import { IconChevronRight } from '@lightspeed/cirrus/Icon/ChevronRight';
import { IconCustomFields } from '@lightspeed/cirrus/Icon/CustomFields';
import { IconDelete } from '@lightspeed/cirrus/Icon/Delete';
import { IconSettings } from '@lightspeed/cirrus/Icon/Settings';
import { IconUsers } from '@lightspeed/cirrus/Icon/Users';
import { IconProps } from '@lightspeed/cirrus/Icon/utils/iconFactory';

import { IKitchenTheme } from '../../../../theming/themes';
import { ReactComponent as FilterIconSvg } from '../../icons/filter.svg';

// ###### Delete icon ######
const DeleteIconStyled = styled(IconDelete)<IconProps>`
  size: ${themeGet('kitchen.sizes.modal.action.icon.size')};
`;
const DeleteIcon = ({ theme, selected }: { theme: IKitchenTheme; selected: boolean | undefined }) =>
  selected ? (
    <DeleteIconStyled color={theme.kitchen.colors.modal.action.icon.selected} />
  ) : (
    <DeleteIconStyled color={theme.kitchen.colors.modal.action.icon.default} />
  );
export const StyledDeleteIcon = withTheme(DeleteIcon);

// ###### Settings icon ######
const SettingsIconStyled = styled(IconSettings)<IconProps>`
  size: ${themeGet('kitchen.sizes.modal.action.icon.size')};
`;
const SettingsIcon = ({
  theme,
  selected,
}: {
  theme: IKitchenTheme;
  selected: boolean | undefined;
}) =>
  selected ? (
    <SettingsIconStyled color={theme.kitchen.colors.modal.action.icon.selected} />
  ) : (
    <SettingsIconStyled color={theme.kitchen.colors.modal.action.icon.default} />
  );
export const StyledSettingsIcon = withTheme(SettingsIcon);

// ###### Account icon ######
const AccountIconStyled = styled(IconUsers)<IconProps>`
  size: ${themeGet('kitchen.sizes.modal.action.icon.size')};
`;
const AccountIcon = ({
  theme,
  selected,
}: {
  theme: IKitchenTheme;
  selected: boolean | undefined;
}) =>
  selected ? (
    <AccountIconStyled color={theme.kitchen.colors.modal.action.icon.selected} />
  ) : (
    <AccountIconStyled color={theme.kitchen.colors.modal.action.icon.default} />
  );
export const StyledAccountIcon = withTheme(AccountIcon);

// ###### Chevron Left icon ######
const ChevronLeftIconStyled = styled(IconChevronLeft)<IconProps>`
  size: ${themeGet('kitchen.sizes.modal.action.icon.size')};
`;
const ChevronLeftIcon = ({
  theme,
  selected,
}: {
  theme: IKitchenTheme;
  selected: boolean | undefined;
}) =>
  selected ? (
    <ChevronLeftIconStyled color={theme.kitchen.colors.modal.action.icon.selected} />
  ) : (
    <ChevronLeftIconStyled color={theme.kitchen.colors.modal.action.icon.default} />
  );
export const StyledChevronLeftIcon = withTheme(ChevronLeftIcon);

// ###### Chevron Right icon ######
const ChevronRightIconStyled = styled(IconChevronRight)<IconProps>`
  size: ${themeGet('kitchen.sizes.modal.action.icon.size')};
`;
const ChevronRightIcon = ({
  theme,
  selected,
}: {
  theme: IKitchenTheme;
  selected: boolean | undefined;
}) =>
  selected ? (
    <ChevronRightIconStyled color={theme.kitchen.colors.modal.action.icon.selected} />
  ) : (
    <ChevronRightIconStyled color={theme.kitchen.colors.modal.action.icon.default} />
  );
export const StyledChevronRightIcon = withTheme(ChevronRightIcon);

// ###### Checkmark icon ######
const CheckmarkIconStyled = styled(IconCheckmark)<IconProps>`
  size: ${themeGet('kitchen.sizes.modal.action.icon.size')};
`;
const CheckmarkIcon = ({
  theme,
  selected,
}: {
  theme: IKitchenTheme;
  selected: boolean | undefined;
}) =>
  selected ? (
    <CheckmarkIconStyled color={theme.kitchen.colors.modal.action.icon.selected} />
  ) : (
    <CheckmarkIconStyled color={theme.kitchen.colors.modal.action.icon.default} />
  );
export const StyledCheckmarkIcon = withTheme(CheckmarkIcon);

// ###### Device Profile icon ######
const DeviceProfileIconStyled = styled(IconCustomFields)<IconProps>`
  size: ${themeGet('kitchen.sizes.modal.action.icon.size')};
`;
const DeviceProfileIcon = ({
  theme,
  selected,
}: {
  theme: IKitchenTheme;
  selected: boolean | undefined;
}) =>
  selected ? (
    <DeviceProfileIconStyled color={theme.kitchen.colors.modal.action.icon.selected} />
  ) : (
    <DeviceProfileIconStyled color={theme.kitchen.colors.modal.action.icon.default} />
  );
export const StyledDeviceProfileIcon = withTheme(DeviceProfileIcon);

// ###### Layout icon ######
const LayoutIconStyled = styled(IconAppsPurchased)<IconProps>`
  size: ${themeGet('kitchen.sizes.modal.action.icon.size')};
`;
const LayoutIcon = ({ theme, selected }: { theme: IKitchenTheme; selected: boolean | undefined }) =>
  selected ? (
    <LayoutIconStyled color={theme.kitchen.colors.modal.action.icon.selected} />
  ) : (
    <LayoutIconStyled color={theme.kitchen.colors.modal.action.icon.default} />
  );
export const StyledLayoutIcon = withTheme(LayoutIcon);

// ###### Filter icon ######
const FilterIconStyled = styled(FilterIconSvg)<IconProps>`
  size: ${themeGet('kitchen.sizes.modal.action.icon.size')};
`;
const FilterIcon = ({ theme, selected }: { theme: IKitchenTheme; selected: boolean | undefined }) =>
  selected ? (
    <FilterIconStyled
      fill={theme.kitchen.colors.modal.action.icon.selected}
      color={theme.kitchen.colors.modal.action.icon.selected}
    />
  ) : (
    <FilterIconStyled
      fill={theme.kitchen.colors.modal.action.icon.default}
      color={theme.kitchen.colors.modal.action.icon.default}
    />
  );

export const StyledFilterIcon = withTheme(FilterIcon);
