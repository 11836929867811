import React, { FC, useRef, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { themeGet } from '@styled-system/theme-get'

import { Box } from '@lightspeed/cirrus/Core'

import { Header } from '../../../shared/components/ticket/header/header'
import { DynamicTicketBody } from './dynamic-ticket-body'
import { useSelection } from '../../../hooks/tickets/ticket-selection.hook'
import { useTicketDetails } from '../../../hooks'
import { useTicketPinchOut, useTracking } from '../../../hooks'
import { TICKET_STATUS } from '../../../types/TicketStatuses'
import { TicketsContext } from '../../../state'

import debug from 'debug'
const log = debug('@kds')

export const DynamicTicket: FC<{
  ticketId: string
  number: number
  modificationDate: string
  navigationId: string
  kdsStatusId: number
  type: string
  receiptId: number
  orderReference?: string | null
}> = ({
  number,
  ticketId,
  modificationDate,
  navigationId,
  kdsStatusId,
  type,
  receiptId,
  orderReference,
}) => {
  const { selected } = useSelection(ticketId)
  const ticketRef = useRef<HTMLElement>()
  const { trackZoomIn } = useTracking()
  const history = useHistory()

  useTicketPinchOut(ticketRef, () => {
    trackZoomIn(new Event('pinch'), receiptId)
    history.push(`/ticket/${ticketId}`)
  })

  useEffect(() => {
    selected &&
      ticketRef &&
      ticketRef.current &&
      ticketRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' })
  }, [selected])

  return (
    <DynamicTicketWrapper
      data-testid={`ticket-wrapper-${ticketId}`}
      ref={ticketRef}
      selected={selected}
    >
      <Header
        selected={selected}
        ticketId={ticketId}
        modificationDate={modificationDate}
        number={number}
        navigationId={navigationId}
        kdsStatusId={kdsStatusId}
        type={type}
        receiptId={receiptId}
        orderReference={orderReference}
      />
      <DynamicTicketBody selected={selected} ticketId={ticketId} />
      <Ikew ticketId={ticketId} />
    </DynamicTicketWrapper>
  )
}

const Ikew = ({ ticketId }: { ticketId: string }) => {
  const { ikewEnabled, ikewGroups, ikewSlots, ikewInProgress } = useContext(TicketsContext)
  const ticketDetails = useTicketDetails(ticketId)
  const { id, tableId, kdsStatusId } = ticketDetails

  useEffect(() => {
    const seen = ikewSlots[id]
    const validTable = ikewGroups[tableId]

    if (!ikewEnabled || kdsStatusId !== TICKET_STATUS.NEW || !validTable || seen) {
      return
    }
    ikewInProgress(ticketDetails)
  }, [])

  return null
}

const DynamicTicketWrapper = styled(Box)<{ selected: boolean }>`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 100%;
  user-select: none;
  border-radius: ${themeGet('space.1')};
  break-inside: avoid-column;
  border: ${({ selected, theme }) =>
    selected ? theme.kitchen.borders.ticket.selected : theme.kitchen.borders.ticket.notSelected};
`
