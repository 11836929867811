import React, { FC, useRef, useContext } from 'react'
import { themeGet } from '@styled-system/theme-get'
import styled from '@emotion/styled'
import useDoubleClick from 'use-double-click'

import { Box, Flex } from '@lightspeed/cirrus/Core'
import { Modifiers } from '../../../shared/components/ticket/body/modifiers'

// import { useSelection } from '../../../hooks/tickets/ticket-selection.hook'
import { useExpeditedItem } from '../../../hooks'
import { TicketItem } from '../../../kitchen-types'
import { TICKET_STATUS } from '../../../types/TicketStatuses'
import { TicketsContext } from '../../../state'

// import debug from 'debug'
// const log = debug('@kds')

interface TicketItemProps {
  ticketId: string
  quantity: number
  name: string
  color: string | null
  modifiers?: string[]
  selected: boolean
  index?: number
  last: boolean
  item: TicketItem
  kdsStatusId: number
}

export const DynamicTicketItem: FC<TicketItemProps> = ({
  ticketId,
  item,
  quantity,
  name,
  color,
  modifiers,
  index,
  last,
  kdsStatusId,
}) => {
  const btnRef = useRef()
  const { toggle, cleared, highlighted } = useExpeditedItem(ticketId, item)

  const invalidToggle =
    kdsStatusId === TICKET_STATUS.DONE ||
    kdsStatusId === TICKET_STATUS.VOIDED ||
    kdsStatusId === TICKET_STATUS.ARCHIVED

  useDoubleClick({
    onSingleClick: () => {
      if (invalidToggle) {
        return
      }
      if (!cleared) {
        toggle()
      }
    },
    onDoubleClick: () => {
      if (invalidToggle) {
        return
      }
      if (cleared) {
        toggle()
      }
    },
    ref: btnRef,
    latency: 250,
  })

  const {
    state: { totalsIndex, clearedIndex },
  } = useContext(TicketsContext)

  const productId = item.productId.toString()
  const entry = totalsIndex[productId]
  const activeQty = entry ? entry.quantity : 0
  const activeItemTotal = activeQty > 1 && !cleared && !highlighted
  const clearedQty = clearedIndex[productId] || 0

  return (
    <TicketItemWrapper
      ref={btnRef}
      data-testid={`ticketItem-${index}`}
      last={last}
      cleared={cleared}
      highlighted={highlighted}
      color={color}
    >
      <Box data-testid={`itemQuantity-${index}`}>{quantity}</Box>
      <ItemName data-testid={`itemName-${index}`}>
        {name} {activeItemTotal && <ItemTotal total={entry.quantity - clearedQty} />}
      </ItemName>
      <Box />
      <ModifierWrapper>
        {modifiers && modifiers.length ? <Modifiers modifiers={modifiers} /> : null}
      </ModifierWrapper>
    </TicketItemWrapper>
  )
}

interface ItemTotalProps {
  total: number
}

const ItemTotal = (props: ItemTotalProps) => {
  const { total } = props
  return <ItemTotalText>{`${total}`}</ItemTotalText>
}

const ItemTotalText = styled('span')`
  color: ${themeGet('kitchen.colors.ticket.items.modifier')};
  position: absolute;
  right: 2%;
`

const TicketItemWrapper = styled(Box)<{ last: boolean; color: string }>`
  display: grid;
  grid-template-columns: ${themeGet('kitchen.sizes.ticket.items.quantityWidth')} 1fr;
  grid-template-rows: auto minmax(0, auto);
  word-wrap: break-word;
  padding: ${themeGet('space.2')};
  line-height: ${themeGet('kitchen.fonts.size.3')};
  font-size: ${themeGet('kitchen.fonts.size.1')};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  border-bottom: ${({ last }) => (last ? 0 : themeGet('kitchen.borders.ticket.item'))};
  break-inside: avoid;
  color: ${({ cleared }) => (cleared ? '#ccc' : themeGet('kitchen.colors.ticket.items.text'))};
  background-color: ${({ highlighted }) =>
    highlighted ? themeGet('kitchen.colors.ticket.status.done') : 'transparent'};
  border-right: ${({ color }) => (color ? `10px solid ${color}` : 'none')};
  position: relative;
`

const ItemName = styled(Box)`
  word-break: break-all;
`

const ModifierWrapper = styled(Flex)`
  color: ${themeGet('kitchen.colors.ticket.items.modifier')};
  font-size: ${themeGet('kitchen.fonts.size.6')};
  font-weight: ${themeGet('kitchen.fonts.weight.bold')};
  min-height: ${(props) => (props.children ? themeGet('space.3') : themeGet('space.0'))};
  letter-spacing: ${themeGet('kitchen.fonts.letterSpacing.0')};
  line-height: ${(props) => (props.children ? '1.225rem' : themeGet('space.0'))};
  flex-wrap: wrap;
  word-break: break-all;
  margin-top: ${(props) => (props.children ? themeGet('space.2') : themeGet('space.0'))};
`
