import React, { useContext, useEffect } from 'react';

import { TicketDetails } from 'kitchen-types';

import { TicketsContext } from '../../../state';
import { TICKET_STATUS } from '../../../types/TicketStatuses';
import { Bump } from './bump';
import { Prioritize } from './prioritize';
import { UnBump } from './un-bump';
import { useShowAndTellBumpBar } from '../../show-and-tell.provider';
import { BUTTONS } from '../../../shared/components/header/header-buttons';

export const HeaderActions = ({ ticket }: { ticket: TicketDetails }) => {
  const {
    state: { stateFilter },
  } = useContext(TicketsContext);

  const voided = ticket.type === 'voided';

  if (voided && stateFilter !== TICKET_STATUS.ARCHIVED) {
    return (
      <Voided
        status={ticket.kdsStatusId}
        voided={voided}
        ticketId={ticket.id}
        kdsStatusId={ticket.kdsStatusId}
        type={ticket.type}
        receiptId={ticket.receiptId}
      />
    );
  }

  if (voided && stateFilter === TICKET_STATUS.ARCHIVED) {
    return null;
  }

  switch (ticket.kdsStatusId) {
    case TICKET_STATUS.NEW:
      return (
        <New
          isPrioritized={ticket.prioritized}
          status={ticket.kdsStatusId}
          voided={voided}
          ticketId={ticket.id}
          kdsStatusId={ticket.kdsStatusId}
          type={ticket.type}
          receiptId={ticket.receiptId}
        />
      );

    case TICKET_STATUS.PREPARING:
    case TICKET_STATUS.DONE:
      return (
        <DoneOrPreparing
          isPrioritized={ticket.prioritized}
          status={ticket.kdsStatusId}
          voided={voided}
          ticketId={ticket.id}
          kdsStatusId={ticket.kdsStatusId}
          type={ticket.type}
          receiptId={ticket.receiptId}
        />
      );
    case TICKET_STATUS.ARCHIVED:
      return (
        <Archived
          voided={voided}
          status={ticket.kdsStatusId}
          ticketId={ticket.id}
          kdsStatusId={ticket.kdsStatusId}
          receiptId={ticket.receiptId}
        />
      );

    default:
      return null;
  }
};

const New = ({
  ticketId,
  voided,
  status,
  isPrioritized,
  kdsStatusId,
  type,
  receiptId,
}: {
  ticketId: string;
  voided: boolean;
  status: TICKET_STATUS;
  isPrioritized: boolean;
  kdsStatusId: number;
  type: string;
  receiptId: number;
}) => {
  const { setButtons, selectedElement } = useShowAndTellBumpBar();
  const { bump, prioritise } = useContext(TicketsContext);

  useEffect(() => {
    setButtons([BUTTONS.CLOSE, BUTTONS.BUMP, BUTTONS.PRIORITIZE]);
  }, [setButtons, ticketId]);

  return (
    <>
      <Bump
        bump={(event: Event) => bump(event, ticketId, kdsStatusId, type, receiptId)}
        currentStatus={status}
        selected={selectedElement === BUTTONS.BUMP}
        voided={voided}
      />
      <Prioritize
        selected={selectedElement === BUTTONS.PRIORITIZE}
        isPrioritized={isPrioritized}
        prioritize={(event: Event) => prioritise(event, ticketId)}
      />
    </>
  );
};

const DoneOrPreparing = ({
  ticketId,
  voided,
  status,
  isPrioritized,
  kdsStatusId,
  type,
  receiptId,
}: {
  ticketId: string;
  voided: boolean;
  status: TICKET_STATUS;
  isPrioritized: boolean;
  kdsStatusId: number;
  type: string;
  receiptId: number;
}) => {
  const { setButtons, selectedElement } = useShowAndTellBumpBar();
  const { bump, unbump, prioritise } = useContext(TicketsContext);

  useEffect(() => {
    setButtons([BUTTONS.CLOSE, BUTTONS.UN_BUMP, BUTTONS.BUMP, BUTTONS.PRIORITIZE]);
  }, [setButtons, ticketId]);

  return (
    <>
      <UnBump
        unBump={(event: Event) => unbump(event, ticketId, kdsStatusId, receiptId)}
        selected={selectedElement === BUTTONS.UN_BUMP}
        currentStatus={status}
        voided={voided}
      />
      <Bump
        selected={selectedElement === BUTTONS.BUMP}
        bump={(event: Event) => bump(event, ticketId, kdsStatusId, type, receiptId)}
        currentStatus={status}
        voided={voided}
      />
      <Prioritize
        selected={selectedElement === BUTTONS.PRIORITIZE}
        isPrioritized={isPrioritized}
        prioritize={(event: Event) => prioritise(event, ticketId)}
      />
    </>
  );
};

const Archived = ({
  ticketId,
  voided,
  status,
  kdsStatusId,
  receiptId,
}: {
  ticketId: string;
  voided: boolean;
  status: TICKET_STATUS;
  kdsStatusId: number;
  receiptId: number;
}) => {
  const { setButtons, selectedElement } = useShowAndTellBumpBar();
  const { unbump } = useContext(TicketsContext);

  useEffect(() => {
    setButtons([BUTTONS.CLOSE, BUTTONS.UN_BUMP]);
  }, [setButtons, ticketId]);

  return (
    <UnBump
      unBump={(event: Event) => unbump(event, ticketId, kdsStatusId, receiptId)}
      currentStatus={status}
      selected={selectedElement === BUTTONS.UN_BUMP}
      voided={voided}
    />
  );
};

const Voided = ({
  ticketId,
  voided,
  status,
  kdsStatusId,
  type,
  receiptId,
}: {
  ticketId: string;
  voided: boolean;
  status: TICKET_STATUS;
  kdsStatusId: number;
  type: string;
  receiptId: number;
}) => {
  const { setButtons, selectedElement } = useShowAndTellBumpBar();
  const { bump } = useContext(TicketsContext);

  useEffect(() => {
    setButtons([BUTTONS.CLOSE, BUTTONS.BUMP]);
  }, [setButtons, ticketId]);

  return (
    <Bump
      bump={(event: Event) => bump(event, ticketId, kdsStatusId, type, receiptId)}
      currentStatus={status}
      selected={selectedElement === BUTTONS.BUMP}
      voided={voided}
    />
  );
};
