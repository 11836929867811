import { AxiosResponse } from 'axios';
import { kitchenConnector } from './kitchenConnector';
import { KDS_TOKEN_IDENTIFIER, KDS_LOGIN_INFO } from '../types/localStorageAliases';
import { rpcClient } from './rpc';
import { NoCredentialFoundError, KDSAutoLoginError } from './errors';

let refreshPromise: Promise<void> | null;

export const refreshToken = async () => {
  if (!refreshPromise) {
    refreshPromise = new Promise((resolve, reject) => {
      const loginInfo = localStorage.getItem(KDS_LOGIN_INFO);
      if (loginInfo) {
        const { username, password } = JSON.parse(loginInfo);
        if (username && password) {
          return kitchenConnector
            .login(username, password)
            .then(() => {
              resolve();
              refreshPromise = null;
            })
            .catch(error => reject(error));
        }
      }
      reject(new NoCredentialFoundError());
      return null;
    });
  }
  return refreshPromise;
};

export const retryRequest = (response: AxiosResponse<any>) => {
  if (localStorage.getItem(KDS_TOKEN_IDENTIFIER)) {
    const requestConfig = response.config;
    const data = JSON.parse(requestConfig.data);
    data.params.shift();
    requestConfig.data = data;
    return rpcClient.request(requestConfig);
  }
  return Promise.reject(new KDSAutoLoginError());
};
