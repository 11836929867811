import React, { useContext, useEffect, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';

import { Box, Flex } from '@lightspeed/cirrus/Core';

import { FormattersContext, TablesContext, TicketsContext } from '../../../state';
import { Modifiers } from '../ticket/body/modifiers';

export const BumpPrinting = ({ ticketId, onPrint }: { ticketId: string; onPrint: () => void }) => {
  const { timeFormatter, dateFormatter } = useContext(FormattersContext);

  const { tables } = useContext(TablesContext);

  const {
    state: { tickets },
  } = useContext(TicketsContext);

  const ticket = tickets.find(({ id }) => id === decodeURI(ticketId));

  useLayoutEffect(() => {
    ticket && ticket.id && window.print();
  }, [ticketId]);

  useEffect(() => {
    const clearTicketToPrint = () => {
      onPrint();
    };

    window.addEventListener('afterprint', clearTicketToPrint);

    return () => {
      clearTicketToPrint();
      window.removeEventListener('afterprint', clearTicketToPrint);
    };
  }, []);

  return ticket ? (
    <BumpPrintContainer>
      <Flex flexDirection="column" flex={1}>
        <Header>{tables[ticket.tableId] ? tables[ticket.tableId].name : 'No table'}</Header>
        <Info>
          <Flex>{`${dateFormatter.format(
            moment(ticket.modificationDate).toDate(),
          )} - ${timeFormatter.format(moment(ticket.modificationDate).toDate())}`}</Flex>
          <Flex justifyContent="flex-end">{ticket.customerName || ticket.waiterName}</Flex>
        </Info>
        <Flex flexDirection="column" flex={1} mt="10px">
          {ticket.items.map(({ productId, amount, name, modifiers }) => (
            <Box key={productId}>
              <Item>
                <Box>{amount}</Box>
                <Box>{name}</Box>
              </Item>
              <Item>
                <Box />
                <Box ml="5px">
                  {modifiers && modifiers.length ? <Modifiers modifiers={modifiers} /> : null}
                </Box>
              </Item>
            </Box>
          ))}
        </Flex>
        <Footer>Dit is geen geldig BTW-kasticket.</Footer>
      </Flex>
    </BumpPrintContainer>
  ) : null;
};

const Header = styled(Box)`
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  border-bottom: 3px solid #000;
`;

const Footer = styled(Box)`
  padding-top: 10px;
  margin: 10px 0px;
  text-align: center;
  border-top: 1px solid #000;
  font-size: 12px;
`;

const Info = styled(Box)`
  font-size: 12px;
`;

const Item = styled(Box)`
  display: grid;
  grid-template-columns: 20px auto;
`;

const BumpPrintContainer = styled(Flex)`
  width: 100%;
  color: #000;
  @media screen {
    display: none;
  }
`;
