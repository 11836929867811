import { useContext } from 'react';
import { MixpanelEvents } from '../../types/mixpanel-events';
import { MixpanelContext } from '../../state/mixpanel.provider';

export const useMoreOpenTracking = () => {
  const { track, getInteractionType, getSourceType } = useContext(MixpanelContext);

  const trackMoreOpen = (event: Event) => {
    try {
      track(MixpanelEvents.MORE_OPEN, getInteractionType(event), getSourceType());
    } catch (error) {
      // silently ignore the error
    }
  };
  return trackMoreOpen;
};
