import { CompanySettingsData } from 'kitchen-types';

export class KDSDisabledError extends Error {
  companySettings: CompanySettingsData;

  constructor(companySettings: CompanySettingsData) {
    super('[KDSDisabledError] - The kitchen display is disabled for this company');
    this.companySettings = companySettings;
    Object.setPrototypeOf(this, KDSDisabledError.prototype);
  }
}

export class KDSAutoLoginError extends Error {
  constructor() {
    super("[KDSAutoLoginError] - The kitchen display could't auto login.");
    Object.setPrototypeOf(this, KDSAutoLoginError.prototype);
  }
}

export class NoCredentialFoundError extends Error {
  constructor() {
    super('[NoCredentialFoundError] - No credential found for auto login.');
    Object.setPrototypeOf(this, NoCredentialFoundError.prototype);
  }
}

export class WrongCredentialError extends Error {
  errorMessage: string;

  constructor() {
    super('[WrongCredentialError] - Wrong credentials.');
    this.errorMessage = 'Invalid user credentials';
    Object.setPrototypeOf(this, WrongCredentialError.prototype);
  }
}

export class KDSRedirect extends Error {
  constructor() {
    super('[KDSRedirect] - The kitchen v3 is not active for this account');
    Object.setPrototypeOf(this, KDSRedirect.prototype);
  }
}
