import { useIntlDateTimeFormat } from './useIntlDateTimeFormat';

export const useShortDateFormat = (timeZone: string, locales: string | string[]) => {
  return useIntlDateTimeFormat(locales, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone,
  });
};
