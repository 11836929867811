import { useContext, useCallback } from 'react';
import { MixpanelEvents } from '../../types/mixpanel-events';
import { TICKET_STATUS } from '../../types/TicketStatuses';
import { MixpanelContext } from '../../state/mixpanel.provider';

export const useUnbumpTracking = () => {
  const { track, getInteractionType, getSourceType } = useContext(MixpanelContext);

  const getEvent = (kdsStatusId: TICKET_STATUS) => {
    switch (kdsStatusId) {
      case TICKET_STATUS.PREPARING:
        return MixpanelEvents.TICKET_UNBUMP_TO_NEW;
      case TICKET_STATUS.DONE:
        return MixpanelEvents.TICKET_UNBUMP_TO_PREPARING;
      case TICKET_STATUS.ARCHIVED:
        return MixpanelEvents.TICKET_UNBUMP_TO_DONE;
      default:
        return null;
    }
  };

  const trackUnbump = useCallback(
    (event: Event, kdsStatusId: number, receiptId: number) => {
      try {
        const mixpanelEvent: MixpanelEvents | null = getEvent(kdsStatusId);
        if (mixpanelEvent) {
          track(mixpanelEvent, getInteractionType(event), getSourceType(), {
            receiptId,
          });
        }
      } catch (error) {
        // silently ignore the error
      }
    },
    [getInteractionType, getSourceType, track],
  );

  return trackUnbump;
};
