import React, { ReactNode } from 'react';
import { themeGet } from '@styled-system/theme-get';

import styled from '@emotion/styled';
import { Flex } from '@lightspeed/cirrus/Core';
import { Modal, ModalHeader } from '@lightspeed/cirrus/Modal';
import { Text } from '@lightspeed/cirrus/Text';

type kitchenModalProps = {
  children?: ReactNode;
  title?: string;
};

type kitchenModalEvents = {
  onCloseModal: (event: MouseEvent | KeyboardEvent) => void;
};

const StyledModal = styled(Modal)`
  width: ${themeGet('kitchen.sizes.modal.width')};
  height: ${themeGet('kitchen.sizes.modal.height')};
`;

const LogoWrapper = styled(Flex)`
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${themeGet('space.3')};
`;

export const KitchenModal = ({
  onCloseModal,
  children,
  title,
}: kitchenModalProps & kitchenModalEvents) => {
  return (
    <StyledModal isOpen onRequestClose={onCloseModal}>
      <ModalHeader>
        <Flex justifyContent="center">
          <Text data-testid="kitchen_modal_title">{title}</Text>
        </Flex>
      </ModalHeader>
      {children}
      <LogoWrapper>
      </LogoWrapper>
    </StyledModal>
  );
};
