import { useContext } from 'react';
import { MixpanelEvents } from '../../types/mixpanel-events';
import { MixpanelContext } from '../../state/mixpanel.provider';

export const useQuickFilterTracking = () => {
  const { track, getInteractionType, getSourceType } = useContext(MixpanelContext);

  const trackQuickFilter = (event: Event, mixpanelEvent: MixpanelEvents) => {
    try {
      track(mixpanelEvent, getInteractionType(event), getSourceType());
    } catch (error) {
      // silently ignore the error
    }
  };

  return trackQuickFilter;
};
